import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import Styled from 'styled-components';

import PatientOnboardingPrint from '@/components/business/analytics/patient/patient-update/patient-update-onboarding-print';
import {Button} from '@/components/buttons/button';
import {InfoMessage} from '@/components/form/info-message';
import withRouter from '@/decorators/withRouter';
import {BackendApiService} from '@/services/backend-api-service';

class PatientCreationOnboarding extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientId: PropTypes.string.isRequired,
        navigate: PropTypes.func,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        qrCode: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.getLatestQrCode();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Onboarding', 'patientOnboarding.title'])}</h3>

                <InfoMessage infoMessage={t(['The caregiver can start the onboarding on the tablet!', 'patientOnboarding.info'])} text={t(['You can print the QR code if necessary.', 'patientOnboarding.infoText'])} />

                {state.qrCode &&
                    <div>
                        <div className="qrCodeWrapper">
                            <img src={`data:image/jpeg;base64,${state.qrCode.qrCodeData}`} alt={t(['Patient QR Code', 'patientOnboarding.qrCodeAlt'])} />
                        </div>
                        <div className="hidden">
                            <PatientOnboardingPrint patientId={props.patientId} qrCode={state.qrCode} ref={el => (this.componentRef = el)} />
                        </div>
                        <div className="qrCodeActions">
                            <ReactToPrint content={() => this.componentRef}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <Button className="button-active" onClick={handlePrint}>{t(['Print QR code', 'patientOnboarding.qrCodePrint'])}</Button>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                            <Button className="button-cancel" displayIcon={false} onClick={this.handleClickPatientsDashboard}>{t(['Patients dashboard', 'patientOnboarding.patientsDashboardLink'])}</Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

    handleClickPatientsDashboard() {
        let url = '/patients/';
        this.props.navigate(url);
    }

    getLatestQrCode() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'getLatestQrCode',
            data: {
                id: this.props.patientId
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
                qrCode: response,
            }));
            return response;
        }).catch(err => {
            console.error(err);

            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

//language=SCSS
PatientCreationOnboarding = Styled(PatientCreationOnboarding)`
& {
    .qrCodeWrapper {
        width:300px;
        margin:30px 0;
    }
    
    .qrCodeActions {
        button:last-child {
            margin-top:20px;
        }
    }
}
`;

export default withRouter(withTranslation()(PatientCreationOnboarding));

