import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';

type DomainDefinitions = {
    normal: DomainDefinition;
    extended?: DomainDefinition;
};

type DomainDefinition = {
    domain: [number, number];
    ticks: number[];
};

const domainSPO2Normal: DomainDefinition = {
    domain: [
        70,
        100,
    ],
    ticks: [ 70, 80, 90, 100 ],
};
const domainSPO2Extended: DomainDefinition = {
    domain: [
        50,
        100,
    ],
    ticks: [ 50, 60, 70, 80, 90, 100 ],
};
const domainHR: DomainDefinition = {
    domain: [
        40,
        240,
    ],
    ticks: [ 40, 80, 120, 160, 200, 240 ],
};
const domainActigraphyNormal: DomainDefinition = {
    domain: [
        0.0,
        0.5,
    ],
    ticks: [ 0.0, 0.25, 0.5 ],
};
// const domainActigraphyExtended: DomainDefinition = {
//     domain: [
//         0.0,
//         1.5,
//     ],
//     ticks: [ 0.0, 0.25, 0.5, 0.75, 1.0, 1.25, 1.5 ],
// };

//@ts-expect-error data will be added right after. AFAIK it is not possible to set keys from enums using the JSON syntax
const domains: {[key in SignalType]: DomainDefinitions} = {};
domains[SignalType.Signal_PR] = {
    normal: domainHR,
};
domains[SignalType.Signal_SPO2] = {
    normal: domainSPO2Normal,
    extended: domainSPO2Extended,
};
domains[SignalType.Signal_Actigraphy] = {
    normal: domainActigraphyNormal,
    //extended: domainActigraphyExtended,
};

function getDomainForEvents(maxEvents: number, dual: boolean): DomainDefinition {
    maxEvents = Math.max(10, maxEvents);

    // Rounding maxEvents to a "round" number
    const tickStep = (
        maxEvents <= 20 ? 5 :
            maxEvents <= 40 ? 10 :
                maxEvents <= 100 ? 20 :
                    50
    );
    maxEvents = Math.ceil(maxEvents / tickStep) * tickStep;

    // Building ticks
    const ticks = [];
    for (let i= dual ? -maxEvents : 0; i <= maxEvents; i += tickStep) {
        ticks.push(i);
    }

    return {
        domain: [
            dual ? -maxEvents : 0,
            maxEvents
        ],
        ticks: ticks,
    };
}

function getDomainForDuration(durationInSeconds: number): DomainDefinition {
    const durationInHours = durationInSeconds / 3600;
    const tickLength = durationInHours < 16 ? 2 : 6;
    let duration = Math.max(10, durationInHours);
    duration /= tickLength;
    duration = Math.ceil(duration);
    const out = [];
    for (let tick = 0; tick <= duration; ++tick) {
        out.push(tick * tickLength);
    }
    return {
        domain: [0, duration * tickLength],
        ticks: out,
    };
}

function getDomainForSignalType(signalType: SignalType, min?: number, max?: number): DomainDefinition {
    const domainDefinitions = domains[signalType];

    if (!domainDefinitions.extended || typeof(min) === 'undefined' || typeof(max) === 'undefined') {
        return domainDefinitions.normal;
    }

    // Check for extended domain
    if (min < domainDefinitions.normal.domain[0] || max > domainDefinitions.normal.domain[1]) {
        return domainDefinitions.extended;
    }

    return domainDefinitions.normal;
}

export { getDomainForSignalType, getDomainForEvents, getDomainForDuration };
