import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import ResizeObserver from 'resize-observer-polyfill';

const style = {width: '100%', height: '100%'};

class Autosized extends React.Component {
    static propTypes = {
        component: PropTypes.any.isRequired,
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
        debug: PropTypes.bool, // If true, won't autosize on resize and add button to trigger it
    };

    constructor(props) {
        super(props);

        autoBind(this);

        this.rootRef = React.createRef();
        this.state = {
            w: null,
            h: null,
        };
        this.updateTimeout = null;
    
        if (!this.props.debug) {
            window.addEventListener('resize', this.updateSize);
        }
    }
    
    updateSize() {
        if (!this.updateTimeout) {
            this.updateTimeout = setTimeout(() => {
                this.updateTimeout = null;
                if (this.rootRef) {
                    const node = this.rootRef.current;
                    if (node) {
                        const w = node.offsetWidth;
                        const h = node.offsetHeight;
                        if (this.state.w !== w || this.state.h !== h) {
                            this.setState({
                                w: w,
                                h: h,
                            });
                        }
                    }
                }
            }, 100);
        }
    }

    componentDidMount() {
        if (!this.props.debug) {
            this.updateSize();
        }
        this.resizeObserver = new ResizeObserver(() => {
            this.updateSize();
        });
        this.resizeObserver.observe(this.rootRef.current);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
        this.resizeObserver.disconnect();
    }

    render() {
        const props = this.props;
        // eslint-disable-next-line react/prop-types
        const innerProps = props.innerProps;
        const Component = props.component;
        if (props.maxWidth) {
            style.maxWidth = props.maxWidth;
        }
        if (props.maxHeight) {
            style.maxHeight = props.maxHeight;
        }
        if (this.state.w) {
            return (
                <div ref={this.rootRef} style={style}>
                    <Component {...innerProps} width={this.state.w} height={this.state.h} />
                </div>
            );
        }
        else {
            return (
                <div ref={this.rootRef} style={{width: '100%', height: '100%'}}>
                    {props.debug && (
                        <div>
                            DEBUG
                            <button onClick={this.updateSize}>update</button>
                        </div>
                    )}
                </div>
            );
        }
    }
}

export { Autosized };
