import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {HcpProgramEnum} from '@/enum/hcp-program-enum.jsx';
import programCardioIcon from '@/public/icons/program-cardio-icon.svg';
import programResearchIcon from '@/public/icons/program-research-icon.svg';
import programRespiratoryIcon from '@/public/icons/program-respiratory-icon.svg';
import {colorPalette} from '@/themes/darkmode.js';

const PatientsProgramSelection = ({program, onChanged}) => {
    const {t} = useTranslation();
    const [selectedProgram, setSelectedProgram] = useState(program);

    useEffect(() => {
        setSelectedProgram(program);
    }, [program]);

    const handleChangeProgram = (program, e) => {
        if(e) {
            e.preventDefault();
        }
        setSelectedProgram(parseInt(program));
        onChanged(parseInt(program));
    };

    return (
        <StyledWrapper>
            <nav>
                <a href="#" id="program-label-research" title={t(['Research', 'programType.research'])} onClick={e => handleChangeProgram(HcpProgramEnum.RESEARCH, e)}>{t(['Research', 'programType.research'])}</a>
                <a href="#" id="program-label-respiratory" title={t(['Respiratory', 'programType.respiratory'])} onClick={e => handleChangeProgram(HcpProgramEnum.RESPIRATORY, e)}>{t(['Respiratory', 'programType.respiratory'])}</a>
                <a href="#" id="program-label-cardio" title={t(['Cardiology', 'programType.cardio'])} onClick={e => handleChangeProgram(HcpProgramEnum.CARDIO, e)}>{t(['Cardiology', 'programType.cardio'])}</a>
            </nav>
            <Slider
                dots
                min={0}
                max={2}
                step={1}
                tipProps={{visible: false}}
                value={selectedProgram}
                allowCross={false}
                onChange={(program) => {
                    handleChangeProgram(program);
                }}
            />
        </StyledWrapper>
    );
};

PatientsProgramSelection.propTypes = {
    t: PropTypes.func,
    program: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired
};

//language=SCSS
const StyledWrapper = styled.div`  
    && {
        width:150px;
      
        nav {
            position: relative;
            height: 30px;
            margin-bottom: 15px;
            a {
                display: inline-block;
                text-indent: -10000px;
                color: transparent;
                width:23px;
                height:23px;
                position:absolute;
                
                &#program-label-research {
                    background: url(${programResearchIcon}) no-repeat center;
                    left: -12px;
                    bottom: 0;
                }
                &#program-label-respiratory {
                    background: url(${programRespiratoryIcon}) no-repeat center;
                    left: 64px;
                    bottom: 0;
                }
                &#program-label-cardio {
                    background: url(${programCardioIcon}) no-repeat center;
                    right: -12px;
                    bottom: 0;
                }
            }
        }
          
        .rc-slider.rc-slider-disabled {
            display: none;
        }
        .rc-slider:not(.rc-slider-disabled) {
            .rc-slider-rail {
                background: ${colorPalette.clearColor};
            }
            
            .rc-slider-step {
                .rc-slider-dot,
                .rc-slider-dot-active {
                    width: 10px;
                    height: 10px;
                    border-radius: 20px;
                    background: ${colorPalette.clearColor};
                    border: none;
                }
            }
        
            .rc-slider-track {
                background: ${colorPalette.clearColor};
            }
        
            .rc-slider-handle {
                width:20px;
                height:20px;
                margin-top:-8px;
                background:${colorPalette.activeColor};
                border-color:${colorPalette.frontColor};
            }
        
            .rc-slider-handle-dragging {
                box-shadow: 0 0 0 3px ${colorPalette.frontColor};
            }
        
            .rc-slider-tooltip-inner {
                padding: 2px;
                min-width: 24px;
                height: auto;
                font-size: 11px;
                line-height: 1;
                color: #fff;
                text-align: center;
                text-decoration: none;
                background-color: inherit;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }
`;

export default PatientsProgramSelection;
