import * as Sentry from '@sentry/react';

import {appVersion} from '@/util/app-version-util';

let serviceInitialized = false;

const ErrorTrackingService = {
    init() {
        if (!serviceInitialized) {
            //Init Sentry
            Sentry.init({
                dsn: 'https://f0b461a21395444b8b21c036ccb490d3@o356527.ingest.sentry.io/4505520872161280',
                environment: __GABI_ENV__,
                release: `gabi-hcp-web@${appVersion}`,
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: [
                            //'localhost',
                            'https://dev.analytics.gabismartcare.com',
                            'https://acceptance.analytics.gabismartcare.com',
                            'https://analytics.gabismartcare.com'
                        ],
                    }),
                    new Sentry.Replay(),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });

            serviceInitialized = true;
        }
        else {
            console.warn('ErrorTrackingService already initialized');
        }
    },
    
    captureApiException(exception, apiFunction) {
        withScope(apiFunction, () => {
            Sentry.captureException(exception);
        });
    },
};

function withScope(apiFunction, callback) {
    if (serviceInitialized) {
        Sentry.withScope(scope => {
            if (apiFunction) {
                scope.setTag('gabi-api-request', apiFunction);
                scope.setTag('gabi-env', __GABI_ENV__);
                scope.setTag('gabi-app-version', __APP_VERSION__);
            }
            callback();
        });
    }
}

export { ErrorTrackingService };
