import {HcpQueryServiceClient} from 'gabi-api-ts/v2/hcp/query/hcp_query.client';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {HcpPersonalDataForm} from '@/components/business/analytics/hcp/hcp-personal-data-form';
import LoadingView from '@/components/static/loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService, FeedbackAlertType} from '@/services/feedback-alerts-service';

const HcpUpdatePersonalDataPropTypes = {
    hcp: PropTypes.object.isRequired,
    onSaved: PropTypes.func,
};

function HcpUpdatePersonalData(props) {
    const {t} = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [personalDataLoading, personalData] = useBackendQuery({
        serviceClient: HcpQueryServiceClient,
        query: HcpQueryServiceClient.prototype.get,
        memoize: false,
        data: {
            id: props.hcp.sub,
        }
    }, []);
    
    const updateHcpPersonalData = async (hcp) => {
        setSubmitting(true);

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateHcpPersonalDetails',
            data: {
                hcp: hcp,
            },
        })
            .then((hcp) => {
                FeedbackAlertsService.add(FeedbackAlertType.SUCCESS, 'updateHcpPersonalDetails', t('hcpUpdatePersonalData.success') ?? 'Profile updated!');
                return hcp;
            })
            .then((hcp) => {
                BackendApiRefreshListeners.refreshByCall(HcpQueryServiceClient, HcpQueryServiceClient.prototype.get);
                if (hcp && props.onSaved) {
                    props.onSaved(hcp);
                }
                setSubmitting(false);
            })
            .catch(err => {
                console.error(err);
                setSubmitting(false);
            });
    };

    return (
        <>
            <h3>{t('hcpUpdatePersonalData.title')}</h3>

            {!personalDataLoading && (
                <HcpPersonalDataForm hcp={personalData} submitting={submitting} onSubmit={updateHcpPersonalData} />
            )}
            {personalDataLoading && (
                <LoadingView />
            )}
        </>
    );
}

HcpUpdatePersonalData.propTypes = HcpUpdatePersonalDataPropTypes;

export {HcpUpdatePersonalData};
