export const BandAssignationStatusLabel = {
    0: 'UNKNOWN',
    1: 'NO_QR_CODE_AVAILABLE',
    2: 'NO_BAND_ASSIGNED',
    3: 'NO_RECORDING',
    4: 'HAS_RECORDING',
};

export const BandAssignationStatus = {
    ASSIGNATION_STATUS_UNKNOWN: 0,
    NO_QR_CODE_AVAILABLE: 1,
    NO_BAND_ASSIGNED: 2,
    NO_RECORDING: 3,
    HAS_RECORDING: 4,
};
