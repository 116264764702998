import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components';

import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';
import {InfoMessage} from '@/components/form/info-message';
import {SuccessMessage} from '@/components/form/success-message';
import {UserPic} from '@/components/static/user-pic';
import hcpDefaultPic from '@/public/icons/hcp-default-pic.svg';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';

class GroupAddUser extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        group: PropTypes.object.isRequired,
        onAdded: PropTypes.func,
        onCancelled: PropTypes.func,
        onNotFound: PropTypes.func,
        t: PropTypes.func,
    };

    state = {
        userEmail: '',
        userEmailValid: true,
        user: null,
        userRole: false,
        loading: false,
        userIsAlreadyInGroup: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    isFilled() {
        const state = this.state;
        return (
            state.userEmail && state.userEmail !== ''
        );
    }

    render() {
        const props = this.props;
        const state = this.state;

        const { t } = this.props;

        return (
            <div className={props.className}>
                {!state.user &&
                    <form onSubmit={this.handleSubmit}>
                        <div className="fieldWrapper">
                            <label htmlFor="addUserToGroup-userEmail" className={`mandatoryField ${(this.state.userEmail === '') ? 'empty' : 'filled'}`}>{t(['Email of the user', 'addUser.email'])}</label>
                            <input maxLength={320} name="email" id="addUserToGroup-userEmail" value={this.state.userEmail} required aria-required="true" autoFocus onChange={this.handleChangeEmail} />
                        </div>
                        {state.userEmail && !state.userEmailValid &&
                            <div className="errorWrapper">
                                <ErrorMessage text="Invalid email" errorMessage={t(['Please enter a valid email address', 'addUser.emailError'])} />
                            </div>
                        }
                        <div>
                            <Button className="button-create" disabled={!this.isFilled()} loading={state.loading}>{t(['Find user', 'addUser.confirm'])}</Button>
                            <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'addUser.cancel'])}</Button>
                        </div>
                    </form>
                }
                {state.user &&
                    <div>
                        {state.userIsAlreadyInGroup &&
                            <InfoMessage infoMessage="User found!" text={`${state.user.firstName} ${state.user.lastName} ${t(['is already in this group.', 'addUser.userAlreadyInGroup'])}`} />
                        }

                        {!state.userIsAlreadyInGroup &&
                            <div>
                                <SuccessMessage successMessage="User found!" text={t(['You can now add this user to your group.', 'addUser.userFound'])} />

                                <div className="user-found-wrapper">
                                    <UserPic pic={hcpDefaultPic} alt={state.user.firstName} width={70}/>
                                    <span className="userDetails">
                                        {state.user.firstName}&nbsp;{state.user.lastName}<br />
                                        <span>{state.user.email}</span>
                                    </span>
                                    <div className="changeRoleWrapper">
                                        <input type="checkbox" name="userRole" id="userRole" defaultChecked={this.state.userRole} onChange={this.handleChangeUserRole} />
                                        <label htmlFor="userRole">{t(['Admin', 'addUser.userRole'])}</label>
                                    </div>
                                </div>

                                <div>
                                    <Button className="button-create" disabled={!this.isFilled()} loading={state.loading} onClick={this.handleAddUserToGroup}>{t(['Add user to group', 'addUser.add'])}</Button>
                                    <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'addUser.cancel'])}</Button>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }

    handleChangeEmail(e) {
        this.setState(state => ({
            ...state,
            userEmail: e.target.value,
        }));
    }

    handleChangeUserRole(e) {
        this.setState(state => ({
            ...state,
            userRole: e.target.checked,
        }));
    }

    handleSubmit(e) {
        e.preventDefault();

        const email = this.state.userEmail;
        const pattern = /^\S{1,60}@{1}[a-z0-9_-]{1,60}(\.[a-z0-9_-]{2,60})+$/;
        const userEmailValid = pattern.test(email);

        this.setState(state => ({
            ...state,
            userEmailValid: userEmailValid,
        }));

        if(userEmailValid) {
            this.findUserByEmail();
        }
    }

    handleAddUserToGroup() {
        this.addUserToGroup();
    }

    handleCancel(e) {
        e.preventDefault();
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    findUserByEmail() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'findUserByEmail',
            data: {
                userEmail: this.state.userEmail,
            }
        })
            .then((userId) => {
                if(userId.id) {
                    let userIsAlreadyInGroup = false;

                    this.props.group.usersList.forEach(user => {
                        if(user.id.id === userId.id) {
                            userIsAlreadyInGroup = true;
                        }
                    });

                    this.setState(state => ({
                        ...state,
                        loading: false,
                        userId: userId,
                        userIsAlreadyInGroup: userIsAlreadyInGroup,
                    }));

                    this.getHcpUser(userId);
                }
                else {
                    this.setState(state => ({
                        ...state,
                        loading: false,
                        userId: userId,
                    }));

                    if(this.props.onNotFound) {
                        this.props.onNotFound(this.state.userEmail);
                    }
                }
                return userId;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }

    getHcpUser(userId) {
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getUser',
            data: {
                id: userId.id,
            }
        })
            .then((hcp) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    user: hcp,
                }));

                return hcp;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }

    addUserToGroup() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: (this.state.userRole) ? 'addUserAdminToGroup' : 'addUserMemberToGroup',
            data: {
                userId: this.state.userId.id,
                groupId: this.props.group.groupId.id,
            }
        })
            .then((group) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    userEmail: '',
                    user: null,
                }));

                if(this.props.onAdded) {
                    this.props.onAdded();
                }

                return group;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    loading: false,
                }));
            });
    }
}

//language=SCSS
GroupAddUser = Styled(GroupAddUser)`
& {
    .changeRoleWrapper {
        display:flex;
        margin:15px 0;
        input {
            width:25px;
            height:25px;
        }
        label {
            margin: 2px 0 0 10px;
        }
    }
    
    .user-found-wrapper {
        padding: 15px;
        border:1px solid ${colorPalette.thirdBackground};
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: 20px;

        .userDetails {
            display: inline-block;
            vertical-align: top;
            padding-top: 4px;
            padding-left: 10px;
            width: calc(100% - 110px);
            word-break: break-all;
            span {
                color:${colorPalette.clearColor};
            }
        }
    }
}
`;

export default withTranslation()(GroupAddUser);
