import {differenceInDays} from 'date-fns';
import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {
    AlertType,
    HasData,
    SignalType,
    Trend,
} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useTranslation} from 'react-i18next';
import {Bar, ComposedChart, Line, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickDate} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-date';
import {ChartAxisTickDuration} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-duration';
import {
    getDomainForDuration,
    getDomainForEvents
} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {getMax} from '@/util/array-util';
import {formatDateUniversal, formatSecondsToHms} from '@/util/date-utils';
import {roundTo} from '@/util/number-util';
import {getSignalEventConfig, getSignalTypeIdentifier, getSignalTypeUnit} from '@/util/signal-type-util';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type HealthReportPercentagePerDayProps = {
    patientId: string;
    signalType: SignalType;
    alertType: AlertType;
    lastRecordingDate: Date;
    dateFrom: Date;
    dateTo: Date;
    ignoreUnifiedDuration?: boolean;
    displayPreviousNights: boolean;
    lightColor?: boolean;
    onClick?: (date: Date) => void;
}

type EventFormatted = {
    date: string;
    events: number | null;
    hasEnoughData: boolean;
    reliableDurationInSeconds: number;
    duration: number | null;
}

function HealthReportPercentagePerDay({patientId, signalType, lastRecordingDate, alertType, dateFrom, dateTo, ignoreUnifiedDuration, displayPreviousNights, lightColor, onClick}: HealthReportPercentagePerDayProps) {
    const { t } = useTranslation();
    const nbDays = displayPreviousNights ? 15 : differenceInDays(dateTo, dateFrom)+1;

    const [signalsEventConfigurationLoading, signalsEventConfiguration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: patientId } },
    });

    const [eventsSummaryLoading, eventsSummary] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalEventsSummary,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            date: formatJSDateToApiDateTs(lastRecordingDate),
            alertType: alertType,
            nbPreviousDays: 14,
        },
    });

    const [unifiedDurationLoading, unifiedDuration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalsUnifiedDuration,
        memoize: true,
        data: {
            patientId: { id: patientId },
            signals: [SignalType.Signal_PR, SignalType.Signal_SPO2],
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
        },
    });

    const [eventsPerDayLoading, eventsPerDay] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventsPerDay,
        memoize: true,
        data: {
            patientId: { id: patientId },
            signalType: signalType,
            alertType: alertType,
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
        },
    });

    const [averageEventsPerHourForPeriodLoading, averageEventsPerHourForPeriod] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getAverageEventsPerHoursForPeriod,
        memoize: true,
        disabled: displayPreviousNights,
        data: {
            alertType: alertType,
            signalType: signalType,
            patientId: { id: patientId },
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
        },
    });

    const loading = (
        (unifiedDurationLoading || !unifiedDuration) ||
        (eventsPerDayLoading || !eventsPerDay) ||
        (signalsEventConfigurationLoading || !signalsEventConfiguration) ||
        (eventsSummaryLoading || !eventsSummary) ||
        (averageEventsPerHourForPeriodLoading)
    );

    const signalEventConfiguration: SignalEventConfiguration = signalsEventConfigurationLoading ? {
        low: { value: -1000 },
        high: { value: -1000 },
        sensibility: { seconds: 3600 },
    } : getSignalEventConfig(signalsEventConfiguration!.configuration!, signalType);

    const handleClick: CategoricalChartFunc = (rechartData) => {
        if (onClick) {
            if (rechartData && rechartData.activePayload && rechartData.activePayload[0]) {
                const rechartsPayload = rechartData?.activePayload[0].payload;
                if (rechartsPayload) {
                    const selectedDate = new Date(rechartsPayload.date);
                    const duration = rechartsPayload.reliableDurationInSeconds;
                    if (duration && duration > 0) {
                        onClick(selectedDate);
                    }
                }
            }
        }
    };

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>) => {
        if (data?.payload) {
            const events = data?.payload[0]?.payload?.events;
            const reliableDurationInSeconds = data?.payload[0]?.payload?.reliableDurationInSeconds;

            return (
                <div className="chart-tooltip">
                    <strong>{formatDateUniversal(new Date(data?.payload[0]?.payload?.date + ' 00:00:00'))}</strong><br />
                    <span className="tooltip-duration">{t('healthReport.events.last7nights.duration')}: {formatSecondsToHms(reliableDurationInSeconds)}</span>
                    {signalType === SignalType.Signal_SPO2 ?
                        <span className="tooltip-events">{roundTo(events, 2)}%</span>
                        :
                        <span className="tooltip-events">{roundTo(events, 2)} {t('global.eventsPerHour')}</span>
                    }
                </div>
            );
        }
        return null;
    };

    if (patientId && dateFrom && dateTo && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalType.Signal_PR: return t('healthReport.events.last7nights.pulseRateTitle');
            case SignalType.Signal_SPO2: return t('healthReport.events.last7nights.spo2Title');
            default: return ''; // not used
            }
        })();

        const subtitle = (() => {
            const out: string[] = [];
            if (alertType === AlertType.Alert_LOW) {
                out.push(`${t('global.below')} ${signalEventConfiguration.low?.value}${getSignalTypeUnit(signalType)}`);
            }
            if (alertType === AlertType.Alert_HIGH) {
                out.push(`${t('global.above')} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`);
            }

            return out.join(' or ');
        })();

        const signalColors = lightColor ? colorPalette.signalTypeLight : colorPalette.signalType;

        if (loading) {
            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                    >
                        <LoadingView color={signalColors[getSignalTypeIdentifier(signalType)]}/>
                        {/*<ul>*/}
                        {/*    <li>unifiedDurationLoading: {unifiedDurationLoading ? 'Y' : 'N'}</li>*/}
                        {/*    <li>eventsPerDayLoading: {eventsPerDayLoading ? 'Y' : 'N'}</li>*/}
                        {/*    <li>signalsEventConfigurationLoading: {signalsEventConfigurationLoading ? 'Y' : 'N'}</li>*/}
                        {/*    <li>averageEventsPerHourForDayLoading: {averageEventsPerHourForDayLoading ? 'Y' : 'N'}</li>*/}
                        {/*    <li>averageEventsPerHourForPeriodLoading: {averageEventsPerHourForPeriodLoading ? 'Y' : 'N'}</li>*/}
                        {/*</ul>*/}
                        {/*<ul>*/}
                        {/*    <li>unifiedDuration: {JSON.stringify(unifiedDuration, null, '  ')}</li>*/}
                        {/*    <li>eventsPerDay: {JSON.stringify(eventsPerDay, null, '')}</li>*/}
                        {/*    <li>signalsEventConfiguration: {JSON.stringify(signalsEventConfiguration, null, '')}</li>*/}
                        {/*    <li>averageEventsPerHourForDay: {JSON.stringify(averageEventsPerHourForDay, null, '')}</li>*/}
                        {/*    <li>averageEventsPerHourForPeriod: {JSON.stringify(averageEventsPerHourForPeriod, null, '')}</li>*/}
                        {/*</ul>*/}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            const eventsPerDayFormatted: EventFormatted[] = [];
            eventsPerDay?.eventsPerDay.forEach(event => {
                const hasEnoughData = event.hasRecording && event.hasData >= HasData.HAS_MIN_RELIABLE_DATA;
                eventsPerDayFormatted.push({
                    date: event.date,
                    hasEnoughData: hasEnoughData,
                    events: (hasEnoughData && event.reliableDurationInSeconds > 0) ? event.events : null,
                    reliableDurationInSeconds: event.reliableDurationInSeconds,
                    duration: (hasEnoughData && event.reliableDurationInSeconds > 0) ? roundDurationHoursToQuarterPrecision(event.reliableDurationInSeconds) : null,
                });
            });
            const eventsPerDayNotZero = eventsPerDayFormatted.filter(x => x.events && x.events !== 0).length;

            const averageUnit = (() => {
                switch(signalType) {
                case SignalType.Signal_PR: return (
                    <span>{' '}{t('global.eventsPerHour')}</span>
                );
                case SignalType.Signal_SPO2: return '%';
                default: return '';
                }
            })();

            const topRightContent = displayPreviousNights ? (
                <div className="average-block">
                    <p>
                        <HealthReportStatisticsTrend
                            hasData={Math.min(
                                eventsSummary?.events?.hasData ?? HasData.HAS_NO_DATA,
                                eventsSummary?.eventsPrevious?.hasData ?? HasData.HAS_NO_DATA
                            )}
                            trend={eventsSummary?.trend ?? Trend.NORMAL}
                        />
                        {t('healthReport.aggregatedSignal.avgRecording')}{' '}
                        <AverageValue averageObj={eventsSummary?.events ?? null} unit={averageUnit} precision={2} />
                    </p>
                    <p>
                        {t('healthReport.aggregatedSignal.avgLast14Nights')}{' '}
                        <AverageValue averageObj={eventsSummary?.eventsPrevious ?? null} unit={averageUnit} precision={2} />
                    </p>
                </div>
            ) : (
                <div className="average-block">
                    <p>
                        {t('healthReport.statistics.averageForPeriod')}{' '}
                        <AverageValue averageObj={averageEventsPerHourForPeriod ?? null} unit={averageUnit} precision={2} />
                    </p>
                </div>
            );

            const tooltipText = <>
                <p>
                    {signalType === SignalType.Signal_PR &&
                        <>{t('infoButton.eventsPerDay.pulseRate')}&nbsp;</>
                    }
                    {signalType === SignalType.Signal_SPO2 &&
                        <>{t('infoButton.eventsPerDay.spo2')}&nbsp;</>
                    }
                    {t('infoButton.eventsPerDay.reliableData')}
                </p>
                <p>
                    {t('infoButton.eventsPerDay.duration')}
                </p>
            </>;

            const eventsDomain = getDomainForEvents((eventsPerDayFormatted.length === 0 ? 0 : getMax(eventsPerDayFormatted, (event) => event.events ?? 0).events) ?? 0, false);

            const maxDuration = ignoreUnifiedDuration ?
                (eventsPerDayFormatted.length === 0 ? 0 : getMax(eventsPerDayFormatted, (event) => event.reliableDurationInSeconds).reliableDurationInSeconds) :
                (unifiedDuration?.maxUnifiedDurationInSeconds ?? 0);
            const durationDomain = getDomainForDuration(maxDuration);

            const bestHasData: HasData = (() => {
                if (displayPreviousNights) {
                    const hasDataForDay = eventsSummary!.events!.hasData ?? HasData.HAS_NO_DATA;
                    const hasDataForPrevious = eventsSummary!.eventsPrevious!.hasData ?? HasData.HAS_NO_DATA;
                    return Math.max(hasDataForDay, hasDataForPrevious);
                }
                else {
                    return averageEventsPerHourForPeriod?.hasData ?? HasData.HAS_NO_DATA;
                }
            })();

            return (
                <StyledWrapper className="health-report-percentage-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                        topRightContent={topRightContent}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t('infoButton.eventsPerDay.title')}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {(bestHasData < HasData.HAS_MIN_RELIABLE_DATA) ? (
                            <div className="empty-message">
                                {(bestHasData === HasData.HAS_NO_DATA) && (
                                    t('healthReport.events.last7nights.noRecording', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_DATA) && (
                                    t('healthReport.events.last7nights.noReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_RELIABLE_DATA) && (
                                    t('healthReport.events.last7nights.notEnoughReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                            </div>
                        ) : (eventsPerDayNotZero <= 0) ? (
                            <div className="empty-message">
                                {t('healthReport.events.last7nights.noEvent', {
                                    nbDays: nbDays,
                                })}
                            </div>
                        ) : (
                            <ComposedChart height={250} data={eventsPerDayFormatted} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick}>
                                <XAxis dataKey="date" interval="equidistantPreserveStart" tick={<ChartAxisTickDate textAnchor="middle" />} />
                                <YAxis
                                    yAxisId="left"
                                    orientation="left"
                                    dataKey="events"
                                    width={26}
                                    domain={eventsDomain.domain}
                                    ticks={eventsDomain.ticks}
                                    interval={0}
                                    padding={{ top: 10 }}
                                    tick={(props) => <ChartAxisTickColored {...props} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} textAnchor="end" />}
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    dataKey="duration"
                                    width={34}
                                    domain={durationDomain.domain}
                                    ticks={durationDomain.ticks}
                                    interval={0}
                                    tick={<ChartAxisTickDuration textAnchor="start" />}
                                    padding={{ top: 10 }}
                                />
                                <Bar yAxisId="right" dataKey="duration" barSize={40} fill={colorPalette.thirdBackground}/>
                                <Line
                                    type="step"
                                    dot={{ fill: signalColors[getSignalTypeIdentifier(signalType)] }}
                                    dataKey="events"
                                    stroke={signalColors[getSignalTypeIdentifier(signalType)]}
                                    strokeWidth={2}
                                    isAnimationActive={false}
                                    yAxisId="left"
                                />
                                <Tooltip content={renderTooltip} isAnimationActive={false} />
                            </ComposedChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportPercentagePerDay" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: relative;
    min-height: 260px;
    height: 100%;
    display: flex; 
    flex-direction: column;
    
    > * {
      flex-grow: 1;
    }

    .tooltip-events {
        color: ${props => props.color};
    }

    .tooltip-duration {
        color: ${colorPalette.clearColor};
    }
    
    svg {
        cursor: pointer;
    }
    
    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportPercentagePerDay};
