import {useTranslation} from 'react-i18next';

import {EventConfigurationForm} from '@/components/business/analytics/common/event-configuration-form';

type PatientUpdateEventConfigurationProps = {
    className?: string;
    patientId: string;
    onChanged: (dataChanged: boolean) => void;
};

function PatientUpdateEventConfiguration({className, patientId, onChanged}: PatientUpdateEventConfigurationProps) {
    const { t } = useTranslation();
    return (
        <div className={`patient-update-event-configuration ${className ?? ''}`}>
            <h3>{t(['Event configuration', 'updateEventConfiguration.title'])}</h3>

            <EventConfigurationForm
                patientId={patientId}
                onCancel={() => { /* do nothing, here to make sure the button is shown */ }}
                onChanged={onChanged}
            />
        </div>
    );
}

export { PatientUpdateEventConfiguration };
