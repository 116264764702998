import {subDays} from 'date-fns';
import {AlertType, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {getI18n, withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Styled from 'styled-components';

import GuidedTourHealthReport from '@/components/business/analytics/guidedTour/guided-tour-health-report';
import {HealthReportAggregatedSignal} from '@/components/business/analytics/health-report/health-report-aggregated-signal';
import HealthReportDuration from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsList} from '@/components/business/analytics/health-report/health-report-events-list';
import {HealthReportEventsPerHour} from '@/components/business/analytics/health-report/health-report-events-per-hour';
import HealthReportNoRecording from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {withBackendQuery} from '@/decorators/with-backend-query';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {useBackendQuery} from '@/hooks/use-backend-query';
import timelineIcon from '@/public/icons/timeline-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {withTutorialStatus} from '@/services/tutorial-service';
import {colorPalette, colors, medias} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {getSignalTypeUnit} from '@/util/signal-type-util';
import {formatApiDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component) {
    return Page({
        name: () => getI18n().t(['Health Report - last night', 'healthReport.lastnight.title']),
        pagePath: props => (
            getPagePath(props)
        )
    })(Component);
}

function withEventConfig(Component) {
    return withBackendQuery('signalsEventConfigurationLoading', 'signalsEventConfiguration', 'signalsEventConfigurationError', props => useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: props.params.id_patient } },
    }))(Component);
}

function withPatientData(Component) {
    return withBackendQuery('patientDataLoading', 'patientData', 'patientDataError', props => usePatientData({
        patientId: props.params.id_patient,
    }))(Component);
}

class HealthReportLastAvailableNightPage extends React.Component {
    static propTypes = {
        signalsEventConfiguration: PropTypes.object,
        signalsEventConfigurationLoading: PropTypes.bool.isRequired,
        patientData: PropTypes.object,
        patientDataLoading: PropTypes.bool.isRequired,
        t: PropTypes.func,
        params: PropTypes.object,
        className: PropTypes.string,
        tutorialStatus: PropTypes.any,
        navigate: PropTypes.func,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const patientId = props.params.id_patient;
        const patientData = props.patientData;
        const eventConfiguration = props.signalsEventConfiguration;
        const t = props.t;
        
        const loading = (
            (props.signalsEventConfigurationLoading || !eventConfiguration) ||
            (props.patientDataLoading || !patientData)
        );
        
        if (loading) {
            return (
                <HealthReportWrapper
                    patientId={patientId}
                    patientData={patientData}
                    activeDashboard={HealthReportDashboardTypeEnum.LAST_24H}
                    eventConfiguration={eventConfiguration}
                    onChanged={this.handleEventConfigurationChanged}
                >
                    <LoadingView />
                </HealthReportWrapper>
            );
        }
        
        let dateFrom = null;
        let dateTo = null;
        let dashboardHasContent = false;
        let timelineDate = null;
        if (props.patientData.lastRecordingDate) {
            dateFrom = formatApiDateToJSDate(props.patientData.lastRecordingDate);
            dateTo = formatApiDateToJSDate(props.patientData.lastRecordingDate);
            timelineDate = formatApiDateForUrl(props.patientData.lastRecordingDate);
            const lastRecordingDateLimit = subDays(NewDateUtil(), 30);
            dashboardHasContent = dateFrom.getTime() > lastRecordingDateLimit.getTime();
        }

        return (
            <div className={props.className}>
                <HealthReportWrapper
                    patientId={patientId}
                    patientData={props.patientData}
                    activeDashboard={HealthReportDashboardTypeEnum.LAST_24H}
                    eventConfiguration={eventConfiguration}
                    onChanged={this.handleEventConfigurationChanged}
                >
                    {props.tutorialStatus.guidedTourHealthReport && (
                        <GuidedTourHealthReport />
                    )}

                    {dashboardHasContent && (
                        <div className="health-report-switch-wrapper">
                            <HealthReportDuration patientId={patientId} dateFrom={dateFrom} dateTo={dateTo}/>
                            <Link
                                to={`/patients/${patientId}/timeline/${timelineDate}`}
                                id="button-timeline"
                                className="button-link"
                            >
                                {t(['Daily biometrics', 'healthReportDashboardNavigation.dailyBiometrics'])}
                            </Link>
                        </div>
                    )}

                    {dashboardHasContent && (
                        <div className={'health-report-content health-report-content-view'}>
                            <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.pulseRate}>
                                <HealthReportAggregatedSignal
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    date={dateFrom}
                                    signalEventConfiguration={eventConfiguration.configuration.hr}
                                    onClick={this.handleAggregatedSignalChartClick}
                                />
                            </ColoredContainer>

                            <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                <HealthReportEventsPerHour
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    alertTypes={[ AlertType.Alert_HIGH, AlertType.Alert_LOW ]}
                                    date={dateFrom}
                                    subtitle={<span>
                                        {t(['#events/h with aduration of X seconds', 'healthReport.events.last24h.subtitle'], {duration: eventConfiguration.configuration.hr.sensibility.seconds})}
                                    </span>}
                                    signalEventConfiguration={eventConfiguration.configuration.hr}
                                    onClick={this.handleEventsPerHourChartClick}
                                />
                            </ColoredContainer>
                            
                            <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.spo2}>
                                <HealthReportAggregatedSignal
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    date={dateFrom}
                                    signalEventConfiguration={eventConfiguration.configuration.spo2}
                                    onClick={this.handleAggregatedSignalChartClick}
                                />
                            </ColoredContainer>

                            <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                <HealthReportEventsPerHour
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    alertTypes={[ AlertType.Alert_LOW ]}
                                    date={dateFrom}
                                    subtitle={`
                                        ${t(['Below', 'global.below'])} ${eventConfiguration.configuration.spo2.low.value}${getSignalTypeUnit(SignalType.Signal_SPO2)}
                                    `}
                                    signalEventConfiguration={eventConfiguration.configuration.spo2}
                                    onClick={this.handleEventsPerHourChartClick}
                                />
                            </ColoredContainer>

                            <HealthReportEventsList
                                patientId={patientId}
                                date={dateFrom}
                                eventConfiguration={eventConfiguration}
                            />
                        </div>
                    )}
                    {!dashboardHasContent && (
                        <HealthReportNoRecording patient={props.patientData} activeDashboard={HealthReportDashboardTypeEnum.LAST_24H} />
                    )}
                </HealthReportWrapper>
            </div>
        );
    }

    handleAggregatedSignalChartClick(data) {
        const selectedHour = data.at.time;
        let url = `/patients/${this.props.params.id_patient}/timeline/${data.at.date}/${selectedHour}`;
        this.props.navigate(url);
    }

    handleEventsPerHourChartClick(data) {
        const selectedHour = (data.hour < 10) ? `0${data.hour}:00` : `${data.hour}:00`;
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatApiDateForUrl(data.time.date)}/${selectedHour}`;
        this.props.navigate(url);
    }
}

//language=SCSS
HealthReportLastAvailableNightPage = Styled(HealthReportLastAvailableNightPage)`
& {
    .health-report-switch-wrapper {
        position:relative;
        margin-bottom:20px;
        #button-timeline {
            background: ${colors.buttonCreate.background} url(${timelineIcon}) no-repeat 15px center;
            background-size: 25px;
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px 15px 10px 50px;
            border-radius: 5px;
            &:hover, &:focus {
                background-color: ${colors.buttonCreate.backgroundHover};
            }
        }
    }
    
    .health-report-content {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        @media screen and (${medias.xlMin}) {
          grid-template-columns: minmax(0, 2fr) minmax(250px, 1fr) minmax(260px, 1fr);
        }
        
        grid-auto-rows: 340px;
        flex-wrap: nowrap;
        gap: 10px;
    }

    .health-report-events-list {
        display: none;
        @media screen and (${medias.xlMin}) {
            display: block;
        }
        grid-column: 3;
        grid-row: 1;
        grid-row-end: span 2;
    }
    
    .widget-aggregated-signal {
        grid-column: 1;
    }
    
    .widget-events {
        grid-column: 2;
    }
}
`;

HealthReportLastAvailableNightPage = withRouter(
    withTutorialStatus(withEventConfig(withPatientData(
        wrapWithPage(withTranslation()(HealthReportLastAvailableNightPage))
    )))
);

export {HealthReportLastAvailableNightPage};
