import {addSeconds} from 'date-fns';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import styled from 'styled-components';

import HealthReportActigraphySignalSample from '@/components/business/analytics/health-report/health-report-actigraphy-signal-sample';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {
    ChartAxisTickHourMinutesFromTimestamp,
} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-minutes-from-timestamp';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains-old';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, getSignalTypeUnit, SignalTypeEnum} from '@/enum/signal-type-enum';
import timelineIcon from '@/public/icons/timeline-icon.svg';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette, colors} from '@/themes/darkmode';
import {formatDateTimeUniversal} from '@/util/date-utils';
import {formatTimeFragmentTwoDigits} from '@/util/time-utils';

const HealthReportSignalSample = ({patientId, signalType, eventTimestamp, dateFrom, duration, signalEventConfiguration}) => {
    const { t } = useTranslation();
    const [signalData, setSignalData] = useState(null);
    const [medicalEvents, setMedicalEvents] = useState(null);
    const [actigraphyData, setActigraphyData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSignalSample();
    }, []);

    const getSignalSample = () => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getSignalSample',
            data: {
                patientId: patientId,
                signalType: signalType,
                dateFrom: dateFrom,
                duration: duration,
            }
        })
            .then((response) => {
                setSignalData(response.signalData?.blocksList);
                setMedicalEvents(response.signalData?.eventsList);
                setActigraphyData(response.actigraphyData?.blocksList);
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    const renderTooltip = (data) => {
        if (data?.payload) {
            const color = (data?.payload[0]?.payload?.reliability && data?.payload[0]?.payload?.reliableValue.value && data?.payload[0]?.payload?.reliableValue.value !== 'noData') ?
                colorPalette.signalType[getSignalTypeIdentifier(signalType)] :
                colorPalette.clearColor;

            return (
                <div className="chart-tooltip">
                    <strong style={{color: color}}>
                        <div>{t(`global.${getSignalTypeIdentifier(signalType)}`)}:&nbsp;
                            {(data?.payload[0]?.payload?.reliability && data?.payload[0]?.payload?.reliableValue?.value) ?
                                Math.round(data?.payload[0]?.payload?.reliableValue?.value) + getSignalTypeUnit(signalType)
                                : (data?.payload[0]?.payload?.value === 'noData') ?
                                    t(['No data available', 'global.noDataAvailable']) :
                                    t(['Not Reliable', 'global.notReliable'])
                            }
                        </div>
                    </strong>
                    {formatDateTimeUniversal(new Date(data?.payload[0]?.payload?.at))}
                </div>
            );
        }
        return null;
    };

    if (patientId && dateFrom) {
        if (!loading) {
            return (
                <StyledWrapper>
                    <h3>{formatDateTimeUniversal(dateFrom)} - {formatDateTimeUniversal(addSeconds(dateFrom, 300))}</h3>

                    <Link className="timeline-link" to={`/patients/${patientId}/timeline/${eventTimestamp.date.year}-${eventTimestamp.date.month<10 ? '0' : ''}${eventTimestamp.date.month}-${eventTimestamp.date.day<10 ? '0' : ''}${eventTimestamp.date.day}/${formatTimeFragmentTwoDigits(eventTimestamp.time.hour)}:${formatTimeFragmentTwoDigits(eventTimestamp.time.minutes)}:${formatTimeFragmentTwoDigits(eventTimestamp.time.seconds)}`}>
                        {t(['Show this event in the Daily biometrics', 'healthReport.events.list.dailyBiometrics'])}
                    </Link>

                    <div>
                        <LineChart data={signalData} width={450} height={200}>
                            <CartesianGrid strokeDasharray="1 3" />

                            <XAxis
                                dataKey="at"
                                tick={<ChartAxisTickHourMinutesFromTimestamp textAnchor="right" />}
                                interval={60}
                            />

                            <YAxis
                                domain={getDomainForSignalType(signalType).domain}
                                tick={(props) => <ChartAxisTickColored {...props} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} unit={getSignalTypeUnit(signalType)} />}
                                type="number"
                                tickCount={4}
                            />

                            {medicalEvents.map(block => {
                                if (block.from === block.to) {
                                    return (
                                        <ReferenceLine
                                            key={`eventBlock-pulseRate-${block.from}`}
                                            x={block.from}
                                            stroke={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                            strokeWidth={0.5}
                                        />
                                    );
                                }
                                else {
                                    return (
                                        <ReferenceArea
                                            key={block.from}
                                            x1={block.from}
                                            x2={block.to}
                                            fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                            strokeOpacity={0.0}
                                            fillOpacity={0.25}
                                        />
                                    );
                                }
                            })}

                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="value"
                                stroke={colorPalette.clearColor}
                                strokeWidth={1.2}
                                connectNulls={false}
                            />
                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="reliableValue.value"
                                stroke={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                strokeWidth={1.5}
                                opacity={1}
                                connectNulls={false}
                            />
                            {signalType !== SignalTypeEnum.SPO2 &&
                                <ReferenceLine
                                    y={signalEventConfiguration.high.value}
                                    label={{
                                        position: (signalEventConfiguration.high.value >= (getDomainForSignalType(signalType).domain[1] - (5))) ? 'bottom' : 'top',
                                        value: `${signalEventConfiguration.high.value}${getSignalTypeUnit(signalType)}`,
                                        fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]
                                    }}
                                    stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]}
                                    strokeDasharray="4"
                                />
                            }
                            <ReferenceLine
                                y={signalEventConfiguration.low.value}
                                label={{
                                    position: (signalEventConfiguration.low.value <= (getDomainForSignalType(signalType).domain[0] + (5))) ? 'top' : 'bottom',
                                    value: `${signalEventConfiguration.low.value}${getSignalTypeUnit(signalType)}`,
                                    fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]
                                }}
                                stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(signalType)]}
                                strokeDasharray="4"
                            />
                            <Tooltip
                                content={renderTooltip}
                                data={signalData}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    </div>

                    <HealthReportActigraphySignalSample actigraphyData={actigraphyData} />
                </StyledWrapper>
            );
        }
        else {
            return (
                <LoadingView color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]} />
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportSignalSample" />;
    }
};

HealthReportSignalSample.propTypes = {
    patientId: PropTypes.string.isRequired,
    signalType: PropTypes.number.isRequired,
    eventTimestamp: PropTypes.object.isRequired,
    dateFrom: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,
    signalEventConfiguration: PropTypes.object.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    margin-top: 5px;
    
    .timeline-link {
        display: inline-block;
        margin-bottom: 20px;
        background: ${colors.buttonCreate.background} url(${timelineIcon}) no-repeat 15px center;
        background-size: 25px;
        color: ${colorPalette.frontColor};
        padding: 10px 15px 10px 50px;
        border-radius: 5px;
        
        &:hover, &:focus {
            background-color: ${colors.buttonCreate.backgroundHover};
            text-decoration: none;
        }
    }
}
`;

export default HealthReportSignalSample;
