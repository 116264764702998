import {subDays} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import {PatientsActiveTable} from '@/components/business/analytics/patients/patients-active-table';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {useTutorialStatus} from '@/services/tutorial-service';
import {NewDateUtil} from '@/util/new-date-util';

const PatientsActive = ({program}) => {
    const dateTo = NewDateUtil();
    const dateFrom = subDays(dateTo, 6);
    
    const tutorialStatus = useTutorialStatus();
    
    const [activePatientsLoading, activePatients] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatientsWithRecording,
        data: {
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
        },
        memoize: false,
    });

    if (program !== null) {
        if (!activePatientsLoading && activePatients) {
            const patients = [ ...activePatients.patient ];
            if (tutorialStatus.demoPatient) {
                patients.unshift(DemoPatient.patient);
            }
            return (
                <StyledWrapper>
                    <PatientsActiveTable
                        patients={patients}
                        program={program}
                    />
                </StyledWrapper>
            );
        }
        else {
            return <LoadingView size="large" />;
        }
    }
    else {
        return <ComponentErrorMessage component="PatientsActive" />;
    }
};

PatientsActive.propTypes = {
    program: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      padding-bottom: 50px;
  }
`;

export {PatientsActive};
