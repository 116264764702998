import {HcpQueryServiceClient} from 'gabi-api-ts/v2/hcp/query/hcp_query.client';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {buttonFilledTheme} from '@/components/buttons/button-filled-theme';
import {ErrorMessage} from '@/components/form/error-message';
import LoadingView from '@/components/static/loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {colors} from '@/themes/darkmode';

type HcpUpdatePasswordProps = {
    className?: string,
};
function HcpUpdatePasswordUnstyled({ className }: HcpUpdatePasswordProps) {
    const [passwordRequested, setPasswordRequested] = useState(false);
    const [passwordRequestLinkLoading, passwordRequestLink, passwordRequestError] = useBackendQuery({
        serviceClient: HcpQueryServiceClient,
        query: HcpQueryServiceClient.prototype.getChangePasswordLink,
        disabled: !passwordRequested,
        data: {},
    }, [ passwordRequested ]);

    const { t } = useTranslation();

    return (
        <div className={`update-password ${className ?? ''}`}>
            <div>
                <h3>{t('hcpUpdatePassword.title')}</h3>
            </div>
            {!passwordRequested ? (
                <button className="request-password-btn" onClick={() => {
                    setPasswordRequested(true);
                }}>{t('hcpUpdatePassword.requestLink')}</button>
            ) : (
                <div>
                    {passwordRequestLinkLoading ? (
                        <LoadingView />
                    ) : (
                        passwordRequestLink ? (
                            <>
                                <div>{t('hcpUpdatePassword.success')}</div>
                                <a target="_blank" rel="noreferrer" className="change-password-link" href={passwordRequestLink.link}>
                                    {t('hcpUpdatePassword.changePasswordLink')}
                                </a>
                            </>
                        ) : (
                            <>
                                {passwordRequestError && (
                                    <ErrorMessage errorMessage={t('hcpUpdatePassword.error')} text={passwordRequestError?.message ?? 'unknown error'} />
                                )}
                            </>
                        )
                    )}
                </div>
            )}
        </div>
    );
}

//language=SCSS
const HcpUpdatePassword = Styled(HcpUpdatePasswordUnstyled)`
& {
    .change-password-link, .request-password-btn {
        display: inline-block;
        margin-top: 15px;
        border: none;
        border-radius: 3px;
        color: ${colors.button.text};
        background-repeat: no-repeat;
        background-position: 10px center;
        padding: 7px 15px;
    }
    .request-password-btn {
        ${buttonFilledTheme(colors.buttonSearch)}
    }
    .change-password-link {
        ${buttonFilledTheme(colors.buttonCreate)}
    }
}
`;

export { HcpUpdatePassword };
