import {ServiceInfo} from '@protobuf-ts/runtime-rpc';

import {buildBackendCallKey, GrpcRequestFn, ServiceClientConstructor} from '@/services/api-requests/grpc-request-ts';
import {BackendApiCache} from '@/services/backend-api-cache';
import {ObservableVar} from '@/util/observable-var';

class _BackendApiRefreshListeners {
    refreshListeners: Record<string, ObservableVar<number>> = {};

    get(backendCallKey: string) {
        if (!this.refreshListeners[backendCallKey]) {
            this.refreshListeners[backendCallKey] = new ObservableVar<number>(0);
        }
        return this.refreshListeners[backendCallKey];
    }

    refreshByKey(backendCallKey: string) {
        // Clearing cache if any
        BackendApiCache.clearMatching(backendCallKey); // no need to check for memoize. If the key is not there, it does not matter
        const listener = this.refreshListeners[backendCallKey];
        if (!listener) {
            console.debug(`No listener for key ${backendCallKey}. No refresh needed`);
            return;
        }
        listener.set(listener.value+1);
    }

    refreshByCall<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
    (serviceClient: ServiceClientConstructor<ServiceClient>, query: GrpcRequestFn<Request, Response>) {
        this.refreshByKey(buildBackendCallKey(serviceClient, query));
    }
}

const BackendApiRefreshListeners = new _BackendApiRefreshListeners();

export { BackendApiRefreshListeners };
