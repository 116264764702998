import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum.jsx';
import arrowDownIcon from '@/public/icons/circle-arrow-down-icon.svg';
import arrowRightIcon from '@/public/icons/circle-arrow-right-icon.svg';
import {colorPalette} from '@/themes/darkmode.js';

const HealthReportNavigation = ({activeDashboard, patientId}) => {
    const { t } = useTranslation();

    return (
        <StyledWrapper>
            <nav>
                <Link to={`/patients/${patientId}/healthReport`} className={(activeDashboard === HealthReportDashboardTypeEnum.LAST_24H ? 'active' : '')}>{t(['Last available night', 'healthReportDashboardNavigation.lastAvailableNight'])}</Link>
                <Link to={`/patients/${patientId}/healthReport/last7days`} className={(activeDashboard === HealthReportDashboardTypeEnum.LAST_7DAYS ? 'active' : '')}>{t(['Last 7 nights', 'healthReportDashboardNavigation.last7Nights'])}</Link>
                <Link to={`/patients/${patientId}/healthReport/last30Days`} className={(activeDashboard === HealthReportDashboardTypeEnum.LAST_30DAYS ? 'active' : '')}>{t(['Last 30 nights', 'healthReportDashboardNavigation.last30Nights'])}</Link>
                <Link to={`/patients/${patientId}/healthReport/trends`} className={(activeDashboard === HealthReportDashboardTypeEnum.TRENDS ? 'active' : '')}>{t(['Trends 90 nights', 'healthReportDashboardNavigation.trends90Nights'])}</Link>
            </nav>
        </StyledWrapper>
    );
};

HealthReportNavigation.propTypes = {
    activeDashboard: PropTypes.number,
    patientId: PropTypes.string,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      margin-bottom: 30px;
      
      a {
          display:inline-block;
          border-radius: 20px;
          color: ${colorPalette.frontColor};
          padding: 5px 15px 5px 35px;
          background: ${colorPalette.thirdBackground} url(${arrowRightIcon}) no-repeat 10px center;
          background-size: 17px;
          margin-right: 10px;
          &:hover, &:focus {
              text-decoration: none;
              background-color: ${colorPalette.activeColorDarker};
          }
      }
      .active {
          &, &:hover, &:focus {
              background-color: ${colorPalette.activeColor};
              background-image: url(${arrowDownIcon});
          }
      }
  }
`;

export default HealthReportNavigation;
