import {SignalEventConfiguration, SignalsEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {getI18n} from 'react-i18next';

const getSignalTypeLabel = (signalType: SignalType) => {
    switch(signalType) {
    case SignalType.Signal_PR:
        return getI18n().t('global.pulseRate');
    case SignalType.Signal_SPO2:
        return getI18n().t('global.spo2');
    case SignalType.Signal_Actigraphy:
        return getI18n().t('global.movements');
    default:
        return 'Unknown';
    }
};

const getSignalTypeUnit = (signalType: SignalType) => {
    switch(signalType) {
    case SignalType.Signal_PR:
        return 'bpm';
    case SignalType.Signal_SPO2:
        return '%';
    case SignalType.Signal_Actigraphy:
        return 'G';
    default:
        return '?';
    }
};

const getSignalTypeIdentifier = (signalType: SignalType) => {
    switch(signalType) {
    case SignalType.Signal_PR:
        return 'pulseRate';
    case SignalType.Signal_SPO2:
        return 'spo2';
    case SignalType.Signal_Actigraphy:
        return 'movements';
    default:
        return 'unknown';
    }
};

function getSignalEventConfig(eventConfig: SignalsEventConfiguration, signalType: SignalType): SignalEventConfiguration {
    switch (signalType) {
    case SignalType.Signal_PR: return eventConfig.hr!;
    case SignalType.Signal_RR: return eventConfig.rr!;
    case SignalType.Signal_SPO2: return eventConfig.spo2!;
    default: {
        console.error(`getSpecificSignalEventConfig(): wrong signal provided ${SignalType[signalType]}. Falling back to pulse rate.`);
        return eventConfig.hr!;
    }
    }
}

export {
    getSignalEventConfig,
    getSignalTypeIdentifier,
    getSignalTypeLabel,
    getSignalTypeUnit,
};
