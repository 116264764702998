import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import GroupAddUser from '@/components/business/analytics/group/group-add-user.jsx';
import GroupDelete from '@/components/business/analytics/group/group-delete.jsx';
import GroupInviteUser from '@/components/business/analytics/group/group-invite-user.jsx';
import {GroupPendingUsersList} from '@/components/business/analytics/group/group-pending-users-list.jsx';
import GroupRename from '@/components/business/analytics/group/group-rename.jsx';
import {GroupUsersList} from '@/components/business/analytics/group/group-users-list.jsx';
import {Button} from '@/components/buttons/button.jsx';
import {Modal} from '@/components/layout/modal';
import LoadingView from '@/components/static/loading-view.tsx';
import {BackendApiService} from '@/services/backend-api-service.js';

class GroupDetails extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        group: PropTypes.object.isRequired,
        isMeGroupAdmin: PropTypes.bool.isRequired,
        onGroupChanged: PropTypes.func,
        onUsersChanged: PropTypes.func,
        onGroupDeleted: PropTypes.func,
    };

    state = {
        loading: false,
        showGroupAddUserModal: false,
        showGroupAddUserInviteModal: false,
        showGroupRenameModal: false,
        showGroupDeleteModal: false,
        pendingInvitations: null,
        groupDeleted: false,
        invitationEmail: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.getPendingInvitations();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                {!state.loading && state.showGroupAddUserModal &&
                    <Modal title={t(['Add user to group', 'groupDetails.addUserModalTitle'])} onClosed={this.handleCloseModal}>
                        <GroupAddUser group={props.group} onAdded={this.handleUserAdded} onCancelled={this.handleCloseModal} onNotFound={(email) => this.handleUserNotFound(email)} />
                    </Modal>
                }

                {!state.loading && state.showGroupAddUserInviteModal &&
                    <Modal title={t(['User invitation', 'groupDetails.addUserInviteModalTitle'])} onClosed={this.handleCloseModal}>
                        <GroupInviteUser group={props.group} email={this.state.invitationEmail || ''} onInvited={this.handleUserInvited} onCancelled={this.handleCloseModal} />
                    </Modal>
                }

                {!state.loading && state.showGroupRenameModal &&
                    <Modal title={t(['Rename group', 'groupDetails.groupRenameModalTitle'])} onClosed={this.handleCloseModal}>
                        <GroupRename group={props.group} onRenamed={this.handleGroupRenamed} onCancelled={this.handleCloseModal} />
                    </Modal>
                }

                {!state.loading && state.showGroupDeleteModal &&
                    <Modal title={t(['Delete group', 'groupDetails.groupDeleteModal'])} onClosed={this.handleCloseModal}>
                        <GroupDelete group={props.group} onDeleted={this.handleGroupDeleted} onCancelled={this.handleCloseModal} />
                    </Modal>
                }

                {!state.loading &&
                    <div>
                        {props.isMeGroupAdmin &&
                            <div className="group-actions">
                                <Button className="button-create" displayIcon={true} onClick={this.handleOpenGroupAddUserModal}>{t(['Add user to group', 'groupDetails.actions.create'])}</Button>
                                <Button className="button-edit-filled-hover" displayIcon={true} onClick={this.handleOpenGroupRenameModal}>{t(['Rename group', 'groupDetails.actions.rename'])}</Button>
                                <Button className="button-delete-filled-hover" displayIcon={true} onClick={this.handleOpenGroupDeleteModal}>{t(['Delete group', 'groupDetails.actions.delete'])}</Button>
                            </div>
                        }

                        {props.group?.usersList &&
                            <div className="group-users">
                                <GroupUsersList
                                    users={props.group.usersList}
                                    groupId={props.group.groupId.id}
                                    isMeGroupAdmin={props.isMeGroupAdmin}
                                    onUserRemoved={this.handleUserRemoved}
                                    onRoleChanged={this.handleRoleChanged}
                                />
                            </div>
                        }

                        {state.pendingInvitations?.emailList.length > 0 &&
                            <div className="group-pending-users">
                                <h3>Pending invitations</h3>
                                <GroupPendingUsersList
                                    emails={state.pendingInvitations.emailList}
                                    groupId={props.group.groupId.id}
                                />
                            </div>
                        }
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    handleOpenGroupAddUserModal() {
        this.setState(state => ({
            ...state,
            showGroupAddUserModal: true,
        }));
    }

    handleOpenGroupRenameModal() {
        this.setState(state => ({
            ...state,
            showGroupRenameModal: true,
        }));
    }

    handleOpenGroupDeleteModal() {
        this.setState(state => ({
            ...state,
            showGroupDeleteModal: true,
        }));
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showGroupAddUserModal: false,
            showGroupAddUserInviteModal: false,
            showGroupRenameModal: false,
            showGroupDeleteModal: false,
        }));
    }

    handleUserAdded() {
        this.handleCloseModal();
        if(this.props.onUsersChanged) {
            this.props.onUsersChanged(this.props.group);
        }
    }

    handleUserInvited() {
        this.handleCloseModal();
        if(this.props.onUsersChanged) {
            this.props.onUsersChanged(this.props.group);
        }
    }

    handleUserNotFound(email) {
        this.setState(state => ({
            ...state,
            showGroupAddUserModal: false,
            showGroupAddUserInviteModal: true,
            invitationEmail: email,
        }));
    }

    handleUserRemoved() {
        this.handleCloseModal();
        if(this.props.onUsersChanged) {
            this.props.onUsersChanged(this.props.group);
        }
    }

    handleRoleChanged() {
        this.handleCloseModal();
        if(this.props.onUsersChanged) {
            this.props.onUsersChanged(this.props.group);
        }
    }

    handleGroupRenamed(/*group*/) {
        this.handleCloseModal();
        if(this.props.onGroupChanged) {
            this.props.onGroupChanged(this.props.group);
        }
    }

    handleGroupDeleted(/*group*/) {
        this.setState(state => ({
            ...state,
            groupDeleted: true,
        }));
        this.handleCloseModal();
        if(this.props.onGroupDeleted) {
            this.props.onGroupDeleted();
        }
    }

    getPendingInvitations() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getPendingInvitations',
            data: {
                id: this.props.group.groupId.id
            }
        }).then(pendingInvitations => {
            this.setState(state => ({
                ...state,
                loading: false,
                pendingInvitations: pendingInvitations,
            }));

            return pendingInvitations;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props.group, nextProps.group)
            || !deepEqual(this.state.showGroupAddUserModal, nextState.showGroupAddUserModal)
            || !deepEqual(this.state.showGroupAddUserInviteModal, nextState.showGroupAddUserInviteModal)
            || !deepEqual(this.state.showGroupRenameModal, nextState.showGroupRenameModal)
            || !deepEqual(this.state.showGroupDeleteModal, nextState.showGroupDeleteModal)
            || !deepEqual(this.state.pendingInvitations, nextState.pendingInvitations)
            || !deepEqual(this.props, nextProps);
    }
}

//language=SCSS
GroupDetails = Styled(GroupDetails)`
& {
    padding:0 25px 25px 25px;
    
    .group-actions {
        button {
            margin-right:10px;
        }
    }
    
    .group-users {
        margin-top:20px;
    }
    
    .group-pending-users {
        h3 {
            font-size:16px;
        }
    }
}
`;

export default withTranslation()(GroupDetails);
