import {addDays, subDays} from 'date-fns';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientRecordingGetDuration from '@/components/business/analytics/patients/patient-recording-get-duration.jsx';
import IconTooltip from '@/components/buttons/icon-tooltip';
import warningIcon from '@/public/icons/warning-icon.svg';
import {colorPalette} from '@/themes/darkmode.js';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util.js';

const HealthReportDuration = ({patientId, dateFrom, dateTo}) => {
    const { t } = useTranslation();

    const sevenDaysAgo = subDays(NewDateUtil(), 7);
    const formattedDateFrom = formatDateUniversal(dateFrom);
    const formattedDateTo = (formatDateUniversal(dateFrom) === formatDateUniversal(dateTo)) ? formatDateUniversal(addDays(dateTo, 1)) : formatDateUniversal(dateTo);

    return (
        <StyledWrapper>
            <span>
                <strong>{t(['Recording period', 'healthReportDuration.title'])}</strong>&nbsp;&nbsp;
            </span>
            <span>{formattedDateFrom} - {formattedDateTo}</span>
            {dateTo.getTime() < sevenDaysAgo.getTime() &&
                <div className="oldDataTooltip">
                    <IconTooltip icon={warningIcon} iconAltText={t(['Red warning icon', 'healthReportDuration.oldDataImgAltText'])}>
                        {t(['Be aware that the displayed data are older than 7 days.', 'healthReportDuration.oldDataText'])}
                    </IconTooltip>
                </div>
            }
            {formatDateUniversal(dateFrom) === formatDateUniversal(dateTo) &&
                <span>
                    <strong>{t(['Duration', 'healthReportDuration.duration'])}</strong>&nbsp;&nbsp;
                    <PatientRecordingGetDuration patientId={patientId} recordingDate={dateFrom} />
                </span>
            }
        </StyledWrapper>
    );
};

HealthReportDuration.propTypes = {
    patientId: PropTypes.string.isRequired,
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    background: ${colorPalette.secondBackground};
    border-radius: 5px;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: ${colorPalette.activeColor};
    padding: 10px 0 10px 15px;
    display: flex;
    height: 42px;
    
    span {
        display: inline-block;
    }
    
    div.oldDataTooltip {
        display: inline-block;
        margin-left: 10px;
        margin-top: -4px;
        button {
            margin: 0;
        }
    }
    
    span:last-child {
        border-left: 1px solid ${colorPalette.darkColor};
        padding-left: 10px;
        margin-left: 10px;
        display: flex;
    }
}
`;

export default HealthReportDuration;
