import type {FinishedUnaryCall, RpcOptions, RpcTransport, ServiceInfo, UnaryCall} from '@protobuf-ts/runtime-rpc';
import {RpcMetadata} from '@protobuf-ts/runtime-rpc/build/types/rpc-metadata';
import { PromisePool } from 'minimal-promise-pool';

import config from '@/config';
import {ErrorTrackingService} from '@/services/error-tracking-service';
import {FeedbackAlertsService, FeedbackAlertType} from '@/services/feedback-alerts-service';
import {grpcWebFetchTransport} from '@/services/grpc/grpc-config';
import {grpcDevToolsInterceptors} from '@/services/grpc/grpc-dev-tools';

const API_KEY = config.firebase.apiKey;

const grpcPromisesPool = new PromisePool(8);

const getGrpcMetadata = (idToken: string) => {
    const metadata: RpcMetadata = {
        'x-api-key': API_KEY,
        'caller-id': 'analytics-web',
        'caller-version': `${__APP_VERSION__}-${__COMMIT_HASH__}`,
    };

    // In case of request without token
    if(idToken !== '') {
        metadata['Authorization'] = `Bearer ${idToken}`;
    }

    return metadata;
};

export type GrpcRequestFn<Request extends object, Response extends object> = (input: Request, options?: RpcOptions) => UnaryCall<Request, Response>;

export type ServiceClientConstructor<ServiceClient extends ServiceInfo> = new (_transport: RpcTransport) => ServiceClient;

export async function grpcRequest<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
(idToken: string, ServiceClient: ServiceClientConstructor<ServiceClient>, fn: GrpcRequestFn<Request, Response>, req: Request): Promise<FinishedUnaryCall<Request, Response>> {
    if (__GABI_ENV__ !== 'production') {
        console.debug('REQUEST PARAMS - ' + fn, req);
    }

    const service = new ServiceClient(grpcWebFetchTransport(grpcDevToolsInterceptors));

    try {
        return await grpcPromisesPool.runAndWaitForReturnValue(async () => await fn.call(service, req, {
            meta: getGrpcMetadata(idToken),
        }));
    }
    catch (error) {
        const err = error as Error;
        const backendErrorCode = err.message?.substring(0, 7); // Get error code from backend error message
        const backendErrorMessage = err.message?.substring(8, err.message.length); // Get error code from backend error message
        FeedbackAlertsService.add(FeedbackAlertType.ERROR, fn.name, backendErrorMessage, `${backendErrorCode}`);
        ErrorTrackingService.captureApiException(err, fn);
        throw error;
    }
}

export function buildBackendCallKey<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
(serviceClient: ServiceClientConstructor<ServiceClient>, query: GrpcRequestFn<Request, Response>) {
    // @ts-expect-error dynamic instanciation of the service object
    const fakeClient = new serviceClient('');
    return `${fakeClient.typeName}/${query.name}`;
}
