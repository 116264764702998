import {subDays} from 'date-fns';
import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {HasData, SignalType, Trend} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {floorHour} from 'gabi-web-common/util/date-util';
import {useTranslation} from 'react-i18next';

import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {NoDataCell} from '@/components/business/analytics/patient-overview-cells/no-data-cell';
import {PatientsActiveTableCellHoverable} from '@/components/business/analytics/patients/patients-active-table-cell-hoverable';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {MiniLoadingView} from '@/components/static/mini-loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {useDebugFlags} from '@/services/debug-service';
import {colorPalette} from '@/themes/darkmode';
import {getSignalTypeUnit, getSignalTypeIdentifier} from '@/util/signal-type-util';

type PulseAverageCellProps = {
    patient: Patient;
    signalType: SignalType;
    handleWidgetChartClick: (selectedDate: Date) => void;
}

function AverageCell({patient, signalType, handleWidgetChartClick}: PulseAverageCellProps) {
    const { t } = useTranslation();
    const debugFlags = useDebugFlags();
    const [averageSummaryLoading, averageSummary] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalAverageSummary,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: patient.patientId,
            date: patient.lastRecordingDate,
            nbPreviousDays: 14,
        },
    });

    if (averageSummaryLoading || !averageSummary) {
        return (
            <PatientsActiveTableCellHoverable
                hasHover={false}
                hoverElement={() => null}
            >
                <div className="text-center">
                    <MiniLoadingView />
                </div>
            </PatientsActiveTableCellHoverable>
        );
    }
    else {
        const lastRecordingDate = new Date(
            patient.lastRecordingDate!.year,
            patient.lastRecordingDate!.month-1,
            patient.lastRecordingDate!.day
        );
        return (
            <PatientsActiveTableCellHoverable
                hasHover={
                    debugFlags.alwaysShowTrendsOverlays || (
                        (averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
                        averageSummary.trend !== Trend.NORMAL &&
                        !isNaN(averageSummary.averageValue?.value ?? NaN)
                    )
                }
                hoverElement={() => (
                    <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                        <ErrorBoundary>
                            <ColoredContainer color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}>
                                {averageSummary.trend === Trend.NO_DATA &&
                                    <p className="trendsNoData">{t('pages.patients.trends.noData')}</p>
                                }
                                {averageSummary.trend !== Trend.NO_DATA &&
                                    <HealthReportStatisticsPerDay
                                        patientId={patient.patientId!.id}
                                        signalType={signalType}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(lastRecordingDate)}
                                        ignoreUnifiedDuration
                                        displayPreviousNights={true}
                                        onClick={handleWidgetChartClick}
                                    />
                                }
                            </ColoredContainer>
                        </ErrorBoundary>
                    </div>
                )}
            >
                {((averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA) !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(averageSummary.averageValue?.value ?? NaN) ? (
                    <NoDataCell/>
                ) : (<>
                    <strong>{Math.round(averageSummary.averageValue?.value ?? 0)}</strong>
                    {' '}
                    {getSignalTypeUnit(signalType)}
                    {averageSummary.trend !== undefined && (
                        <HealthReportStatisticsTrend
                            hasData={averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA}
                            trend={averageSummary.trend} />
                    )}
                </>)}
            </PatientsActiveTableCellHoverable>
        );
    }
}

export {AverageCell};
