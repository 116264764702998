import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import ConfirmDeleteForm from '@/components/form/confirm-delete-form.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';

class GroupRemoveUser extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        groupId: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        userDetails: PropTypes.object.isRequired,
        userRole: PropTypes.number.isRequired,
        onRemoved: PropTypes.func,
        onCancelled: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <ConfirmDeleteForm
                    loading={state.loading}
                    itemName={props.userDetails.firstName}
                    text={t(['Are you sure you want to remove', 'removeUser.confirmDeleteForm.text'])}
                    deleteLabel={t(['Remove user', 'removeUser.confirmDeleteForm.delete'])}
                    onCancelled={this.handleCancel}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }

    handleSubmit() {
        this.removeUser();
    }

    handleCancel() {
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    async removeUser() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const user = await BackendApiService.getRequest({
                domain: 'group',
                modelName: (this.props.userRole === GroupUserRole.ADMIN) ? 'removeUserAdminFromGroup' : 'removeUserMemberFromGroup',
                data: {
                    groupId: this.props.groupId,
                    userId: this.props.userId
                }
            });

            this.setState(state => ({
                ...state,
                loading: false,
            }));
            if(this.props.onRemoved) {
                this.props.onRemoved(user);
            }
        }
        catch (err) {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

export default withTranslation()(GroupRemoveUser);
