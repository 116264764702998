import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {formatDateTimeWeekday} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util.js';

const PatientLastRecording = ({lastRecordingDataTime}) => {
    const { t } = useTranslation();

    const today = NewDateUtil();
    const lastRecordingDate = new Date(
        lastRecordingDataTime.date.year, 
        lastRecordingDataTime.date.month-1, 
        lastRecordingDataTime.date.day,
        lastRecordingDataTime.time.hour,
        lastRecordingDataTime.time.minutes,
        lastRecordingDataTime.time.seconds
    );

    const difference = today.getTime() - lastRecordingDate.getTime();

    const days = Math.ceil(difference / (1000 * 3600 * 24));

    if(days <= 7) {
        return (
            <>
                {formatDateTimeWeekday(lastRecordingDate)}
            </>
        );
    } else {
        return (
            <em>
                {t('patient.noRecordingFor', 'No recording for')}&nbsp;{days}&nbsp;{t('global.days', 'days')}
            </em>
        );
    }
};

PatientLastRecording.propTypes = {
    lastRecordingDataTime: PropTypes.object,
};

export default PatientLastRecording;
