import {HcpCommandServiceClient} from 'gabi-api-ts/v2/hcp/command/hcp_command.client';
import {LastSeenNotification} from 'gabi-api-ts/v2/hcp/query/hcp_query.ts';
import {useEffect, useRef, useState} from 'react';
import Styled from 'styled-components';

import {Changelog} from '@/components/widgets/changelog';
import changelogGift from '@/public/icons/changelog-gift.svg';
import {grpcRequest} from '@/services/api-requests/grpc-request-ts';
import {Auth0Service} from '@/services/auth0-service';
import {colorPalette} from '@/themes/darkmode';
import {noop} from '@/util/noop';

type ChangelogGiftProps = {
    className?: string;
    giftNotification: LastSeenNotification;
    onClosed: () => void;
};
function ChangelogGiftUnstyled(props: ChangelogGiftProps) {
    const [changelogGiftOpen, setChangelogGiftOpen] = useState(false);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const listener = (e: MouseEvent) => {
            if (changelogGiftOpen && !buttonRef.current?.contains(e.target as Node)){
                setChangelogGiftOpen(false);
                notifyGiftNotificationSeen().then(noop);
            }
        };
        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, []);

    function handleChangelogClick() {
        if (changelogGiftOpen) {
            notifyGiftNotificationSeen().then(noop);
        }
        setChangelogGiftOpen(!changelogGiftOpen);
    }

    async function notifyGiftNotificationSeen() {
        try {
            const accessToken = await Auth0Service.getAccessToken();
            await grpcRequest(
                accessToken ?? '',
                HcpCommandServiceClient,
                HcpCommandServiceClient.prototype.notifyGiftNotificationSeen,
                {},
            );
            if (props.onClosed) {
                props.onClosed();
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    const from = props.giftNotification.time ? new Date(Number(props.giftNotification.time.seconds) * 1000) : null;

    return (
        <div className={`changelog-gift ${props.className} ${changelogGiftOpen ? 'active' : ''}`} ref={buttonRef}>
            <div className="changelog-wrapper" onClick={handleChangelogClick} />

            <div className={`changelog-content ${changelogGiftOpen ? 'active' : ''}`}>
                <Changelog from={from} showViewFullChangelogLink={true} onViewFullChangelogClick={() => setChangelogGiftOpen(false)} />
            </div>
        </div>
    );
}

//language=SCSS
const ChangelogGift = Styled(ChangelogGiftUnstyled)`
& {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 70px;
    height: 100%;
    border-left: 3px solid ${colorPalette.mainBackground};
    background:url(${changelogGift}) no-repeat center;
    background-size:25px;
    &.active {
        background-color: ${colorPalette.activeColorDarker};
    }
    
    .changelog-wrapper {
        cursor: pointer;
        z-index: 2;
        position: relative;
        padding: 0 10px;
        height: 100%;
    }

    .changelog-content {
        position: fixed;
        background: ${props => props.theme.colorPalette.mainBackground};
        overflow-y: scroll;
        margin: 0;
        list-style: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        transition: opacity 0.15s ease-out, max-height 0.5s ease-out;
        opacity: 0;
        z-index: 1;
        max-height: 0;
        padding:0;

        &.active {
            max-height: 80vh;
            opacity: 1;
            display: block;
            transition: opacity 0.15s ease-out;
        }
    }
}`;

export {ChangelogGift};
