import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';

import {PatientPrescriberForm} from '@/components/business/analytics/patient/common/patient-prescriber-form';
import {InfoMessage} from '@/components/form/info-message';
import LoadingView from '@/components/static/loading-view';
import {BackendApiService} from '@/services/backend-api-service';

class PatientCreationPrescriber extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        me: PropTypes.object.isRequired,
        patientId: PropTypes.string.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        prescribers: null,
    };

    componentDidMount() {
        this.fetchPrescribers();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Patient prescriber', 'createPrescriber.title'])}</h3>

                <InfoMessage infoMessage={t(['Caregiver information were successfully saved!', 'createPrescriber.info'])} text={t(['Please select the patient\'s prescriber.', 'createPrescriber.infoText'])} />

                {!state.loading && state.prescribers &&
                    <PatientPrescriberForm
                        me={props.me}
                        prescriberSelected={props.me.sub?.split('|')[1]}
                        prescribers={state.prescribers}
                        loading={state.loading}
                        onSubmit={(prescriber) => this.createPatientPrescriber(prescriber)}
                    />
                }
                {state.loading &&
                    <LoadingView/>
                }
            </div>
        );
    }

    fetchPrescribers() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'getPrescribers',
        }).then(prescribersIds => {
            // For each prescriber ID, get personal details
            this.fetchPrescribersDetails(prescribersIds);

            return prescribersIds;
        }).catch(error => {
            console.error(error);
        });
    }

    fetchPrescribersDetails(prescribersIds) {
        const prescribers = [];

        prescribersIds.forEach(prescriber => {
            const prescriberId = prescriber.id;
            BackendApiService.getRequest({
                domain: 'hcp',
                modelName: 'getUser',
                data: {
                    id: prescriberId,
                }
            }).then(prescriberDetails => {
                prescribers.push({
                    'id': prescriberId,
                    'details': prescriberDetails
                });

                if(prescribers.length === prescribersIds.length) {
                    prescribers.sort((a, b) => a.details?.lastName.localeCompare(b.details?.lastName));

                    this.setState(state => ({
                        ...state,
                        loading: false,
                        prescribers: prescribers,
                    }));
                }

                return prescriberDetails;
            }).catch(error => {
                console.error(error);
            });
        });
    }

    createPatientPrescriber(prescriber) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'assignPrescriberToPatient',
            data: {
                patientId: this.props.patientId,
                prescriberId: prescriber.id
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            if(this.props.onSaved) {
                this.props.onSaved();
            }
            return response;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

export default withTranslation()(PatientCreationPrescriber);

