import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {GroupUserRole} from '@/components/business/analytics/group/group-user-role.jsx';
import {Button} from '@/components/buttons/button.jsx';
import ConfirmDeleteForm from '@/components/form/confirm-delete-form.jsx';
import {Modal} from '@/components/layout/modal';
import {colorPalette} from '@/themes/darkmode.js';

class HcpUpdateGroupsForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        group: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLeaveGroup: PropTypes.func
    };

    state = {
        showConfirmLeaveGroupModal: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                {state.showConfirmLeaveGroupModal &&
                    <Modal title={t(['Leave group', 'hcpUpdateGroups.leaveGroupModalTitle'])} onClosed={this.handleCloseModal}>
                        <ConfirmDeleteForm
                            loading={props.loading}
                            itemName={props.group.name}
                            text={t(['Are you sure you want to leave', 'hcpUpdateGroups.leaveGroupModalText'])}
                            deleteLabel={t(['Leave group', 'hcpUpdateGroups.leaveGroupModalDeleteLabel'])}
                            onCancelled={this.handleCloseModal}
                            onSubmit={this.handleSubmit}
                        />
                    </Modal>
                }
                {!state.showConfirmLeaveGroupModal &&
                    <div key={props.group.groupId.id} className="group-block">
                        <span>{props.group.name}</span>
                        <span className="requester-role">{(props.group.requesterRole === GroupUserRole.ADMIN) ? 'Admin' : 'Member'}</span>
                        <Button className="button-delete" displayIcon={true} onClick={this.handleClickLeaveGroup}>{t(['Leave group', 'hcpUpdateGroups.confirm'])}</Button>
                    </div>
                }
            </div>
        );
    }

    handleClickLeaveGroup() {
        this.setState(state => ({
            ...state,
            showConfirmLeaveGroupModal: true,
        }));
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showConfirmLeaveGroupModal: false,
        }));
    }

    handleSubmit() {
        this.handleCloseModal();
        if(this.props.onLeaveGroup) {
            this.props.onLeaveGroup(this.props.group);
        }
    }
}

//language=SCSS
HcpUpdateGroupsForm = Styled(HcpUpdateGroupsForm)`
& {
    .group-block {
        background: ${colorPalette.secondBackground};
        border-radius:5px;
        padding:15px;
        margin-right:20px;
        margin-bottom:20px;
        width:200px;
        
        span {
            display:block;
            &.requester-role {
                color:${colorPalette.clearColor};
                font-size:13px;
            }
        }
        
        button {
            margin-top:15px;
        }
    }
}
`;

export default withTranslation()(HcpUpdateGroupsForm);


