import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientsProgramSelection from '@/components/business/analytics/patients/patients-program-selection.jsx';
import babyFox from '@/public/images/demo_baby_hello.svg';
import {colorPalette} from '@/themes/darkmode.js';

const PatientsWelcomeSection = ({program, onChanged}) => {
    const { t } = useTranslation();

    const handleProgramChanged = (selectedProgram) => {
        onChanged(selectedProgram);
    };

    return (
        <StyledWrapper>
            <div>
                <img src={babyFox} width={100} alt="Baby fox" />
            </div>
            <div className="welcomeText">
                <h2>{t(['Good Morning!', 'pages.patients.welcomeTitle'])}</h2>
                <h3>{t(['Have a nice day at work, saving children\'s lives!', 'pages.patients.welcomeText'])}</h3>
            </div>
            <div className="welcomeActions">
                <PatientsProgramSelection program={program} onChanged={handleProgramChanged} />
            </div>
        </StyledWrapper>
    );
};

PatientsWelcomeSection.propTypes = {
    t: PropTypes.func,
    program: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      width: 100%;
      background: ${colorPalette.secondBackground};
      border-radius: 10px;
      padding: 30px;
      display:flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom:30px;
      
      .welcomeText {
          margin-left:20px;
          h2 {
              font-size:16px;
          }
          h3 {
              font-size:14px;
          }
      }
      
      .welcomeActions {
          margin-left: auto;
          display:flex;
          margin-right: 10px;
      }
  }
`;

export default PatientsWelcomeSection;
