import {ReactElement, PropsWithChildren} from 'react';
import {ResponsiveContainer} from 'recharts';
import styled from 'styled-components';

import InfoTooltip from '@/components/buttons/info-tooltip';

type ChartContainerProps = PropsWithChildren<{
    className?: string;
    title: string | ReactElement;
    subtitle?: string | ReactElement;
    topRightContent?: string | ReactElement;
    infoTooltipTitle?: string | ReactElement;
    infoTooltipText?: string | ReactElement;
    infoTooltipOverlayPosition?: 'bottomRight' | 'bottomLeft';
}>;

function ChartContainer({className, title, subtitle, topRightContent, infoTooltipTitle, infoTooltipText, infoTooltipOverlayPosition, children}: ChartContainerProps) {
    return (
        <StyledWrapper className={`chart-container ${!topRightContent ? '' : 'chart-container__has-top-right-content'} ${className ?? ''}`}>
            <div className="chart-container__header">
                <div className="chart-container__top-left">
                    <h2>{title}</h2>
                    {subtitle && (
                        <h3>{subtitle}</h3>
                    )}
                </div>

                {topRightContent && (
                    <div className="chart-container__top-right">
                        {topRightContent}
                    </div>
                )}
            </div>
            <div className="chart-container__content">
                {children && (
                    <ResponsiveContainer>
                        {children as ReactElement}
                    </ResponsiveContainer>
                )}
            </div>

            {infoTooltipTitle && (
                <InfoTooltip title={infoTooltipTitle} overlayPosition={infoTooltipOverlayPosition}>
                    {infoTooltipText}
                </InfoTooltip>
            )}
        </StyledWrapper>
    );
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .chart-container__header {
        min-height: 50px;
        position: relative;
        flex-grow: 0;
        z-index: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .chart-container__top-left {
        display: inline-block;
        flex-shrink: 0;
        
        &.chart-container__has-top-right-content {
            @media screen and (min-width: 1800px) {
              max-width: 36%;
            } 
        }

        > h2 {
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 0;
            white-space: nowrap;
        }
        > h3 {
            font-size: 12px;
            margin-bottom: 0;
            color: ${props => props.theme.colorPalette.clearColor};
        }
    }
    
    .chart-container__top-right {
        position: relative;
        font-size: 13px;
        flex-grow: 1;

        p {
            margin-bottom: 5px;
            text-align: right;
        }
    }
    
    .chart-container__content {
        position: relative;
        height: 100px;
        flex-grow: 1;
        z-index: 1;
        padding-right: 32px;
    }
    
    .loading-view {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .lds-ring {
            margin: 0;
        }
    }
    
    .info-tooltip {
        z-index: 2; 
        right: 0;
        bottom: 5px;
    }
    
    .recharts-responsive-container {
        height: 100%;
    }
}
`;

export {ChartContainer};
