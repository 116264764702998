import PropTypes from 'prop-types';

function GuidedTourStep(props) {
    return (
        <div className={props.className}>
            <div>{props.children}</div>
        </div>
    );
}

GuidedTourStep.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};

export { GuidedTourStep };
