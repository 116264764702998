import React from 'react';

class EmptyTooltip extends React.PureComponent {
    render() {
        return (
            <div className="custom-tooltip"></div>
        );
    }
}

export { EmptyTooltip };

