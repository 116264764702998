import {ProgramType} from 'gabi-api-ts/v2/hcp/common/hcp_common';

type HcpProgramConfig = {
    pulseRate: {
        average: boolean;
        eventsBelow: boolean;
        eventsAbove: boolean;
    },
    spo2: {
        average: boolean;
        eventsBelow: boolean;
    },
    movements: boolean;
    sleeping: boolean;
}

function getHcpProgramConfig(program: ProgramType): HcpProgramConfig {
    const config = {
        pulseRate: {
            average: true,
            eventsBelow: true,
            eventsAbove: true,
        },
        spo2: {
            average: true,
            eventsBelow: true,
        },
        movements: true,
        sleeping: true,
    };

    if (program === ProgramType.CARDIAC) {
        config.spo2.eventsBelow = false;
    }

    if (program === ProgramType.PNEUMO) {
        config.pulseRate.eventsBelow = false;
        config.pulseRate.eventsAbove = false;
    }

    return config;
}

export {getHcpProgramConfig};
export type {HcpProgramConfig};
