import {withAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import GroupCreate from '@/components/business/analytics/group/group-create';
import GroupDetails from '@/components/business/analytics/group/group-details';
import {GroupUserRole} from '@/components/business/analytics/group/group-user-role';
import {GroupsList} from '@/components/business/analytics/group/groups-list';
import {Button} from '@/components/buttons/button';
import ErrorBoundary from '@/components/errors/error-boundary';
import {InfoMessage} from '@/components/form/info-message';
import {Modal} from '@/components/layout/modal';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';

function wrapWithPage(Component) {
    return Page({
        name: 'Groups management'
    })(Component);
}

class GroupsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
        auth0: PropTypes.object,
    };

    state = {
        showGroupCreateModal: false,
        loading: false,
        selectedGroupLoading: false,
        groups: [],
        selectedGroupId: null,
        selectedGroup: null,
        isMeGroupAdmin: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    componentDidMount() {
        this.fetchGroups();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    {state.showGroupCreateModal &&
                        <Modal title={t(['New group', 'pages.groups.newGroup'])} onClosed={this.handleCloseGroupCreateModal}>
                            <GroupCreate onCreated={this.handleGroupCreated} onCancelled={this.handleCloseGroupCreateModal} />
                        </Modal>
                    }
                    {!state.loading &&
                        <div className="groups-wrapper">
                            <div className="groups-groupsList">
                                <div>
                                    <Button className="button-create" displayIcon={true} onClick={this.handleOpenGroupCreateModal}>{t(['Add group', 'pages.groups.addGroup'])}</Button>
                                </div>
                                <GroupsList groups={state.groups} selectedGroupId={this.state.selectedGroupId} onSelect={this.handleSelectGroup}/>
                            </div>
                            <div className="groups-groupDetails">
                                {!state.selectedGroupLoading && state.groups.length > 0 && this.state.selectedGroup &&
                                    <GroupDetails
                                        key={this.state.selectedGroupId}
                                        group={this.state.selectedGroup}
                                        isMeGroupAdmin={this.state.isMeGroupAdmin}
                                        onGroupChanged={this.handleGroupChanged}
                                        onUsersChanged={this.handleUsersChanged}
                                        onGroupDeleted={this.handleGroupDeleted}
                                    />
                                }
                                {!state.selectedGroupLoading && state.groups.length === 0 &&
                                    <InfoMessage className="info-message" infoMessage={t(['You can now add your first group.', 'pages.groups.info'])} />
                                }
                                {state.selectedGroupLoading &&
                                    <LoadingView />
                                }
                            </div>
                        </div>
                    }
                    {state.loading &&
                        <LoadingView />
                    }
                </ErrorBoundary>
            </div>
        );
    }

    handleOpenGroupCreateModal() {
        this.setState(state => ({
            ...state,
            showGroupCreateModal: true,
        }));
    }

    handleCloseGroupCreateModal() {
        this.setState(state => ({
            ...state,
            showGroupCreateModal: false,
        }));
    }

    handleGroupCreated(group) {
        this.handleCloseGroupCreateModal();
        this.fetchGroups(group.id);
    }

    handleSelectGroup(selectedGroupId) {
        this.fetchSelectedGroup(selectedGroupId);
    }

    handleGroupChanged(group) {
        this.fetchGroups(group.groupId.id);
    }

    handleGroupDeleted() {
        this.fetchGroups();
    }

    handleUsersChanged(group) {
        this.fetchSelectedGroup(group.groupId.id);
    }

    fetchGroups(selectedGroup) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'groups',
        }).then(groups => {
            const selectedGroupId = (selectedGroup) ? selectedGroup : groups.groupList[0]?.groupId.id;

            this.setState(state => ({
                ...state,
                loading: false,
                groups: groups.groupList,
                selectedGroupId: selectedGroupId,
                selectedGroup: null,
            }));

            if(selectedGroupId) {
                this.fetchSelectedGroup(selectedGroupId);
            }

            return groups;
        }).catch (err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    fetchSelectedGroup(selectedGroupId) {
        this.setState(state => ({
            ...state,
            selectedGroupLoading: true,
        }));

        BackendApiService.getRequest({
            domain: 'group',
            modelName: 'getGroup',
            data: {
                id: selectedGroupId
            }
        }).then(group => {
            let isMeGroupAdmin = false;
            group.usersList.forEach(user => {
                // eslint-disable-next-line react/prop-types
                if ((user.id.id === this.props.auth0.user.sub.split('|')[1]) && (user.role === GroupUserRole.ADMIN)) {
                    isMeGroupAdmin = true;
                }
            });

            this.setState(state => ({
                ...state,
                selectedGroupLoading: false,
                selectedGroup: group,
                selectedGroupId: selectedGroupId,
                isMeGroupAdmin: isMeGroupAdmin,
            }));

            return group;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                selectedGroupLoading: false,
            }));
        });
    }
}

//language=SCSS
GroupsPage = Styled(GroupsPage)`
& {
    min-height: 500px;
    width: 95%;
    height: calc(100% - 80px);
    margin: 120px auto 0 auto;
    
    .groups-wrapper {
        display:flex;
    }
    
    .groups-groupsList {
        border-right:1px solid ${colorPalette.mainBackground};
        min-height:500px;
        flex-grow:1;
        width:300px;
    }
    
    .groups-groupDetails {
        flex-grow: 5;
        width:calc(100% - 300px);
        .info-message {
            margin-left:30px;
            margin-top:0;
        }
    }
}
`;

GroupsPage = wrapWithPage(withTranslation()(withAuth0(GroupsPage)));
export {GroupsPage};
