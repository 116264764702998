import PropTypes from 'prop-types';
import styled from 'styled-components';

import {EventConfigurationButton} from '@/components/business/analytics/common/event-configuration-button';
import {PatientSummary} from '@/components/business/analytics/patient/common/patient-summary';
import {PatientHealthCondition} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition';
import ErrorBoundary from '@/components/errors/error-boundary';
import LoadingView from '@/components/static/loading-view';
import {colorPalette, zIndices} from '@/themes/darkmode';

const HealthReportSidebar = ({patient}) => {
    if (!patient) {
        // Return an empty sidebar while we wait for data
        return (
            <StyledWrapper>
                <div className="health-report-sidebar">
                    <LoadingView/>
                </div>
            </StyledWrapper>
        );
    }
    
    const isStudyPatient = patient.trialId?.id;
    
    if (patient) {
        return (
            <StyledWrapper>
                <div className="health-report-sidebar">
                    <ErrorBoundary>
                        <div className="patient-summary-wrapper">
                            <PatientSummary
                                patient={patient}
                                currentPage={{page: 'healthReport'}}
                            />
                        </div>
                        {!isStudyPatient &&
                            <div className="patient-health-condition-wrapper">
                                <PatientHealthCondition
                                    patient={patient}
                                />
                            </div>
                        }
                        <div className="health-report-event-configuration">
                            <EventConfigurationButton
                                patientId={patient.patientId.id}
                            />
                        </div>
                    </ErrorBoundary>
                </div>
            </StyledWrapper>
        );
    }
};

HealthReportSidebar.propTypes = {
    patient: PropTypes.object,
    eventConfiguration: PropTypes.object,
    onEventConfigurationChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    .health-report-sidebar {
        background: ${colorPalette.secondBackground};
        position: fixed;
        height: 100%;
        width: 250px;
        border-right: 1px solid ${colorPalette.darkColor};
        z-index: ${zIndices.sidebar};
        
        div.patient-summary-wrapper,
        div.patient-health-condition-wrapper {
            position: relative;
        }
        
        > div {
            border-bottom: 1px solid ${colorPalette.darkColor};
            padding: 20px;
            //text-align: center;
        }
        
        .health-report-event-configuration {
            .button-event-configuration {
                border-radius: 5px;
                right: auto;
                top: auto;
                position: relative;
                margin: 0 auto;
                display: block;
            }
        }
    }
}
`;

export {HealthReportSidebar};
