export function arrayToMap<T>(array: T, keyfn: (t: T) => string, valuefn: (a: T) => T = (a: T) => a): {[key:string]: T} {
    if (!Array.isArray(array)) {
        throw new TypeError(`expected array, got ${typeof(array)}`);
    }

    const out: {[key:string]: T} = {};

    for (let i=0; i<array.length; ++i) {
        out[keyfn(array[i])] = valuefn(array[i]);
    }

    return out;
}

export function getMax<T>(array: T[], callback: (item: T) => number): T {
    if (array.length === 0) {
        throw new Error('Array is empty');
    }

    let maxItem: T = array[0];
    let maxValue: number = callback(maxItem);

    for (let i = 1; i < array.length; i++) {
        const value = callback(array[i]);
        if (value > maxValue) {
            maxValue = value;
            maxItem = array[i];
        }
    }

    return maxItem;
}

export function getMin<T>(array: T[], callback: (item: T) => number): T | null {
    if (array.length === 0) {
        return null;
    }

    let minItem: T = array[0];
    let minValue: number = callback(minItem);

    for (let i = 1; i < array.length; i++) {
        const value = callback(array[i]);
        if (value < minValue) {
            minValue = value;
            minItem = array[i];
        }
    }

    return minItem;
}
