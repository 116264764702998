import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {formatFullTime} from 'gabi-web-common/util/date-util';
import {useMemo} from 'react';
import {CartesianGrid, Line, LineChart, ReferenceArea, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {NameType} from 'recharts/types/component/DefaultTooltipContent';
import {ContentType} from 'recharts/types/component/Tooltip';
import {AxisInterval} from 'recharts/types/util/types';

import {ChartAxisTickColored, ChartAxisTickColoredProps} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {TimelineTick, TimelineTickProps} from '@/components/business/analytics/timeline/timeline-tick';
import {Modal} from '@/components/layout/modal';
import {colorPalette} from '@/themes/darkmode';
import {getSignalTypeIdentifier} from '@/util/signal-type-util';

type Props =  { [key:string]: unknown };

type TimelineSubchartZoomProps = {
    selectedTimestamp: number;
    subchartType: SignalType;
    data: Array<{
        secondsSince12pm: number;
        value: number;
    }>;
    zoomInfo: {
        zoomAreaStartTimestamp: number;
        zoomAreaEndTimestamp: number;
        // zoomAreaStartCoordinate: this.state.zoomAreaStartCoordinate;
        // zoomAreaEndCoordinate: this.state.zoomAreaEndCoordinate;
    };
    medicalEvents: Array<{
        fromSecondsSince12pm: number;
        toSecondsSince12pm: number;
    }>;
    eventConfiguration: SignalEventConfiguration;
    tooltip_: (p: Props) => Tooltip<number, NameType>;
    label: string;
    unit: string;
    padding: number;

    onClosed?: () => void;
}

function TimelineSubchartZoom({selectedTimestamp, subchartType, data, zoomInfo, medicalEvents, eventConfiguration, tooltip_, label, unit, padding, onClosed}: TimelineSubchartZoomProps) {
    const tooltip = (props: { payload: Array<{ payload: object }> }) => {
        return tooltip_({
            ...props,
            baseDate: selectedTimestamp,
            payload: props.payload[props.payload.length-1]?.payload ?? {},
        });
    };

    const zoomedChartDomainY = [];
    const zoomedChartDomainX = [];
    const startTimestamp = zoomInfo.zoomAreaStartTimestamp; //new Date(state.zoomData?.zoomAreaStartTimestamp).getUTCMilliseconds();

    // Get chart data for selected period
    const zoomedChartData = useMemo(() => data.filter(item => (
        item.secondsSince12pm >= zoomInfo.zoomAreaStartTimestamp &&
        item.secondsSince12pm <= zoomInfo.zoomAreaEndTimestamp
    )), [data, zoomInfo]);
    if (zoomedChartData.length <= 0) {
        return null;
    }

    // Get medical alerts for selected period
    const zoomedChartMedicalEvents = medicalEvents.filter(block => {
        return (
            (
                block.fromSecondsSince12pm >= zoomInfo.zoomAreaStartTimestamp &&
                block.fromSecondsSince12pm <= zoomInfo.zoomAreaEndTimestamp
            ) ||
            (
                block.toSecondsSince12pm >= zoomInfo.zoomAreaStartTimestamp &&
                block.toSecondsSince12pm <= zoomInfo.zoomAreaEndTimestamp
            ) ||
            (
                block.fromSecondsSince12pm <= zoomInfo.zoomAreaStartTimestamp &&
                block.toSecondsSince12pm >= zoomInfo.zoomAreaStartTimestamp &&
                block.fromSecondsSince12pm <= zoomInfo.zoomAreaEndTimestamp &&
                block.toSecondsSince12pm >= zoomInfo.zoomAreaEndTimestamp
            )
        );
    });
    if (zoomedChartMedicalEvents.length > 0) {
        if (zoomedChartMedicalEvents[0].fromSecondsSince12pm < zoomInfo.zoomAreaStartTimestamp) {
            zoomedChartMedicalEvents[0] = {
                ...zoomedChartMedicalEvents[0],
                fromSecondsSince12pm: zoomInfo.zoomAreaStartTimestamp,
            };
        }
        if (zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1].toSecondsSince12pm > zoomInfo.zoomAreaEndTimestamp) {
            zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1] = {
                ...zoomedChartMedicalEvents[zoomedChartMedicalEvents.length-1],
                toSecondsSince12pm: zoomInfo.zoomAreaEndTimestamp,
            };
        }
    }

    // Get chart domain for selected period + padding
    let zoomedChartDomainMin = zoomedChartData[0].value;
    let zoomedChartDomainMax = zoomedChartData[0].value;
    zoomedChartData.forEach(item => {
        if (item.value < zoomedChartDomainMin) {
            zoomedChartDomainMin = item.value;
        }
        if (item.value > zoomedChartDomainMax) {
            zoomedChartDomainMax = item.value;
        }
    });

    // Add padding if relevant
    zoomedChartDomainMin = (subchartType === SignalType.Signal_Actigraphy && ((zoomedChartDomainMin - padding) < 0)) ? 0 : zoomedChartDomainMin - padding;
    zoomedChartDomainMax = (subchartType === SignalType.Signal_SPO2 && ((zoomedChartDomainMax + padding) > 100)) ? 100 : zoomedChartDomainMax + padding;
    zoomedChartDomainY.push(zoomedChartDomainMin, zoomedChartDomainMax);
    zoomedChartDomainX.push(zoomedChartData[0].secondsSince12pm, zoomedChartData[zoomedChartData.length - 1].secondsSince12pm);

    const startDateTime = new Date(selectedTimestamp + zoomedChartData[0].secondsSince12pm * 1000);
    const endDateTime = new Date(selectedTimestamp + zoomedChartData[zoomedChartData.length - 1].secondsSince12pm * 1000);

    return (
        <Modal className="modal-zoom-chart" title={<>
            <h2 style={{color: colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}}>{label}</h2>
            <h3>
                {formatFullTime(startDateTime)}
                {' - '}
                {formatFullTime(endDateTime)}
            </h3>
        </>} onClosed={onClosed}>
            <LineChart data={zoomedChartData} width={600} height={300}>
                <CartesianGrid strokeDasharray="1 3" />

                <XAxis
                    dataKey="secondsSince12pm"
                    type="number"
                    name="t"
                    domain={zoomedChartDomainX}
                    tick={(props: TimelineTickProps) => <TimelineTick {...props} baseDate={new Date(selectedTimestamp)} displaySeconds />}
                    interval={'equidistantPreserveStartEnd' as AxisInterval}
                />
                <YAxis
                    domain={zoomedChartDomainY}
                    tick={(props: ChartAxisTickColoredProps) => <ChartAxisTickColored {...props} decimals={2} unit={unit} fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(subchartType)]} />}
                    type="number"
                    tickCount={4}
                    tickFormatter={formatYAxis as (value: unknown, index: number) => string}
                    interval={'equidistantPreserveStartEnd' as AxisInterval}
                />

                {zoomedChartMedicalEvents.map(block => {
                    if (block.fromSecondsSince12pm === block.toSecondsSince12pm) {
                        return (
                            <ReferenceLine
                                key={`eventBlock-${subchartType}-${block.fromSecondsSince12pm}`}
                                x={block.fromSecondsSince12pm + startTimestamp}
                                stroke={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                                strokeWidth={0.5}
                            />
                        );
                    }
                    else {
                        return (
                            <ReferenceArea
                                key={block.fromSecondsSince12pm}
                                x1={block.fromSecondsSince12pm}
                                x2={block.toSecondsSince12pm}
                                fill={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                                strokeOpacity={0.0}
                                fillOpacity={0.25}
                            />
                        );
                    }
                })}

                <Line
                    isAnimationActive={false}
                    type="linear"
                    dot={false}
                    activeDot={false}
                    dataKey="value"
                    stroke={colorPalette.clearColor}
                    strokeWidth={1.2}
                    connectNulls={false}
                />
                <Line
                    isAnimationActive={false}
                    type="linear"
                    dot={false}
                    activeDot={false}
                    dataKey="reliableValue.value"
                    stroke={colorPalette.signalType[getSignalTypeIdentifier(subchartType)]}
                    strokeWidth={1.5}
                    opacity={1}
                    connectNulls={false}
                />

                {eventConfiguration && subchartType !== SignalType.Signal_SPO2 && (
                    <ReferenceLine
                        y={eventConfiguration.high?.value}
                        label={{
                            position: ((eventConfiguration.high?.value ?? 0) >= (zoomedChartDomainY[1] - (5))) ? 'bottom' : 'top',
                            value: `${eventConfiguration.high?.value}${unit}`,
                            fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                        }}
                        stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                        strokeDasharray="4"
                    />
                )}
                {eventConfiguration && (
                    <ReferenceLine
                        y={eventConfiguration.low?.value}
                        label={{
                            position: ((eventConfiguration.low?.value ?? 0) <= (zoomedChartDomainY[0] + (5))) ? 'top' : 'bottom',
                            value: `${eventConfiguration.low?.value}${unit}`,
                            fill: colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]
                        }}
                        stroke={colorPalette.signalTypeClear[getSignalTypeIdentifier(subchartType)]}
                        strokeDasharray="4"
                    />
                )}

                {tooltip && (
                    <Tooltip content={tooltip as unknown as ContentType<number, NameType>} isAnimationActive={false} />
                )}
            </LineChart>
        </Modal>
    );
}

function formatYAxis(tickItem: number) {
    if (tickItem === Math.floor(tickItem)) {
        return tickItem;
    }
    else {
        return tickItem.toFixed(2);
    }
}

export {TimelineSubchartZoom};
