import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {ErrorMessage} from '@/components/form/error-message';
import {AppLogoFull} from '@/components/static/app-logo-full';
import doctors from '@/public/images/doctors.svg';
import {colorPalette} from '@/themes/darkmode';

const LoginUnauthorizedViewProps = {
    errorMessage: PropTypes.string.isRequired,
    t: PropTypes.func,
    className: PropTypes.string,
};

function LoginUnauthorizedViewUnstyled({className, errorMessage, t}) {
    return (
        <div className={`login-unauthorized-view ${className}`}>
            <AppLogoFull height={80} className="login-logo" />
            <div className="login-content">
                <h1>Gabi Analytics</h1>
                <img src={doctors} width={250} alt={t('pages.unauthorizedView.alt')}/>
                <ErrorMessage errorMessage={errorMessage} text="" />
            </div>
        </div>
    );
}
LoginUnauthorizedViewUnstyled.propTypes = LoginUnauthorizedViewProps;

//language=SCSS
const LoginUnauthorizedView = Styled(withTranslation()(LoginUnauthorizedViewUnstyled))`
& {
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
    
    .login-logo {
        margin-bottom: 20px;
    }
    
    .login-content {
        background: ${colorPalette.thirdBackground};
        border-radius: 10px;
        padding: 35px 70px 55px 70px;
        width: 400px;
        margin: 0 auto;

        h1 {
            font-size: 18px;
            margin-bottom: 15px;
            text-align: center;
        }

        img {
            margin-bottom: 20px;
        }
        
        .button-active {
            margin-top: 15px;
        }
    }
}`;

export {LoginUnauthorizedView};
