import {JSX} from 'react';

import {useBackendQueryResult} from '@/hooks/use-backend-query';

type Props = { [key: string]: unknown };

export function withBackendQuery<Response extends object>
(loadingName: string, resourceName: string, errorName: string, hookFn: (props: Props, dependencies?: unknown[]) => useBackendQueryResult<Response>, dependencies?: unknown[])
: (component: JSX.ElementType) => JSX.ElementType {

    return (Component: JSX.ElementType) => {
        function ComponentWithQueryProp(props: Props) {
            const [loading, resource, error] = hookFn(props, dependencies);

            const extraProps: Props = {};
            extraProps[loadingName] = loading;
            extraProps[resourceName] = resource;
            if (errorName) {
                extraProps[errorName] = error;
            }

            return (
                <Component {...props} {...extraProps} />
            );
        }

        return ComponentWithQueryProp;
    };
}
