import { Date as ApiDate } from 'gabi-api-js/signal/query/signal_query_pb';

export function formatDateForUrl(date: Date): string {
    const dateYear = date.getFullYear();
    const month = date.getMonth()+1;
    const monthStr = `${month < 10 ? '0' : ''}${month}`;
    const day = date.getDate();
    const dayStr = `${day < 10 ? '0' : ''}${day}`;
    return `${dateYear}-${monthStr}-${dayStr}`;
}

export function formatApiDateForUrl(apiDate: ApiDate.AsObject): string {
    const month = `${apiDate.month < 10 ? '0' : ''}${apiDate.month}`;
    const day = `${apiDate.day < 10 ? '0' : ''}${apiDate.day}`;
    return `${apiDate.year}-${month}-${day}`;
}
