import { Patient } from 'gabi-api-ts/v2/patient/query/patient_query';
import {useTranslation} from 'react-i18next';

import PatientsTableRow from '@/components/business/analytics/patients/patients-table-row';
import {InfoMessage} from '@/components/form/info-message';
import {TableSortable} from '@/components/table/table-sortable';
import {TableSortableColumn} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';

type PatientsInactiveTableProps = {
    patients: Patient[];
}

function PatientsInactiveTable({patients}: PatientsInactiveTableProps) {
    const { t } = useTranslation();

    const tableSortableHeaderColumn: TableSortableColumn<Patient>[] = [
        {
            id: 'id',
            label: '',
            sortable: false,
        },
        {
            id: 'name',
            label: t('patient.title'),
            compareFn: (a, b) => {
                const aStr = `${a.lastName ?? ''}, ${a.firstName ?? ''}`;
                const bStr = `${b.lastName ?? ''}, ${b.firstName ?? ''}`;
                return aStr.localeCompare(bStr);
            },
            sortable: true,
        },
        {
            id: 'lastRecording',
            label: t('patient.lastRecording'),
            compareFn: (a, b) => {
                const aStr = a.lastRecordingDataTime ? formatApiFullDateToJSDate(a.lastRecordingDataTime).toISOString() : '2000-01-01';
                const bStr = b.lastRecordingDataTime ? formatApiFullDateToJSDate(b.lastRecordingDataTime).toISOString() : '2000-01-01';
                return aStr.localeCompare(bStr);
            },
            sortable: true,
        },
        {
            id: 'caregiver',
            label: t('caregiver.title'),
            sortable: false,
        },
        {
            id: 'edit',
            label: '',
            sortable: false,
        },
        {
            id: 'delete',
            label: '',
            sortable: false,
        },
    ];

    if (patients) {
        if (patients.length > 0) {
            return (
                <TableSortable
                    data={patients}
                    columns={tableSortableHeaderColumn}
                    defaultSorting={{id: 'name', sortByOrder: 'asc'}}
                    TableBodyRowComponent={PatientsTableRow}
                />
            );
        }
        else {
            return <InfoMessage infoMessage="No inactive patients." />;
        }
    }
    else {
        return <ComponentErrorMessage component="PatientsInactiveTable" />;
    }
}

export {PatientsInactiveTable};
