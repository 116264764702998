import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum.jsx';
import baby_sitting_skinType_1 from '@/public/images/baby_sitting_skin_1.svg';
import baby_sitting_skinType_2 from '@/public/images/baby_sitting_skin_2.svg';
import baby_sitting_skinType_3 from '@/public/images/baby_sitting_skin_3.svg';
import baby_sitting_skinType_4 from '@/public/images/baby_sitting_skin_4.svg';
import baby_sitting_skinType_5 from '@/public/images/baby_sitting_skin_5.svg';
import baby_sitting_skinType_6 from '@/public/images/baby_sitting_skin_6.svg';

const HealthReportNoRecording = ({patient, activeDashboard}) => {
    const { t } = useTranslation();

    const babySittingSkinType = {
        1: baby_sitting_skinType_1,
        2: baby_sitting_skinType_2,
        3: baby_sitting_skinType_3,
        4: baby_sitting_skinType_4,
        5: baby_sitting_skinType_5,
        6: baby_sitting_skinType_6,
    };

    return (
        <StyledWrapper>
            {activeDashboard === HealthReportDashboardTypeEnum.LAST_24H &&
                <div className="no-recording-message-block">
                    <div className="info-message">{t(['No recording for the last 30 nights.', 'healthReport.noRecording.30nights'])}</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150"/>
                </div>
            }
            {activeDashboard === HealthReportDashboardTypeEnum.LAST_7DAYS &&
                <div className="no-recording-message-block">
                    <div className="info-message">{t(['No recording for the last 7 nights.', 'healthReport.noRecording.7nights'])}</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150"/>
                </div>
            }
            {activeDashboard === HealthReportDashboardTypeEnum.LAST_30DAYS &&
                <div className="no-recording-message-block">
                    <div className="info-message">{t(['No recording for the last 30 nights.', 'healthReport.noRecording.30nights'])}</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150"/>
                </div>
            }
            {activeDashboard === HealthReportDashboardTypeEnum.TRENDS &&
                <div className="no-recording-message-block">
                    <div className="info-message">{t(['No recording for the last 90 nights.', 'healthReport.noRecording.90nights'])}</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150"/>
                </div>
            }
        </StyledWrapper>
    );
};

HealthReportNoRecording.propTypes = {
    patient: PropTypes.object.isRequired,
    activeDashboard: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .no-recording-message-block {
          width:400px;
          margin:0 auto;
          margin-top:50px;
          img {
              display:block;
              margin:0 auto;
              margin-top:20px;
          }
      }
  }
`;

export default HealthReportNoRecording;
