import {ValueWithHasDataFlag, HasData} from 'gabi-api-ts/v2/signal/query/signal_query';
import {JSX} from 'react';
import {useTranslation} from 'react-i18next';

type AverageValueProps = {
    averageObj: null | ValueWithHasDataFlag;
    unit: null | string | JSX.Element;
    precision?: number;
};

function AverageValue({averageObj, unit, precision}: AverageValueProps) {
    const { t } = useTranslation();
    if (averageObj && averageObj.hasData == HasData.HAS_MIN_RELIABLE_DATA) {
        return (
            <span>
                ~{averageObj.value.toFixed(precision ?? 0)}{unit}
            </span>
        );
    }
    else {
        return (
            <span>
                {t(['no data', 'healthReport.aggregatedSignal.noData'])}
            </span>
        );
    }
}

export { AverageValue };
