import {PatientCommandServiceClient} from 'gabi-api-ts/v2/patient/command/patient_command.client';
import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import ConfirmDeleteForm from '@/components/form/confirm-delete-form';
import {grpcRequest} from '@/services/api-requests/grpc-request-ts';
import {Auth0Service} from '@/services/auth0-service';

type PatientDeleteProps = {
    className?: string;
    patient: Patient;
    onDeleted: (id: string) => void;
    onCancelled?: () => void;
};

function PatientDelete({className, patient, onDeleted, onCancelled}: PatientDeleteProps) {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    function handleCancel() {
        if (onCancelled) {
            onCancelled();
        }
    }

    async function deletePatient() {
        try {
            setLoading(true);

            const accessToken = await Auth0Service.getAccessToken();
            await grpcRequest(
                accessToken ?? '',
                PatientCommandServiceClient,
                PatientCommandServiceClient.prototype.deletePatient,
                {
                    id: patient.patientId!.id
                }
            );

            if (onDeleted) {
                onDeleted(patient.patientId!.id);
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <div className={className ?? ''}>
            <ConfirmDeleteForm
                loading={loading}
                itemName={`${patient.firstName} ${patient.lastName}`}
                deleteLabel={t('pages.patientDelete.confirm')}
                text={t('pages.patientDelete.text')}
                onCancelled={handleCancel}
                onSubmit={deletePatient}
            />
        </div>
    );
}

export {PatientDelete};
