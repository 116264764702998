import {Auth0Provider, CacheLocation} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';

import NoInternetView from '@/components/static/no-internet-view';
import config from '@/config';
import RootAnalytics from '@/root-analytics';

// Auth0 wrapper, including active route
export default function MainAnalyticsAuth0Wrapper() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ _, forceUpdate ] = useState<number>(0);
    useEffect(() => {
        const update = () => {
            forceUpdate(Date.now);
        };
        window.addEventListener('offline', update);
        window.addEventListener('online', update);

        return () => {
            window.removeEventListener('offline', update);
            window.removeEventListener('online', update);
        };
    }, []);

    if (navigator.onLine) {
        return (
            <Auth0Provider
                domain={config.auth0.domain}
                clientId={config.auth0.clientId}
                redirectUri={window.location.origin}
                audience={`https://${config.auth0.audience}/api/v2/`}
                scope="read:current_user update:current_user_metadata"
                useRefreshTokens={true}
                cacheLocation={config.auth0.cacheLocation as CacheLocation}
            >
                <RootAnalytics />
            </Auth0Provider>
        );
    }
    else {
        return (
            <NoInternetView />
        );
    }
}
