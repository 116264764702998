import {differenceInDays} from 'date-fns';
import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {AlertType, HasData} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickDate} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-date';
import {getDomainForEvents} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartDualLabel, ChartDualLabelProps} from '@/components/charts/chart-dual-label';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {formatDateUniversal, formatSecondsToHms} from '@/util/date-utils';
import {getSignalTypeIdentifier, getSignalTypeUnit} from '@/util/signal-type-util';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type HealthReportEventsPerDayProps = {
    patientId: string;
    signalType: SignalType;
    alertTypes: AlertType[];
    dateFrom: Date;
    dateTo: Date;
    subtitle?: string;
    signalEventConfiguration: SignalEventConfiguration;
    onClick?: (date: Date) => void;
}

type EventFormatted = {
    date: string;
    events: number | null;
    above?: number | null;
    below?: number | null;
    hasEnoughData: boolean;
    reliableDurationInSeconds: number;
    duration: number | null;
};

function HealthReportEventsPerDay({patientId, signalType, alertTypes, dateFrom, dateTo, subtitle, signalEventConfiguration, onClick}: HealthReportEventsPerDayProps) {
    const { t } = useTranslation();
    const nbDays = differenceInDays(dateTo, dateFrom)+1;

    const [averageEventsLowPerHourForPeriodLoading, averageEventsLowPerHourForPeriod] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getAverageEventsPerHoursForPeriod,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
            alertType: AlertType.Alert_LOW,
        },
    });
    const [averageEventsHighPerHourForPeriodLoading, averageEventsHighPerHourForPeriod] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getAverageEventsPerHoursForPeriod,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
            alertType: AlertType.Alert_HIGH,
        },
    });

    const [eventsPerDayLowLoading, eventsPerDayLow] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventsPerDay,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
            alertType: AlertType.Alert_LOW,
        },
    });
    const [eventsPerDayHighLoading, eventsPerDayHigh] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventsPerDay,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            from: formatJSDateToApiDateTs(dateFrom),
            to: formatJSDateToApiDateTs(dateTo),
            alertType: AlertType.Alert_HIGH,
        },
    });

    const loading = (
        (averageEventsLowPerHourForPeriodLoading || !averageEventsLowPerHourForPeriod) ||
        (averageEventsHighPerHourForPeriodLoading || !averageEventsHighPerHourForPeriod) ||
        (eventsPerDayLowLoading || !eventsPerDayLow) ||
        (eventsPerDayHighLoading || !eventsPerDayHigh)
    );

    const handleClick: CategoricalChartFunc = (rechartData) => {
        if (onClick) {
            if (rechartData && rechartData.activePayload && rechartData.activePayload[0]) {
                const rechartsPayload = rechartData?.activePayload[0].payload;
                if (rechartsPayload) {
                    const selectedDate = new Date(rechartsPayload.date);
                    const duration = rechartsPayload.reliableDurationInSeconds;
                    if (duration && duration > 0) {
                        onClick(selectedDate);
                    }
                }
            }
        }
    };

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>) => {
        if (data?.payload && data?.payload[0] && data?.payload[0].payload) {
            const reliableDurationInSeconds = data?.payload[0]?.payload?.reliableDurationInSeconds;
            const hasEnoughData = data?.payload[0]?.payload?.hasEnoughData;
            const unit = signalType === SignalType.Signal_SPO2 ? '%' : ` ${t('global.eventsPerHour')}`;

            const eventsAbove = data?.payload[0]?.payload?.above;
            const eventsBelow = Math.abs(data?.payload[0]?.payload?.below);

            return (
                <div className="chart-tooltip">
                    <strong>{formatDateUniversal(new Date(data?.payload[0]?.payload?.date + ' 00:00:00'))}</strong><br/>
                    {!reliableDurationInSeconds ? (
                        <span>{t('healthReport.statistics.last7nights.noRecordingForThisDay')}</span>
                    ) : (<>
                        <span className="tooltip-duration">{t('healthReport.statistics.last7nights.duration')}: {formatSecondsToHms(reliableDurationInSeconds)}</span>
                        {!hasEnoughData ? (
                            <span>{t('healthReport.statistics.last7nights.notEnoughDataForThisDay')}</span>
                        ) : (<>
                            <span className="tooltip-events">
                                {t('global.above')}{': '}{eventsAbove.toFixed(2)}{unit}
                            </span>
                            <span className="tooltip-events">
                                {t('global.below')}{': '}{eventsBelow.toFixed(2)}{unit}
                            </span>
                        </>)}
                    </>)}
                </div>
            );
        }
        return null;
    };

    if (patientId && dateFrom && dateTo && signalEventConfiguration) {
        const title = (() => {
            switch(signalType) {
            case SignalType.Signal_PR: return t('healthReport.events.last7nights.pulseRateTitle');
            case SignalType.Signal_SPO2: return t('healthReport.events.last7nights.spo2Title');
            default: return ''; // not used
            }
        })();

        subtitle = subtitle ?? (() => {
            const out: string[] = [];
            if (alertTypes.includes(AlertType.Alert_LOW)) {
                out.push(`${t('global.below')} ${signalEventConfiguration.low?.value}${getSignalTypeUnit(signalType)}`);
            }
            if (alertTypes.includes(AlertType.Alert_HIGH)) {
                out.push(`${t('global.above')} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`);
            }
            return out.join(' or ');
        })();

        if (!loading) {
            // Merge events per hour above and below as one array
            const eventsPerDay: EventFormatted[] = [];
            const refEventsPerDay = alertTypes[0] === AlertType.Alert_HIGH ? eventsPerDayHigh.eventsPerDay : eventsPerDayLow.eventsPerDay;
            for (let i=0; i < refEventsPerDay?.length; ++i) {
                const baseEvent = refEventsPerDay[i];
                const hasEnoughData = baseEvent.hasRecording && baseEvent.hasData >= HasData.HAS_MIN_RELIABLE_DATA;
                eventsPerDay[i] = {
                    ...baseEvent,
                    hasEnoughData: hasEnoughData,
                    duration: (hasEnoughData && baseEvent.reliableDurationInSeconds > 0) ? roundDurationHoursToQuarterPrecision(baseEvent.reliableDurationInSeconds) : null,
                    events: (eventsPerDayHigh.eventsPerDay[i]?.events ?? 0) + (eventsPerDayLow.eventsPerDay[i]?.events ?? 0),
                    above: eventsPerDayHigh.eventsPerDay[i]?.events ?? 0,
                    below: -1 * (eventsPerDayLow.eventsPerDay[i]?.events ?? 0),
                };
            }

            // Define domain by taking the min/max amount of event per hour for symetry
            let maxAmount = 0;
            for (let i=0; i < refEventsPerDay.length; ++i) {
                if (eventsPerDay[i].above! > maxAmount) {
                    maxAmount = eventsPerDay[i].above!;
                }
                if (-1 * eventsPerDay[i].below! > maxAmount) {
                    maxAmount = -1 * eventsPerDay[i].below!;
                }
            }

            const domainForEvents = getDomainForEvents(maxAmount, true);

            const eventsPerDayNotZero = eventsPerDay.filter(x => x.events && x.events !== 0).length;
            const hasDataAbove = averageEventsHighPerHourForPeriod?.hasData ?? HasData.HAS_NO_DATA;
            const hasDataBelow = averageEventsLowPerHourForPeriod?.hasData ?? HasData.HAS_NO_DATA;
            const bestHasData: HasData = Math.max(hasDataAbove, hasDataBelow);

            const tooltipText = <>
                <p>
                    {signalType === SignalType.Signal_PR &&
                        <>{t('infoButton.eventsPerDay.pulseRate')}&nbsp;</>
                    }
                    {signalType === SignalType.Signal_SPO2 &&
                        <>{t('infoButton.eventsPerDay.spo2')}&nbsp;</>
                    }
                    {t('infoButton.eventsPerDay.reliableData')}
                </p>
                <p>
                    {t('infoButton.eventsPerDay.duration')}
                </p>
            </>;

            const signalTypeLow = colorPalette.signalTypeLight;

            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    {/*<pre>*/}
                    {/*    {JSON.stringify(eventsPerDay, null, '  ')}*/}
                    {/*</pre>*/}
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                        infoTooltipTitle={`${t(`global.${getSignalTypeIdentifier(signalType)}`)} - ${t('infoButton.eventsPerDay.title')}`}
                        infoTooltipText={tooltipText}
                        infoTooltipOverlayPosition="bottomLeft"
                    >
                        {bestHasData < HasData.HAS_MIN_RELIABLE_DATA ? (
                            <div className="empty-message">
                                {(bestHasData === HasData.HAS_NO_DATA) && (
                                    t('healthReport.events.last7nights.noRecording', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_DATA) && (
                                    t('healthReport.events.last7nights.noReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                                {(bestHasData === HasData.HAS_RELIABLE_DATA) && (
                                    t('healthReport.events.last7nights.notEnoughReliableData', {
                                        nbDays: nbDays,
                                    })
                                )}
                            </div>
                        ) : (eventsPerDayNotZero <= 0) ? (
                            <div className="empty-message">
                                {t('healthReport.events.last7nights.noEvent', {
                                    nbDays: nbDays,
                                })}
                            </div>
                        ) : (
                            <BarChart height={250} data={eventsPerDay} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick} stackOffset="sign">
                                <XAxis dataKey="date" interval="equidistantPreserveStart" tick={<ChartAxisTickDate textAnchor="middle" />} />
                                <YAxis
                                    orientation="left"
                                    dataKey="events"
                                    width={40}
                                    domain={domainForEvents.domain}
                                    ticks={domainForEvents.ticks}
                                    interval={0}
                                    allowDataOverflow={true}
                                    tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]} textAnchor="end" />}
                                    label={(props: ChartDualLabelProps) => (
                                        <ChartDualLabel
                                            {...props}
                                            fill={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}
                                            aboveText={`${t('global.above')} ${signalEventConfiguration.high?.value}${getSignalTypeUnit(signalType)}`}
                                            belowText={`${t('global.below')} ${signalEventConfiguration.low?.value }${getSignalTypeUnit(signalType)}`}
                                        />
                                    )}
                                />
                                {/* Invisible YAxis to align with HealthReportPercentagePerDay : */}
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    dataKey="duration"
                                    width={34}
                                    interval={0}
                                    strokeWidth={0}
                                    tick={false}
                                    stroke={'#ff0000'}
                                    padding={{ top: 10 }}
                                />
                                <Tooltip content={(data) => renderTooltip(data)} isAnimationActive={false}/>
                                <ReferenceLine
                                    y={0}
                                    stroke={'rgba(255,255,255, 0.2)'}
                                />
                                {alertTypes.includes(AlertType.Alert_LOW) && (
                                    <Bar
                                        minPointSize={(_, index) => eventsPerDay[index].reliableDurationInSeconds > 0 ? -1 : 0}
                                        stackId="stack"
                                        //dot={{ fill: signalTypeLow[getSignalTypeIdentifier(signalType)] }}
                                        dataKey={'below'}
                                        fill={signalTypeLow[getSignalTypeIdentifier(signalType)]}
                                    />
                                )}
                                {alertTypes.includes(AlertType.Alert_HIGH) && (
                                    <Bar
                                        minPointSize={(_, index) => eventsPerDay[index].reliableDurationInSeconds > 0 ? 1 : 0}
                                        stackId="stack"
                                        //dot={{ fill: colorPalette.signalType[getSignalTypeIdentifier(signalType)] }}
                                        dataKey="above"
                                        fill={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}
                                    />
                                )}
                            </BarChart>
                        )}
                    </ChartContainer>
                </StyledWrapper>
            );
        }
        else {
            return (
                <StyledWrapper className="health-report-events-per-day" color={colorPalette.signalTypeLight[getSignalTypeIdentifier(signalType)]}>
                    <ChartContainer
                        title={title}
                        subtitle={subtitle}
                    >
                        <LoadingView color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]} />
                    </ChartContainer>
                </StyledWrapper>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="HealthReportEventsPerDay" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: relative;
    min-height: 260px;
    height: 100%;
    display: flex; 
    flex-direction: column;
    
    > * {
      flex-grow: 1;
    }

    .tooltip-events {
        color: ${props => props.color};
    }

    .tooltip-duration {
        color: ${colorPalette.clearColor};
    }
    
    svg {
        cursor: pointer;
    }

    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportEventsPerDay};
