import {JSX, PropsWithChildren, /*MouseEventHandler, MouseEvent,*/ useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {Button} from '@/components/buttons/button';
import {colorPalette} from '@/themes/darkmode';

type PopupMenuProps = PropsWithChildren<{
    buttonText: string | JSX.Element;
    className: string;
    overlayPosition?: 'bottomRight' | 'topRight';
    showActions?: boolean;
    onClosed?: () => void;
    onReset?: () => void;
}>;

function UnstyledPopupMenu({buttonText, children, className, overlayPosition, showActions, onClosed, onReset}: PopupMenuProps){
    const [showPopupMenuContent, setShowPopupMenuContent] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleDocumentClick, { capture: true });
        };
    }, [showPopupMenuContent]);

    const handleDocumentClick = (e: MouseEvent) => {
        if (showPopupMenuContent) {
            const isTargetNotPopupMenu = !(e.target instanceof HTMLElement) || !(ref.current?.contains(e.target) ?? false);
            if (isTargetNotPopupMenu) {
                setShowPopupMenuContent(false);
                if (onClosed) {
                    onClosed();
                }
            }
        }
    };

    const handleOpenMenu = () => {
        if (showPopupMenuContent && onClosed) {
            onClosed();
        }
        setShowPopupMenuContent(showPopupMenuContent => !showPopupMenuContent);
    };

    const handleApply = () => {
        setShowPopupMenuContent(false);
        if (onClosed) {
            onClosed();
        }
    };

    const handleCancel = () => {
        setShowPopupMenuContent(false);
        if (onReset) {
            onReset();
        }
    };

    return (
        <div className={`popup-menu ${className ?? ''}`} ref={ref}>
            <button className="popup-menu-button" onClick={handleOpenMenu}>{buttonText}</button>
            {(showPopupMenuContent && (
                <div className={`popup-menu-overlay animate__animated animate__fadeIn animate__faster ${overlayPosition ?? 'topRight'}`}>
                    <button className="popup-menu-close-button" onClick={handleCancel}>&times;</button>

                    {children}

                    {showActions && (
                        <div className="popup-menu-actions">
                            <Button className="button-active popup-menu-action-apply" onClick={handleApply}>
                                {t(['Apply', 'global.apply'])}
                            </Button>
                            <Button className="popup-menu-action-cancel btn-neutral" onClick={handleCancel}>
                                {t(['Cancel', 'global.cancel'])}
                            </Button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

//language=SCSS
const PopupMenu = Styled(UnstyledPopupMenu)`
& {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    
    &:last-child {
        margin-right: 0;
    }
    
    .popup-menu-actions {
        text-align: right;
        padding: 20px 0 0 0;
        
        .popup-menu-action-apply {
            margin-right: 10px;
        }
    }
    
    .popup-menu-button {
        &, &:link, &:active {
            display: inline-block;
            color: ${colorPalette.frontColor};
            background: ${colorPalette.thirdBackground};
            padding: 8px 12px;
            text-transform: uppercase;
            font-size: 14px;
            border: none;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
        }

        &:hover, &focus {
            background-color: ${colorPalette.activeColor};
        }
    }
    
    .popup-menu-overlay {
        background: ${colorPalette.thirdBackground};
        padding: 30px;
        margin-left: 20px;
        border-radius: 5px;
        z-index: 1050;
        position: absolute;
        width: auto;
        left: 100%;
        top: 0;
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 10px 0;
            border-color: transparent ${colorPalette.thirdBackground} transparent transparent;
        }
        &.bottomRight {
            bottom: 0;
            top: initial;
            &::after {
                bottom: 10px;
                top: initial;
            }
        }
    }

    .popup-menu-close-button {
        position: absolute;
        right: 10px;
        top: 5px;
        border: none;
        font-size: 20px;
        color: ${colorPalette.frontColor};
        background: transparent;
    }
}`;

export {PopupMenu};
