import {useAuth0} from '@auth0/auth0-react';
import {User as Auth0User} from '@auth0/auth0-spa-js';
import { LastSeenNotification } from 'gabi-api-ts/v2/hcp/query/hcp_query';
import {JSX, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Styled from 'styled-components';

import {AppLogoFull} from '@/components/static/app-logo-full';
import {Breadcrumb} from '@/components/widgets/breadcrumb';
import {ChangelogGift} from '@/components/widgets/changelog-gift';
import {HelpCenter} from '@/components/widgets/help-center';
import {TimetravelWidget} from '@/components/widgets/timetravel-widget';
import {UserMenu} from '@/components/widgets/user-menu';
import {Loader} from '@/decorators/loader.new';
import homeImage from '@/public/icons/home-icon.svg';
import {useCurrentPage} from '@/services/current-page-service';
import {useDebugFlags} from '@/services/debug-service';
import {useTutorialStatus} from '@/services/tutorial-service';
import {colorPalette, medias, zIndices} from '@/themes/darkmode';
import userChangelog from '@/user-changelog.json';

function wrapWithLoader(Component: JSX.ElementType) {
    return Loader({
        models: {
            giftNotification: () => ({name: 'getGiftNotificationLastSeen', domain: 'hcp', cached: false}),
        }
    })(Component);
}

type MainTopbarFullProps = {
    className: string;
    giftNotification: LastSeenNotification;
};

function MainTopbarFullUnstyled(props: MainTopbarFullProps) {
    const [showChangelogGift, setShowChangelogGift] = useState(true);
    const debugFlags = useDebugFlags();
    const me: Auth0User | undefined = useAuth0().user;
    const currentPage = useCurrentPage();
    const tutorialStatus = useTutorialStatus();

    useEffect(() => {
        // Check if change log entries have been seen by user
        if (props.giftNotification && props.giftNotification.time) {
            const jsonChangelog = userChangelog.changelog;
            const toTimestamp = new Date(Number(props.giftNotification.time.seconds) * 1000).getTime();
            const updatedChangelog = [];

            jsonChangelog.forEach(changelogEntry => {
                const releaseDateTimestamp = new Date(changelogEntry.releaseDate).getTime();
                if (releaseDateTimestamp > toTimestamp) {
                    updatedChangelog.push(changelogEntry);
                }
            });

            setShowChangelogGift(debugFlags.forceShowChangelogGift || updatedChangelog.length > 0);
        }
    }, [debugFlags.forceShowChangelogGift]);

    function handleChangelogGiftClosed() {
        setShowChangelogGift(false);
    }

    if (tutorialStatus && props.giftNotification) {
        return (
            <header className={props.className}>
                <Link className="topbar-home-link" to="/patients"/>
                <div className="topbar-app-logo">
                    <AppLogoFull height={42} className="login-logo"/>
                </div>
                <h1><Breadcrumb path={currentPage.pagePath}/> {currentPage.pageName}</h1>
                {showChangelogGift && (
                    <ChangelogGift className="topbar-right-icon" giftNotification={props.giftNotification} onClosed={handleChangelogGiftClosed} />
                )}
                {__GABI_ENV__ !== 'production' && (<TimetravelWidget className="topbar-right-icon" onClosed={() => {}}/>)}
                <HelpCenter className="topbar-right-icon" currentPage={currentPage.pageName} tutorialStatus={tutorialStatus} />
                <UserMenu className="topbar-user-menu" user={me} direction="bottom" picLocation="right" />
            </header>
        );
    } else {
        return null;
    }
}

//language=SCSS
const MainTopbarFullStyled = Styled(MainTopbarFullUnstyled)`
& {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: flex;
    background:${colorPalette.thirdBackground};
    z-index: ${zIndices.header};
    
    > * {
        flex-grow: 0;
        flex-shrink: 0;
    }
    > h1 {
        flex-grow: 1;
    }

    .topbar-home-link {
        background: url(${homeImage}) no-repeat center;
        display: inline-block;
        width: 70px;
        height: 70px;
        border-right: 3px solid ${colorPalette.mainBackground};
        &:hover, &:active, &:visited {
            background-color:${colorPalette.activeColor};
        }
    }
    
    .topbar-app-logo, h1 {
        padding-left: 20px;
        padding-right: 18px;
    }
    .topbar-app-logo {
        width: 181px;
        padding-top: 15px;
        border-right: 3px solid ${colorPalette.mainBackground};
    }
    h1 {
        font-size: 16px;
        padding-top: 12px;
        @media screen and (${medias.xlMin}) {
            font-size: 20px;
            padding-top: 12px;
        }
    }
    
    .topbar-right-icon {
        width:70px;
    }

    .topbar-user-menu {
    }
}
`;

const MainTopbarFull = wrapWithLoader(MainTopbarFullStyled);
export {MainTopbarFull};
