
class _BackendApiCache {
    store: Record<string, { responseData: unknown, size: number }> = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

    put(backendCallKey: string, response: unknown) {
        const size = backendCallKey.length + (() => {
            try {
                return JSON.stringify(response).length;
            }
            catch {
                return Number.NaN;
            }
        })();
        if (isNaN(size)) {
            console.warn(`BackendApiCache: cannot store ${backendCallKey}; Impossible to estimate size`);
        }
        else if (size > 1024*1024) {
            console.warn(`BackendApiCache: cannot store ${backendCallKey}; size exceeds 1MB`);
        }
        else {
            this.store[backendCallKey] = {
                size: size,
                responseData: response,
            };
            console.debug(`BackendApiCache: stored ${backendCallKey}`, response);
        }
    }

    get(backendCallKey: string) {
        return this.store[backendCallKey];
    }

    clear(backendCallKey: string) {
        if (this.store[backendCallKey]) {
            delete this.store[backendCallKey];
        }
    }

    /**
     * Calls clear() on all the cached data starting with
     * @param backendCallKeyPrefix the prefix mark as dirty
     */
    clearMatching(backendCallKeyPrefix: string) {
        const toDelete: string[] = Object.keys(this.store).filter(key => key && key.startsWith(backendCallKeyPrefix));
        for (const key of toDelete) {
            delete this.store[key];
        }
    }
}

const BackendApiCache = new _BackendApiCache();

export { BackendApiCache };
