import {withAuth0} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import HcpUpdateConfiguration from '@/components/business/analytics/hcp/hcp-update-configuration';
import HcpUpdateEmail from '@/components/business/analytics/hcp/hcp-update-email';
import HcpUpdateGroups from '@/components/business/analytics/hcp/hcp-update-groups';
import {HcpUpdatePassword} from '@/components/business/analytics/hcp/hcp-update-password';
import {HcpUpdatePersonalData} from '@/components/business/analytics/hcp/hcp-update-personal-data';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Page} from '@/decorators/page';

function wrapWithPage(Component) {
    return Page({
        name: 'Profile'
    })(Component);
}

class HcpUpdatePage extends React.Component {
    static propTypes = {
        auth0: PropTypes.object,
        t: PropTypes.func,
        className: PropTypes.string,
    };

    state = {
        activeStep: 0,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const me = props.auth0.user;
        
        const steps = [
            props.t('pages.hcpUpdate.steps.profile'),
            props.t('pages.hcpUpdate.steps.configuration'),
            props.t('pages.hcpUpdate.steps.groups'),
            props.t('pages.hcpUpdate.steps.email'),
            props.t('pages.hcpUpdate.steps.password')
        ];

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <div className="sections-wrapper">
                        <nav className="sections-menu">
                            {steps.map((step, i) => (
                                <a key={i} className={(state.activeStep === i) ? `section-menu-step-${i} activeStep` : `section-menu-step-${i}`} onClick={() => this.handleStepClicked(i)}>{step}</a>
                            ))}
                        </nav>

                        <div className="sections-content">
                            {state.activeStep === 0 &&
                                <HcpUpdatePersonalData hcp={me} />
                            }
                            {state.activeStep === 1 &&
                                <HcpUpdateConfiguration hcp={me} />
                            }
                            {state.activeStep === 2 &&
                                <HcpUpdateGroups hcp={me} />
                            }
                            {state.activeStep === 3 &&
                                <HcpUpdateEmail hcp={me} />
                            }
                            {state.activeStep === 4 &&
                                <HcpUpdatePassword hcp={me} />
                            }
                        </div>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }

    handleStepClicked(stepIndex) {
        this.setState(state => ({
            ...state,
            activeStep: stepIndex,
        }));
    }
}

//language=SCSS
HcpUpdatePage = Styled(HcpUpdatePage)`
& {
    min-height: 500px;
    width: 90%;
    margin: 70px auto 0 auto;
}
`;

HcpUpdatePage = wrapWithPage(withTranslation()(withAuth0(HcpUpdatePage)));

export {HcpUpdatePage};
