import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import Styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode.js';

class IconTooltip extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
        title: PropTypes.string,
        icon: PropTypes.any,
        iconAltText: PropTypes.string,
        overlayPosition: PropTypes.string, // "bottomRight", "bottomLeft"
        onClosed: PropTypes.func,
    };

    state = {
        showTooltip: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
        this.ref = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    render() {
        const state = this.state;
        const props = this.props;

        return (
            <div className={`${props.className}`} ref={this.ref}>
                <button className="icon-tooltip-button" onClick={this.toggleOverlay}>
                    {props.icon && <img src={props.icon} alt={props.iconAltText} />}
                    {props.title && <span>{props.title}</span>}
                </button>
                {(state.showTooltip &&
                    <div className={`icon-tooltip-overlay animate__animated animate__fadeIn animate__faster ${props.overlayPosition}`}>
                        <button className="icon-tooltip-close-button" onClick={this.toggleOverlay}>&times;</button>
                        
                        {props.title && (
                            <h4>{props.title}</h4>
                        )}
                        {props.children}
                    </div>
                )}
            </div>
        );
    }

    toggleOverlay() {
        this.setState(state => ({
            ...state,
            showTooltip: !state.showTooltip
        }));
    }

    handleDocumentClick(e) {
        if (this.state.showTooltip && !this.ref.current.contains(e.target)){
            this.setState(state => ({
                ...state,
                showTooltip: !state.showTooltip
            }));

            if(this.props.onClosed) {
                this.props.onClosed();
            }
        }
    }

    shouldComponentUpdate() {
        return true; // Layout component, has children
    }
}

//language=SCSS
IconTooltip = Styled(IconTooltip)`
    & {
        position:relative;
        display:inline-block;
        width: 30px;

        h4 {
            font-size:14px;
            font-weight:bold;
        }

        &:last-child {
            margin-right:0;
        }

        .icon-tooltip-button {
            &, &:link, &:active {
                padding:4px;
                background-color:transparent;
                margin-top:5px;
                margin-bottom:3px;
                cursor:pointer;
                border:none;
                color:${colorPalette.frontColor};
                font-size:12px;
                border-radius: 30px;
            }
            
            img {
                width: 20px;
            }

            &:hover, &focus {
                background-color:${colorPalette.thirdBackground};
            }
        }

        .icon-tooltip-overlay {
            background:${colorPalette.thirdBackground};
            padding: 30px;
            margin-left: 20px;
            border-radius: 5px;
            z-index: 1050;
            position: absolute;
            width: 300px;
            left: 100%;
            top:0;
            &::after {
                content: "";
                position: absolute;
                top: 10px;
                left: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 10px 0;
                border-color: transparent ${colorPalette.thirdBackground} transparent transparent;
            }
            &.bottomRight {
                top: 0;
                &::after {
                    top:10px;
                }
            }
            &.bottomLeft {
                top: -10px;
                left:initial;
                right: 100%;
                margin-right:10px;
                margin-left:0;
                &::after {
                    top:10px;
                    left:initial;
                    right:-10px;
                    border-width: 10px 0 10px 10px;
                    border-color: transparent transparent transparent ${colorPalette.thirdBackground};
                }
            }
        }

        .icon-tooltip-close-button {
            position:absolute;
            right:10px;
            top:5px;
            border:none;
            font-size: 20px;
            color:${colorPalette.frontColor};
            background:transparent;
        }
    }`;

export default IconTooltip;

