import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components';

import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation';
import {PatientsInactive} from '@/components/business/analytics/patients/patients-inactive';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Page} from '@/decorators/page';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum';

function wrapWithPage(Component) {
    return Page({
        name: 'Inactive patients'
    })(Component);
}

class PatientsInactivePage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.INACTIVE} />

                    <PatientsInactive />
                </ErrorBoundary>
            </div>
        );
    }
}

//language=SCSS
PatientsInactivePage = Styled(PatientsInactivePage)`
& {
    min-height: 500px;
    width: 95%;
    max-width: 1700px;
    height: calc(100% - 80px);
    margin: 30px auto 0 auto;
}
`;

export default wrapWithPage(withTranslation()(PatientsInactivePage));

