import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {getHcpProgramConfig} from '@/components/business/analytics/patients/hcp-program-config';
import {PatientsActiveTableRow} from '@/components/business/analytics/patients/patients-active-table-row';
import {InfoMessage} from '@/components/form/info-message';
import {TableSortable} from '@/components/table/table-sortable';
import {TableSortableColumn} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';

type PatientsActiveTableProps = {
    patients: Patient[];
    program: number;
}

const PatientsActiveTable = ({patients, program}: PatientsActiveTableProps) => {
    const { t } = useTranslation();

    const programConfig = getHcpProgramConfig(program);
    const tableSortableHeaderColumn: TableSortableColumn<Patient>[] = [
        {
            id: 'id',
            label: '',
            sortable: false,
        },
        {
            id: 'lastName',
            label: t('patient.title') ?? 'Patient',
            compareFn: (a, b) => {
                const aStr = `${a.lastName ?? ''}, ${a.firstName ?? ''}`;
                const bStr = `${b.lastName ?? ''}, ${b.firstName ?? ''}`;
                return aStr.localeCompare(bStr);
            },
            sortable: true,
        },
        {
            id: 'lastRecordingDataTimeSortable',
            label: t('patient.lastRecording') ?? 'Last recording',
            compareFn: (a, b) => {
                const aStr = a.lastRecordingDataTime ? formatApiFullDateToJSDate(a.lastRecordingDataTime).toISOString() : '2000-01-01';
                const bStr = b.lastRecordingDataTime ? formatApiFullDateToJSDate(b.lastRecordingDataTime).toISOString() : '2000-01-01';
                return aStr.localeCompare(bStr);
            },
            sortable: true,
        },
    ];
    if (programConfig.pulseRate.average) {
        tableSortableHeaderColumn.push({
            id: 'lastRecordingInformation.pulseRate.statistics.average',
            label: t('global.pulseRate') ?? 'Pulse Rate',
            subLabel: t('global.average') ?? 'Average',
            className: 'signalTableHeader signalTableHeader_pulseRate',
            sortable: false,
        });
    }
    if (programConfig.spo2.average) {
        tableSortableHeaderColumn.push({
            id: 'lastRecordingInformation.spo2.statistics.average',
            label: t('global.spo2') ?? 'SpO2',
            subLabel: t('global.average') ?? 'Average',
            className: 'signalTableHeader signalTableHeader_spo2',
            sortable: false,
        });
    }
    if (programConfig.pulseRate.eventsBelow) {
        tableSortableHeaderColumn.push({
            id: 'lastRecordingInformation.pulseRate.eventsCountLow.eventCount',
            label: t('global.pulseRate') ?? 'Pulse Rate',
            subLabel: `${t('global.eventsTitle') ?? 'Events/h'} - ${t('global.below') ?? 'Below'}`,
            className: 'separatorCell signalTableHeader signalTableHeader_pulseRate',
            sortable: false,
        });
    }
    if (programConfig.pulseRate.eventsAbove) {
        tableSortableHeaderColumn.push({
            id: 'lastRecordingInformation.pulseRate.eventsCountHigh.eventCount',
            label: t('global.pulseRate') ?? 'Pulse Rate',
            subLabel: `${t('global.eventsTitle') ?? 'Events/h'} - ${t('global.above') ?? 'Above'}`,
            className: 'signalTableHeader signalTableHeader_pulseRate',
            sortable: false,
        });
    }
    if (programConfig.spo2.eventsBelow) {
        tableSortableHeaderColumn.push({
            id: 'lastRecordingInformation.spo2.eventPercent.eventPercent',
            label: t('global.spo2') ?? 'SpO2',
            subLabel: `${t('global.percentage') ?? 'Percentage'} - ${t('global.below') ?? 'Below'}`,
            className: 'signalTableHeader signalTableHeader_spo2',
            sortable: false,
        });
    }

    if (program !== null && patients) {
        if (patients && patients.length > 0) {
            return (
                <StyledWrapper>
                    <TableSortable
                        data={patients}
                        columns={tableSortableHeaderColumn}
                        defaultSorting={{id: 'lastRecordingDataTimeSortable', sortByOrder: 'default'}}
                        TableBodyRowComponent={(props) => <PatientsActiveTableRow {...props} programConfig={programConfig} />}
                    />
                </StyledWrapper>
            );
        }
        else {
            return <InfoMessage infoMessage="No active patients." />;
        }
    }
    else {
        return <ComponentErrorMessage component="PatientsActiveTable" />;
    }
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    thead > tr {
        > th:nth-child(1 of .signalTableHeader) {
            border-left-width: 5px;
        }
    }
}
`;

export {PatientsActiveTable};
