import {PropsWithChildren, ReactNode} from 'react';
import {useState} from 'react';

import '@/stylesheets/bootstrap.min.css';

type PatientsActiveTableCellHoverableProps = PropsWithChildren<{
    className?: string;
    hasHover: boolean;
    hoverElement: () => ReactNode | null;
}>;

function PatientsActiveTableCellHoverable({ className, children, hasHover, hoverElement }: PatientsActiveTableCellHoverableProps) {
    const [showHover, setShowHover] = useState<boolean>(false);

    const handleHover = () => {
        if (hasHover) {
            setShowHover(true);
        }
    };

    const handleLeave = () => {
        setShowHover(false);
    };

    return (
        <td
            className={`hoverableCell ${showHover ? ' activeCell': ''} ${(className ? ` ${className}` : '')}`}
            onMouseOver={hasHover ? handleHover: undefined}
            onMouseLeave={handleLeave}
        >
            {children}
            {showHover ? hoverElement() : null}
        </td>
    );
}

export { PatientsActiveTableCellHoverable };
