import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import LoadingView from '@/components/static/loading-view';
import {useDaysWithSignalData} from '@/hooks/backend/use-days-with-signal-data';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {NewDateUtil} from '@/util/new-date-util';
import {formatApiDateForUrl, formatDateForUrl} from '@/util/url-util';

function PatientTimelineRedirectPage() {
    const navigate = useNavigate();
    const params = useParams();

    const [patientDataLoading, patientData] = usePatientData({
        patientId: params.id_patient ?? '',
    });
    const [daysWithSignalDataLoading, daysWithSignalData] = useDaysWithSignalData({
        patient: patientData!,
        disabled: patientDataLoading,
    });

    useEffect(() => {
        if (!daysWithSignalDataLoading && daysWithSignalData) {
            let lastDayWithData: string = formatDateForUrl(NewDateUtil());
            if ((daysWithSignalData.day ?? []).length > 0) {
                lastDayWithData = formatApiDateForUrl(daysWithSignalData.day[daysWithSignalData.day.length - 1]);
            }
            const url = `/patients/${params.id_patient}/timeline/${lastDayWithData}`;
            navigate(url, { replace: true });
        }
    }, [ daysWithSignalDataLoading ]);

    if (daysWithSignalDataLoading) {
        return (
            <LoadingView />
        );
    }
    return null;
}

export {PatientTimelineRedirectPage};
