import {MessageType} from '@protobuf-ts/runtime';
import type {ServiceInfo} from '@protobuf-ts/runtime-rpc';
import {useEffect, useState, useSyncExternalStore} from 'react';

import {buildBackendCallKey, grpcRequest, GrpcRequestFn, ServiceClientConstructor} from '@/services/api-requests/grpc-request-ts';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiCache} from '@/services/backend-api-cache';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {NoExtraProp} from '@/types/no-extra-prop';
import {singlePromise} from '@/util/promise-util';

export type useBackendQueryArgs<ServiceClient extends ServiceInfo, Request extends object, Response extends object> = {
    serviceClient: ServiceClientConstructor<ServiceClient>;
    query: GrpcRequestFn<Request, Response>;
    data: Request | MessageType<Request>;
    memoize?: boolean;
    disabled?: boolean;
};
export type useBackendQueryResult<R> = [
    boolean, // loading state
    R | null, // response
    Error | null, // error
    () => void, // refresh function
];

export function useBackendQuery<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
(options: NoExtraProp<useBackendQueryArgs<ServiceClient, Request, Response>>, dependencies?: unknown[]) : useBackendQueryResult<Response> {
    const {disabled, memoize, data} = options;
    const backendCallKeyPrefix = buildBackendCallKey(options.serviceClient, options.query);

    const dataStr = data && (data as MessageType<Request>).toJsonString ?
        (data as MessageType<Request>).toJsonString(data as Request) :
        JSON.stringify(data);
    const backendCallKey = `${backendCallKeyPrefix}/${dataStr}`;
    dependencies = dependencies ?? [];

    const cachedResponse = memoize ? BackendApiCache.get(backendCallKey) : null;
    const hasMemoizedData = !disabled && memoize && cachedResponse;

    const [loading, setLoading] = useState<boolean>(!hasMemoizedData);
    const [result, setResult] = useState<Response | null>(cachedResponse?.responseData as Response ?? null);
    const [error, setError] = useState<Error | null>(null);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    function refresh() {
        BackendApiCache.clear(backendCallKey); // no need to check for memoize. If the key is not there, it does not matter
        setRefreshCounter(refreshCounter+1); // trigger a re-fetch
    }

    // Refresh listener
    const refreshListener = BackendApiRefreshListeners.get(backendCallKeyPrefix);
    const listenerCounter = useSyncExternalStore<number>(
        refreshListener.onChange,
        () => refreshListener.value,
    );

    useEffect(() => {
        if (disabled) {
            setLoading(false);
            return;
        }
        setLoading(!hasMemoizedData);
        setResult(cachedResponse?.responseData as Response ?? null);
        setError(null);

        const fetchPromise = fetchData(backendCallKey, options);

        fetchPromise
            .then(response => {
                setResult(response);
                setError(null);
            })
            .catch(error => {
                console.error(error);
                setResult(null);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ ...dependencies, refreshCounter, listenerCounter, backendCallKey, disabled ]);

    return [
        loading,
        result,
        error,
        refresh,
    ];
}

async function fetchData<ServiceClient extends ServiceInfo, Request extends object, Response extends object>
(backendCallKey: string, {serviceClient, query, data, memoize}: useBackendQueryArgs<ServiceClient, Request, Response>) {
    console.debug('backendCallKey', backendCallKey);

    if (memoize) {
        const cachedResponse = BackendApiCache.get(backendCallKey);
        if (cachedResponse) {
            console.debug(`useBackendQuery: returned memoized ${backendCallKey}`, cachedResponse.responseData);
            return cachedResponse.responseData as Response;
        }
    }

    const accessToken = await Auth0Service.getAccessToken();
    const out = await singlePromise(() => {
        return grpcRequest<ServiceClient, Request, Response>(
            accessToken ?? '',
            serviceClient,
            query,
            data as Request
        );
    }, backendCallKey);

    if (memoize) {
        BackendApiCache.put(backendCallKey, out.response);
    }

    return out.response;
}
