import deepEqual from 'deep-eql';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientGroupUsers from '@/components/business/analytics/patient/common/patient-group-users';
import PatientGroupUsersTooltip from '@/components/business/analytics/patient/common/patient-group-users-tooltip';
import {Button} from '@/components/buttons/button';

import type {Group} from '@/types/group';

type PatientGroupsFormProps = {
    className?: string;
    updateMode: boolean;
    groups: Group[];
    loading: boolean;
    onChanged?: (dataChanged: boolean) => void;
    onSubmit: (slectedGroups: {[groupId: string]: boolean}) => void;
};

function convertGroupsToSelectedGroups(groups: Group[]) {
    const selectedGroups: {[groupId: string]: boolean} = {};
    groups.forEach(group => {
        selectedGroups[group.groupId] = group.selected;
    });
    return selectedGroups;
}

function PatientGroupsFormUnstyled({className, updateMode, groups, loading, onChanged, onSubmit}: PatientGroupsFormProps) {
    const { t } = useTranslation();
    const [selectedGroups, setSelectedGroups] = useState<{[groupId: string]: boolean}>({});
    const [dataChanged, setDataChanged] = useState<boolean>(false);

    useEffect(() => {
        setSelectedGroups(convertGroupsToSelectedGroups(groups));
    }, [ groups ]);

    useEffect(() => {
        checkDataChanged();
    }, [ selectedGroups ]);

    const handleChangeGroupSelection = (e: ChangeEvent<HTMLInputElement>) => {
        const groupId = e.target.value;
        const isChecked = e.target.checked;

        const newSelectedGroups = {...selectedGroups};
        newSelectedGroups[groupId] = isChecked;
        setSelectedGroups(newSelectedGroups);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (onSubmit) {
            onSubmit(selectedGroups);
        }
    };

    const checkDataChanged = () => {
        const dataChanged = !deepEqual(selectedGroups, convertGroupsToSelectedGroups(groups));
        setDataChanged(dataChanged);
        if (onChanged) {
            onChanged(dataChanged);
        }
    };

    return (
        <div className={`patient-groups-form ${className ?? ''}`}>
            {groups.length > 0 &&
                <form onSubmit={handleSubmit}>
                    <div className="fieldWrapper">
                        {groups.map(group => (
                            <div className="groupWrapper" key={group.groupId}>
                                <div className="checkboxFieldWrapper">
                                    <input type="checkbox"
                                        name="id"
                                        id={`group-id-${group.groupId}`}
                                        value={group.groupId}
                                        checked={selectedGroups[group.groupId] ?? false}
                                        onChange={handleChangeGroupSelection}
                                    />
                                    <label htmlFor={`group-id-${group.groupId}`}>{group.name}</label>
                                    <PatientGroupUsersTooltip title={`${group.name} - ${t(['Members', 'patientGroup.members'])}`} overlayPosition="bottomRight">
                                        <PatientGroupUsers groupId={group.groupId} />
                                    </PatientGroupUsersTooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="step-form-actions">
                        <Button className="button-create" loading={loading} disabled={!dataChanged && updateMode}>
                            {(updateMode) ? t(['Save', 'patientGroup.save']) : t(['Next', 'patientGroup.next'])}
                        </Button>
                    </div>
                </form>
            }
        </div>
    );
}

//language=SCSS
const PatientGroupsForm = Styled(PatientGroupsFormUnstyled)`
& {
    h3 {
        font-size: 15px;
    }
    
    .groupWrapper {
        position:relative;
        width: auto;
        max-width:300px;
        margin-bottom: 5px;
    }
}
`;

export { PatientGroupsForm };
