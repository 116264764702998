import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientHealthConditionLabel from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-label';
import {Button} from '@/components/buttons/button';
import {LongtextInput} from '@/components/form/input/longtext-input';

class PatientHealthConditionDetailsUpdateForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        loading: PropTypes.bool.isRequired,
        updateMode: PropTypes.bool.isRequired,
        healthConditionDetails: PropTypes.string.isRequired,
        healthConditionTypes: PropTypes.array.isRequired,
        healthConditionTypesSelected: PropTypes.array,
        onSubmit: PropTypes.func.isRequired,
        onChanged: PropTypes.func,
    };

    state = {
        dataChanged: false,
        data: {
            healthConditionDetails: this.props.healthConditionDetails ?? '',
            healthConditionTypesSelection: this.props.healthConditionTypesSelected || [],
        },
    };
    
    initialData = {
        /* Copied from the state in the constructor */
    };

    constructor(props) {
        super(props);
        autoBind(this);
        this.initialData = {
            ...this.state.data,
        };
    }

    isFilled() {
        const state = this.state;
        return (
            state.data.healthConditionDetails && state.data.healthConditionDetails !== ''
        );
    }
    
    checkDataChanged() {
        const dataChanged = !deepEqual(this.state.data, this.initialData);
        this.setState(state => ({
            ...state,
            dataChanged: dataChanged,
        }));
        if (this.props.onChanged) {
            this.props.onChanged(dataChanged);
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    <div className="fieldWrapper">
                        <label htmlFor="healthCondition-details" className={`mandatoryField ${(state.data.healthConditionDetails === '') ? 'empty' : 'filled'}`}>{t(['Health condition details', 'healthCondition.updateTitle'])}</label>
                        <LongtextInput name="note" id="healthCondition-details" required maxLength={150} disabled={props.loading} aria-required="true" autoFocus value={state.data.healthConditionDetails} onChange={this.handleChangeHealthConditionDetails} />
                    </div>
                    <h3>{t(['Health condition labels', 'healthCondition.labelsTitle'])}</h3>
                    <div className="fieldWrapper">
                        {props.healthConditionTypes.map(healthConditionType => (
                            <div key={healthConditionType} className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name={`healthConditionType_${healthConditionType.replace(/ /g, '_')}`}
                                    id={`healthConditionType_${healthConditionType.replace(/ /g, '_')}`}
                                    value={healthConditionType}
                                    checked={state.data.healthConditionTypesSelection.includes(healthConditionType)}
                                    disabled={props.loading}
                                    onChange={this.handleChangeHealthConditionType}
                                />
                                <label htmlFor={`healthConditionType_${healthConditionType}`}><PatientHealthConditionLabel healthConditionLabel={healthConditionType} /></label>
                            </div>
                        ))}
                    </div>
                    <div>
                        <Button className="button-create" disabled={!this.isFilled() || !state.dataChanged} loading={props.loading}>
                            {(props.updateMode) ? t(['Save health condition details', 'healthCondition.updateSave']) : t(['Next', 'patient.next'])}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeHealthConditionDetails(e) {
        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                healthConditionDetails: e.target.value,
            },
        }), function () {
            this.checkDataChanged();
        });
    }

    handleChangeHealthConditionType(e) {
        const state = this.state;
        let healthConditionTypeSelectionCopy = [...state.data.healthConditionTypesSelection];

        this.props.healthConditionTypes.forEach(healthConditionType => {
            if(healthConditionType === e.target.value) {
                if (e.target.checked) {
                    if (!healthConditionTypeSelectionCopy.includes(healthConditionType)) {
                        healthConditionTypeSelectionCopy.push(healthConditionType);
                    }
                }
                else {
                    if (healthConditionTypeSelectionCopy.includes(healthConditionType)) {
                        healthConditionTypeSelectionCopy = healthConditionTypeSelectionCopy.filter(x => x !== healthConditionType);
                    }
                }
            }
        });

        this.setState(state => ({
            ...state,
            data: {
                ...state.data,
                healthConditionTypesSelection: healthConditionTypeSelectionCopy,
            },
        }), function () {
            this.checkDataChanged();
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.props.onSubmit) {
            this.props.onSubmit(
                this.state.data.healthConditionDetails,
                this.state.data.healthConditionTypesSelection
            );
        }
        if (this.props.onChanged) {
            this.props.onChanged(false);
        }
    }
}

//language=SCSS
PatientHealthConditionDetailsUpdateForm = Styled(PatientHealthConditionDetailsUpdateForm)`
& {
    h3 {
        font-size:15px !important;
    }
}
`;

PatientHealthConditionDetailsUpdateForm = withTranslation()(PatientHealthConditionDetailsUpdateForm);
export {PatientHealthConditionDetailsUpdateForm};
