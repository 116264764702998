import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import errorIcon from '@/public/icons/error-icon.svg';
import successIcon from '@/public/icons/success-icon.svg';
import {useFeedbackAlerts, FeedbackAlert, FeedbackAlertType, FeedbackAlertsService} from '@/services/feedback-alerts-service';
import {colorPalette, zIndices} from '@/themes/darkmode';

function FeedbackAlerts() {
    const { t, i18n } = useTranslation();
    const feedbackAlerts = useFeedbackAlerts();

    const handleCloseAlert = (alert: FeedbackAlert) => {
        FeedbackAlertsService.remove(alert.id);
    };

    return (
        <StyledWrapper>
            <div className="content">
                {feedbackAlerts.map(alert => (
                    <div key={alert.id} className={`alert-wrapper alert-type-${alert.type}`}>
                        {alert.type === FeedbackAlertType.ERROR &&
                            <button className="alert-close" onClick={() => handleCloseAlert(alert)}>&times;</button>
                        }
                        {alert.type === FeedbackAlertType.ERROR &&
                            <div>
                                {i18n.exists(`errors.${alert.apiFunction}.${alert.code}`) &&
                                    <span>
                                        {t(['Error:', 'global.error'])}&nbsp;{t([`Error: ${alert.apiFunction} ${alert.code}`, `errors.${alert.apiFunction}.${alert.code}`])}
                                        <strong>&nbsp;[{alert.code}]</strong>
                                    </span>
                                }
                                {!i18n.exists(`errors.${alert.apiFunction}.${alert.code}`) &&
                                    <span>
                                        {i18n.exists(`errors.${alert.apiFunction}.error`) &&
                                            <span>{t(['Error:', 'global.error'])}&nbsp;{t([`Api function error: ${alert.apiFunction}`, `errors.${alert.apiFunction}.error`])} - {t([`Error type: ${(alert.code ?? '').substring(0, 1)}`, `errors.errorType.${(alert.code ?? '').substring(0, 1)}`])}<br /></span>
                                        }
                                        {t(['Server response:', 'errors.serverResponse'])}&nbsp;{alert.message}
                                        <strong>&nbsp;[{alert.code}]</strong>
                                    </span>
                                }
                                <p>
                                    {t(['If this problem persists, please contact', 'global.errorContact'])}&nbsp;
                                    <a className="alert-link" href={`mailto:support@gabismartcare.com?subject=Gabi Analytics - An error occurred - ${alert.code}&body=${alert.code}`}>support@gabismartcare.com</a>
                                </p>
                            </div>
                        }
                        {alert.type === FeedbackAlertType.SUCCESS &&
                            <div>
                                <span>{alert.message}</span>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </StyledWrapper>
    );
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    position: fixed;
    z-index: ${zIndices.feedbackAlerts};
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    pointer-events: none;

    .content {
        position: relative;
        padding: 0 50px;
        text-align: center;
        pointer-events: none;
    }

    .alert-wrapper  {
        pointer-events: all;
        padding: 10px 10px 10px 60px;
        background-size: 25px;
        margin: 0 auto;
        text-align:left;
        background-repeat: no-repeat;
        background-position: 20px center;
        width: 800px;
        position:relative;
        
        &:last-child {
            border-radius: 0 0 5px 5px;
        }

        &.alert-type-1 {
            padding: 15px 10px 15px 60px;
            background-color:${colorPalette.success};
            background-image: url(${successIcon});
            
            &:not(:last-child) {
                border-bottom: solid 1px ${colorPalette.success};
            }
        }
        
        &.alert-type-2 {
            background-color: ${colorPalette.danger};
            background-image: url(${errorIcon});
            
            &:not(:last-child) {
                border-bottom: solid 1px ${colorPalette.dangerDark};
            }
        }

        .alert-close {
            border:none;
            background:none;
            width:40px;
            height:40px;
            position:absolute;
            right: 5px;
            top: 5px;
            color: ${colorPalette.frontColor};
            font-size: 25px;
            cursor: pointer;
            &:hover {
                outline: none;
                color:${colorPalette.dangerFrontClear};
            }
        }
        
        p {
            margin-bottom: 0;
        }
        
        a {
            color: ${colorPalette.dangerFrontClear};
            text-decoration: underline;
        }
    }

    @media print {
        display: none;
    }
}
`;

export {FeedbackAlerts};
