import PropTypes from 'prop-types';
import React from 'react';

import {formatDateShortUniversal} from '@/util/date-utils';

class ChartAxisTickDate extends React.PureComponent {
    static propTypes = {
        x: PropTypes.number,
        y: PropTypes.number,
        payload: PropTypes.object,
        textAnchor: PropTypes.string,
    };

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={14} textAnchor={this.props.textAnchor || 'end'} fill="#fff" >
                    {formatDateShortUniversal(new Date(payload.value + ' 00:00:00'))}
                </text>
            </g>
        );
    }
}

export { ChartAxisTickDate };

