import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode';
import userChangelog from '@/user-changelog.json';
import {formatDateUniversal} from '@/util/date-utils';

type ChangelogProps = {
    className?: string;
    from?: Date | null;
    showViewFullChangelogLink?: boolean;
    onViewFullChangelogClick?: () => void;
}

type ChangeLogChange = string | {
    title: string;
    details: string[];
};

type ChangeLogJsonVersion = {
    version: string;
    releaseDate: string;
    link: string;
    changes: ChangeLogChange[];
}

function ChangelogUnstyled({className, from, showViewFullChangelogLink, onViewFullChangelogClick}: ChangelogProps) {
    const jsonChangelog = userChangelog.changelog;
    const { t } = useTranslation();
    const changelog: ChangeLogJsonVersion[] = jsonChangelog.filter(version => {
        if (!from) {
            return true;
        }
        else {
            return new Date(version.releaseDate).getTime() >= from.getTime();
        }
    });

    return (
        <div className={`changelog-view ${className || ''}`}>
            <h2>{t(['What\'s new?', 'changelog.title'])}</h2>

            {changelog.map((changelogEntry, versionIndex) => (
                <div key={`changelog-entry-${versionIndex}`} className="changelog-entry">
                    <h3>{changelogEntry.version} - {formatDateUniversal(new Date(changelogEntry.releaseDate))}</h3>

                    {changelogEntry.changes &&
                        <ul>
                            {changelogEntry.changes.map((item, changeIndex) => (
                                <li key={`version-${versionIndex}__change-${changeIndex}`}>
                                    {typeof(item) === 'object' ? item.title : item}
                                    {(typeof(item) === 'object' && item.details) && (
                                        <ul>
                                            {item.details.map((detail, detailIndex) => (
                                                <li key={`version-${versionIndex}__change-${changeIndex}__detail-${detailIndex}`}>
                                                    {detail}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    }

                    {changelogEntry.link && (
                        <div>
                            <a href={changelogEntry.link} target="_blank" rel="noreferrer">Official release notes ↗</a>
                        </div>
                    )}
                </div>
            ))}
            {showViewFullChangelogLink && (
                <Link to="/changelog" onClick={onViewFullChangelogClick}>
                    {t(['Full change log history', 'changelog.history'])}
                </Link>
            )}
        </div>
    );
}

//language=SCSS
const Changelog = Styled(ChangelogUnstyled)`
& {
    padding:25px;
    
    .changelog-entry {
        border-bottom: 1px solid ${colorPalette.thirdBackground};
        margin-bottom:15px;
    }
    
    h2 {
        font-size: 14px;
        border-bottom:1px solid ${colorPalette.activeColor};
        padding-bottom:3px;
    }
    
    h3 {
        font-weight: bold;
        font-size:14px;
        margin:20px 0;
    }
    
    h4 {
        text-transform: uppercase;
        padding:5px 10px;
        border-radius:5px;
        font-size:11px;
        display:inline-block;
        
        &.changelog-title-fixed {
           background: #4DC4EA;
        }
        &.changelog-title-improved {
           background: #00BFB2;
        }
        &.changelog-title-new {
           background: #29C785;
        }
    }
    
    ul {
        padding-left:30px;
        li {
            list-style-type: circle;
        }
    }
}`;

export {Changelog};
