import {useTranslation} from 'react-i18next';

import InfoTooltip from '@/components/buttons/info-tooltip';

function NoDataCell() {
    const { t } = useTranslation();
    return (<>
        <em>{t(['No data', 'pages.patients.noValue'])}</em>
        <InfoTooltip className="noDataTooltip" title={t('pages.patients.noValue')} overlayPosition={'bottomLeft'}>
            <p>{t('pages.patients.noValueText')}</p>
        </InfoTooltip>
    </>);
}

export {NoDataCell};
