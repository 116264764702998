/* eslint-disable @typescript-eslint/no-explicit-any */

import type {RpcOptions, UnaryCall} from '@protobuf-ts/runtime-rpc';
import type {MethodInfo} from '@protobuf-ts/runtime-rpc/build/types/reflection-info';
import {
    NextServerStreamingFn,
    NextUnaryFn,
    RpcInterceptor
} from '@protobuf-ts/runtime-rpc/build/types/rpc-interceptor';
import type {ServerStreamingCall} from '@protobuf-ts/runtime-rpc/build/types/server-streaming-call';
import {MessageType} from '@protobuf-ts/runtime/build/types/message-type';

/**
 * If the provided object is a Message from GRPC, use its toJson() function
 * Otherwise, use JSON.parse(JSON.stringify()) to make sure it's a flat serializable object.
 */
function grpcObjectToJson(data: MessageType<object> | object): object {
    return (data as MessageType<Request>).toJson ?
        (data as MessageType<Request>).toJson(data as Request) :
        JSON.parse(JSON.stringify(data));
}

class GPRCDevToolsInterceptor implements RpcInterceptor {
    interceptUnary(next: NextUnaryFn, method: MethodInfo, input: object, options: RpcOptions): UnaryCall {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = 'unary';
        console.debug('interceptUnary', methodFullPath, input);

        call.then(
            (finishedUnaryCall: { request: any; response: any }) => {
                const data = {
                    type: '__GRPCWEB_DEVTOOLS__',
                    methodType,
                    method: methodFullPath,
                    request: grpcObjectToJson(finishedUnaryCall.request),
                    response: grpcObjectToJson(finishedUnaryCall.response),
                };
                window.postMessage(data, '*');

                return finishedUnaryCall;
            },
            (error: any) => {
                window.postMessage(
                    {
                        type: '__GRPCWEB_DEVTOOLS__',
                        method: methodFullPath,
                        methodType,
                        request: grpcObjectToJson(call.request),
                        error: {
                            ...error,
                            message: error.message,
                        },
                    },
                    '*'
                );
            }
        );

        return call;
    }

    interceptServerStreaming(next: NextServerStreamingFn, method: MethodInfo, input: object, options: RpcOptions): ServerStreamingCall {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = 'server_streaming';
        console.debug('interceptServerStreaming', methodFullPath, input);

        window.postMessage({
            type: '__GRPCWEB_DEVTOOLS__',
            method: methodFullPath,
            methodType,
            request: call.request,
        });

        call.responses.onMessage((message: any) => {
            window.postMessage(
                {
                    type: '__GRPCWEB_DEVTOOLS__',
                    method: methodFullPath,
                    methodType,
                    response: message,
                },
                '*'
            );
        });

        call.responses.onError((error: { message: any }) => {
            window.postMessage(
                {
                    type: '__GRPCWEB_DEVTOOLS__',
                    method: methodFullPath,
                    methodType,
                    error: {
                        ...error,
                        message: error.message,
                    },
                },
                '*'
            );
        });

        call.responses.onComplete(() => {
            window.postMessage(
                {
                    type: '__GRPCWEB_DEVTOOLS__',
                    method: methodFullPath,
                    methodType,
                    response: 'EOF',
                },
                '*'
            );
        });

        return call;
    }
}

export const grpcDevToolsInterceptors = [
    new GPRCDevToolsInterceptor(),
];
