import PropTypes from 'prop-types';
import React from 'react';
import Styled from 'styled-components';

import ErrorBoundary from '@/components/errors/error-boundary';
import {Changelog} from '@/components/widgets/changelog';
import {Page} from '@/decorators/page';
import {colorPalette} from '@/themes/darkmode';

function wrapWithPage(Component) {
    return Page({
        name: 'Changelog'
    })(Component);
}

class ChangelogPage extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <Changelog />
                </ErrorBoundary>
            </div>
        );
    }
}

//language=SCSS
ChangelogPage = Styled(ChangelogPage)`
& {
    min-height: 500px;
    width: 600px;
    height: calc(100% - 80px);
    margin: 120px auto 0 auto;
    
    .changelog-entry {
        padding:20px;
        background: ${colorPalette.secondBackground};
        border-radius:5px;
        border:none;
        margin:20px 0;
        h3 {
            margin-top:10px;
        }
    }
}
`;

ChangelogPage = wrapWithPage(ChangelogPage);

export { ChangelogPage };
