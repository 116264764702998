import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {Button} from '@/components/buttons/button.jsx';
import magicIcon from '@/public/icons/magic-icon.svg';
import {BackendApiService} from '@/services/backend-api-service.js';
import {colorPalette} from '@/themes/darkmode.js';

class GroupCreate extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onCreated: PropTypes.func,
        onCancelled: PropTypes.func
    };

    state = {
        name: '',
        institution: '',
        service: '',
        displayHelpFields: false,
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    isFilled() {
        const state = this.state;
        return (
            state.name && state.name !== ''
        );
    }

    render() {
        const props = this.props;
        const state = this.state;

        const { t } = this.props;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    {state.displayHelpFields &&
                        <div className="helpFieldsWrapper">
                            <h4>{t(['Help us find your group\'s name', 'createGroup.help.title'])}</h4>
                            <div className="fieldWrapper">
                                <label htmlFor="createGroup-institution">{t(['What is your institution\'s name?', 'createGroup.help.institution'])}</label>
                                <input name="name" id="createGroup-institution" value={this.state.institution} onChange={this.handleChangeInstitution}/>
                            </div>
                            <div className="fieldWrapper">
                                <label htmlFor="createGroup-service">{t(['What is your service\'s name?', 'createGroup.help.service'])}</label>
                                <input name="name" id="createGroup-service" value={this.state.service} onChange={this.handleChangeService} />
                            </div>
                        </div>
                    }
                    <div className="fieldWrapper">
                        <label htmlFor="createGroup-name" className={`mandatoryField ${(this.state.name === '') ? 'empty' : 'filled'}`}>{t(['Name of the group', 'createGroup.group'])}</label>
                        <input name="name" id="createGroup-name" value={this.state.name} required aria-required="true" autoFocus onChange={this.handleChangeName} />
                        <button className="group-magic-button" type="button" onClick={this.handleClickHelp} title={t(['Help me find my group\'s name', 'createGroup.help.group'])} />
                    </div>
                    <div>
                        <Button className="button-create" disabled={!this.isFilled()} loading={state.loading}>{t(['Create group', 'createGroup.confirm'])}</Button>
                        <Button className="button-cancel" onClick={this.handleCancel}>{t(['Cancel', 'createGroup.cancel'])}</Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeName(e) {
        this.setState(state => ({
            ...state,
            name: e.target.value,
        }));
    }

    handleChangeInstitution(e) {
        this.setState(state => ({
            ...state,
            institution: e.target.value,
            name: `${e.target.value}_${this.state.service}`,
        }));
    }

    handleChangeService(e) {
        this.setState(state => ({
            ...state,
            service: e.target.value,
            name: `${this.state.institution}_${e.target.value}`,
        }));
    }

    handleClickHelp() {
        this.setState(state => ({
            ...state,
            displayHelpFields: true,
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.createGroup();
    }

    handleCancel(e) {
        e.preventDefault();
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    async createGroup() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const group = await BackendApiService.getRequest({
                domain: 'group',
                modelName: 'createGroup',
                data: {
                    name: this.state.name
                }
            });

            this.setState(state => ({
                ...state,
                loading: false,
                name: '',
            }));
            if(this.props.onCreated) {
                this.props.onCreated(group);
            }
        }
        catch (err) {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

//language=SCSS
GroupCreate = Styled(GroupCreate)`
& {
    .helpFieldsWrapper {
        border: 1px dashed ${colorPalette.thirdBackground};
        padding: 15px;
        margin: 10px 0;
        h4 {
            font-size:16px;
        }
    }
    
    .group-magic-button {
        background: ${colorPalette.activeColor} url(${magicIcon}) no-repeat center;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        border: none;
        background-size: 18px;
        position: absolute;
        right: 5px;
        top: 38px;
    }
}
`;

export default withTranslation()(GroupCreate);
