import {
    Date as ApiDate,
} from 'gabi-api-ts/v2/signal/query/signal_query';

export function formatJSDateToApiDateTs(jsDate: Date): ApiDate {
    return {
        day: jsDate.getDate(),
        month: jsDate.getMonth() + 1,
        year: jsDate.getFullYear(),
    };
}

export function formatApiDateToJSDate(apiDate: ApiDate) {
    return new Date(apiDate.year, apiDate.month-1, apiDate.day);
}
