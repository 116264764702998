import PropTypes from 'prop-types';
import React from 'react';

import {colorPalette} from '@/themes/darkmode.js';

class ChartAxisTickDuration extends React.PureComponent {
    static propTypes = {
        x: PropTypes.number,
        y: PropTypes.number,
        payload: PropTypes.object,
        textAnchor: PropTypes.string,
    };

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={4} dx={2} textAnchor={this.props.textAnchor || 'end'} fill={colorPalette.clearColor} >
                    {payload.value}h
                </text>
            </g>
        );
    }
}

export { ChartAxisTickDuration };

