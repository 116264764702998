import PropTypes from 'prop-types';
import React from 'react';

import {formatHourTwoDigits} from '@/util/time-utils';

class ChartAxisTickHourTwoDigits extends React.Component {
    static propTypes = {
        x: PropTypes.number,
        y: PropTypes.number,
        payload: PropTypes.object,
        textAnchor: PropTypes.string,
    };

    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={14} textAnchor={this.props.textAnchor || 'end'} fill="#fff" >
                    {formatHourTwoDigits(payload.value)}
                </text>
            </g>
        );
    }
}

export { ChartAxisTickHourTwoDigits };

