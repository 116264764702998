import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import Styled from 'styled-components';

import useWindowSize from '@/hooks/use-window-size';
import moveIcon from '@/public/icons/move-horiz-icon.svg';
import {colorPalette} from '@/themes/darkmode';

const propTypes = {
    className: PropTypes.string,
    dataBlocks: PropTypes.array.isRequired,
    dataBlocksReliableSPO2: PropTypes.array.isRequired,
    dataBlocksReliableHR: PropTypes.array.isRequired,
    endTime: PropTypes.number.isRequired,
    medicalEvents: PropTypes.object.isRequired,
    startTime: PropTypes.number.isRequired,
    visibleEndTime: PropTypes.number.isRequired,
    visibleStartTime: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onScroll: PropTypes.func.isRequired,
};

function UnstyledTimelineScrollbar(props) {
    const [dragging, setDragging] = useState(false);
    const [_, triggerUpdate] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const scrollbarRef = useRef(null);
    const [windowWidth] = useWindowSize();
    //const visiblebarRef = React.createRef();

    const handleClick = (e) => {
        if (e.target.className !== 'medical-events-scroll__visible-block' && e.target.className !== 'medical-alerts-scroll__cursor' && scrollbarRef.current) {
            let cursorPosition = e.clientX - scrollbarRef.current.offsetLeft - 250;
            const chartLengthTime = props.endTime - props.startTime;
            const scrollbarWidth = scrollbarRef.current.offsetWidth;
            const scrollbarVisibleWindowWidth = document.getElementsByClassName('medical-events-scroll__visible-block')[0].offsetWidth;

            // Keep cursor inside the scrollbar
            if (cursorPosition > (scrollbarWidth - scrollbarVisibleWindowWidth / 2)) {
                cursorPosition = scrollbarWidth - scrollbarVisibleWindowWidth / 2;
            }

            const cursorPositionTime = (cursorPosition - 0.5*scrollbarVisibleWindowWidth)/scrollbarWidth * chartLengthTime;

            props.onClick(cursorPositionTime);
        }
    };
    
    const handleWheel = (event) => {
        const wheelDelta = (event.shiftKey) ? event.deltaY : event.deltaX;
        const deltaX = wheelDelta / 10;
        if (deltaX !== 0) {
            doScroll(deltaX);
        }
    };
    
    const handleMouseMove = (/*MouseEvent*/ e) => {
        if (dragging) {
            const deltaX = e.movementX * (scrollbarRef.current.offsetWidth / windowWidth);
            doScroll(deltaX);
        }
    };
    
    const doScroll = (deltaX) => {
        const chartLengthTime = props.endTime - props.startTime;
        const scrollbarWidth = scrollbarRef.current.offsetWidth;
        const deltaTime = deltaX/scrollbarWidth * chartLengthTime;
        props.onScroll(deltaTime);
        triggerUpdate(Date.now);
    };
    
    const handleDragStart = (scrollBarWidth) => {
        if (scrollBarWidth < 100) {
            setDragging(true);
        }
    };
    
    const handleMouseUp = () => {
        setDragging(false);
    };
    
    // useEffect(() => {
    //     console.log('>>>> TimelineScrollbar <<<<');
    //     console.log(props);
    // }, []);
    
    useEffect(() => {
        if (dragging) {
            document.body.addEventListener('mousemove', handleMouseMove);
            document.body.addEventListener('mouseup', handleMouseUp);
            return () => {
                document.body.removeEventListener('mousemove', handleMouseMove);
                document.body.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [dragging]);
    
    useEffect(() => {
        document.addEventListener('wheel', handleWheel, { passive: false });
        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, []);
    
    const chartLengthTime = props.endTime - props.startTime;
    const visibleWidth = (100*(props.visibleEndTime-props.visibleStartTime)/chartLengthTime).toFixed(1);
    const scrollBarCursorWidth = (visibleWidth < 100) ? visibleWidth : 100;
    let cursorLeftPosition = (100*(props.visibleStartTime-props.startTime)/chartLengthTime).toFixed(1);
    if (props.visibleStartTime < props.startTime || visibleWidth >= 100) {
        cursorLeftPosition = 0;
    }
    else if (cursorLeftPosition > (100 - visibleWidth)) {
        cursorLeftPosition = 100 - visibleWidth;
    }

    return (
        <div ref={scrollbarRef} className={props.className} onClick={handleClick}>
            {props.dataBlocks.map(block => {
                return (
                    <div key={block.start} className="medical-events-scroll__data-block" style={{
                        left: `${(100*(block.start-props.startTime)/chartLengthTime).toFixed(1)}%`,
                        width: `${(100*(block.end-block.start)/chartLengthTime).toFixed(1)}%`,
                    }}/>
                );
            })}
            {props.medicalEvents.hr.map(block => {
                return (
                    <TimelineScrollbarMedicalEvent
                        key={block.fromSecondsSince12pm}
                        chartLengthTime={chartLengthTime}
                        color={colorPalette.signalType.pulseRate}
                        eventBlock={block}
                        startTime={props.startTime}
                        top={0}
                    />
                );
            })}
            {props.medicalEvents.spo2.map(block => {
                return (
                    <TimelineScrollbarMedicalEvent
                        key={block.fromSecondsSince12pm}
                        chartLengthTime={chartLengthTime}
                        color={colorPalette.signalType.spo2}
                        eventBlock={block}
                        startTime={props.startTime}
                        top={50}
                    />
                );
            })}
            {props.dataBlocksReliableSPO2.map(block => {
                return (
                    <div key={block.start} className="medical-events-scroll__data-block-reliable-spo2" style={{
                        left: `${(100*(block.start-props.startTime)/chartLengthTime).toFixed(1)}%`,
                        width: `${(100*(block.end-block.start)/chartLengthTime).toFixed(1)}%`,
                    }}/>
                );
            })}
            {props.dataBlocksReliableHR.map(block => {
                return (
                    <div key={block.start} className="medical-events-scroll__data-block-reliable-hr" style={{
                        left: `${(100*(block.start-props.startTime)/chartLengthTime).toFixed(1)}%`,
                        width: `${(100*(block.end-block.start)/chartLengthTime).toFixed(1)}%`,
                    }}/>
                );
            })}
            <div className="medical-events-scroll__visible-block" style={{
                'left': `${cursorLeftPosition}%`,
                'width': `${scrollBarCursorWidth}%`,
            }} onMouseDown={() => handleDragStart(scrollBarCursorWidth)}>
                <button className="medical-events-scroll__cursor">Move</button>
            </div>
        </div>
    );
}

const TimelineScrollbarMedicalEventPropTypes = {
    chartLengthTime: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    eventBlock: PropTypes.object.isRequired,
    startTime: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
};
function TimelineScrollbarMedicalEvent({chartLengthTime, color, eventBlock, startTime, top}) {
    return (
        <div key={eventBlock.fromSecondsSince12pm} className="medical-events-scroll__event-block" style={{
            left: `${(100*(eventBlock.fromSecondsSince12pm - startTime)/chartLengthTime).toFixed(2)}%`,
            top: `${top}%`,
            minWidth: '1px',
            width: `${(100*(eventBlock.toSecondsSince12pm - eventBlock.fromSecondsSince12pm)/chartLengthTime).toFixed(2)}%`,
            background: `${color}`,
        }}/>
    );
}
TimelineScrollbarMedicalEvent.propTypes = TimelineScrollbarMedicalEventPropTypes;

UnstyledTimelineScrollbar.propTypes = propTypes;

//language=SCSS
const TimelineScrollbar = Styled(UnstyledTimelineScrollbar)`
& {
    position: absolute;
    width: calc(100% - 116px);
    left:108px;
    height: 28px;
    background: ${colorPalette.secondBackground};
    user-select: none;

    .medical-events-scroll__cursor {
        width: 40px;
        background: ${colorPalette.activeColor} url(${moveIcon}) no-repeat center 6px;
        height: 23px;
        text-indent: -10000px;
        color: transparent;
        position: absolute;
        left: calc(50% - 20px);
        top: 26px;
        border: none;
        background-size: 18px;
        border-radius: 1px 1px 20px 20px;
        outline: none;
        cursor: grab;
    }
    
    .medical-events-scroll__data-block {
        position: absolute;
        height: 100%;
        background: ${colorPalette.thirdBackground};
    }
    
    .medical-events-scroll__data-block {
        position: absolute;
        height: 100%;
        background: ${colorPalette.thirdBackground};
    }
    
    .medical-events-scroll__data-block-reliable-spo2 {
        position: absolute;
        bottom: 0;
        height: 2.4px;
        background: ${colorPalette.signalTypeLight.spo2};
        min-width: 1px;
    }
    
    .medical-events-scroll__data-block-reliable-hr {
        position: absolute;
        top: 0;
        height: 2.4px;
        background: ${colorPalette.signalTypeLight.pulseRate};
        min-width: 1px;
    }
    
    .medical-events-scroll__event-block {
        position: absolute;
        height: 50%;
        background: #a6c7da;
        opacity: 0.8;
    }
    
    .medical-events-scroll__visible-block {
        position: absolute;
        height: 100%;
        top: 0;
        background: rgba(107,199,253, 0.2);
        border: solid 3px ${colorPalette.activeColor};
        border-top-width: 1px;
        border-bottom-width: 1px;
        cursor: grab;
        border-radius:0;
        z-index: 20;
    }
}
`;

export { TimelineScrollbar };
