import {HcpAddition} from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {HcpCreationServiceClient} from 'gabi-api-ts/v2/hcp/creation/hcp_creation.client';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import {HcpPersonalDataForm} from '@/components/business/analytics/hcp/hcp-personal-data-form';
import ErrorBoundary from '@/components/errors/error-boundary';
import {ErrorMessage} from '@/components/form/error-message';
import {FlexChild, FlexLayout } from '@/components/layout/flex-layout';
import { AppLogoSmall } from '@/components/static/app-logo-small';
import doctors from '@/public/images/doctors.svg';
import {grpcRequest} from '@/services/api-requests/grpc-request-ts';
import {colorPalette} from '@/themes/darkmode';

type HcpCreationProps = {
    className?: string,
    onCreated: () => void,
};
function HcpCreationUnstyled(props: HcpCreationProps){
    const { t } = useTranslation();
    const [isCreating, setIsCreating] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    async function createHcp(hcp: HcpAddition) {
        setIsCreating(true);

        try {
            await grpcRequest(
                '',
                HcpCreationServiceClient,
                HcpCreationServiceClient.prototype.add,
                hcp,
            );
            setIsCreating(false);
            if (props.onCreated) {
                props.onCreated();
            }
            return hcp;
        }
        catch (err) {
            console.error(err);
            setIsCreating(false);
            setErrorMessage((err as {message?: string}).message ?? t('hcpCreation.api.createHcpError'));
        }
    }

    return (
        <div className={`hcp-creation-page ${props.className ?? ''}`}>
            <ErrorBoundary>
                <FlexLayout direction="row">
                    <FlexChild grow={1} className="create-hcp-illustration">
                        <div className="create-hcp-header">
                            <AppLogoSmall />
                            <h1>{t('hcpCreation.title') ?? 'Create account'}</h1>
                        </div>
                        <img src={doctors} width={350} alt={t('hcpCreation.doctorsAlt') ?? ''} />
                    </FlexChild>
                    <FlexChild grow={1} className="create-hcp-form">
                        {errorMessage && (
                            <ErrorMessage text={t(['Cannot create account', 'hcpCreation.error'])} errorMessage={errorMessage} />
                        )}
                        <HcpPersonalDataForm
                            creationMode={true}
                            submitting={isCreating}
                            onSubmit={(hcp) => createHcp(hcp)}
                        />
                    </FlexChild>
                </FlexLayout>
                {/*<HcpCreationForm loading={isCreating} errorMessage={errorMessage || ''} onSubmit={createHcp} />*/}
            </ErrorBoundary>
        </div>
    );
}

//language=SCSS
const HcpCreation = Styled(HcpCreationUnstyled)`
& {
    .create-hcp-illustration {
        width: 400px;
        padding-right: 40px;
        > img {
            margin-top: 70px;
        }
    }
    
    .create-hcp-header {
        display:flex;
        margin-bottom: 20px;
        img {
            margin-right: 20px;
        }
        h1 {
            margin-top: 10px;
        }
    }
    
    .create-hcp-form {
        width: 400px;
        h3 {
            font-size: 16px;
            margin-top: 30px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 40px;
            padding-top: 7px;
            span {
                display: block;
                padding: 6px 5px;
                color: ${colorPalette.frontColor};
                border-radius: 50px;
                width: 30px;
                height: 30px;
                text-align: center;
                background-color: ${colorPalette.activeColor};
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .programTypeWrapper > div {
            margin-left: 10px;
        }
        
        label a {
            color: #fff;
            text-decoration: underline;
        }
    }
}
`;

export {HcpCreation};
