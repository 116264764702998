import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {PatientHealthConditionDetailsUpdateForm} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-details-update-form';
import {InfoMessage} from '@/components/form/info-message';
import LoadingView from '@/components/static/loading-view';
import {BackendApiService} from '@/services/backend-api-service';

class PatientCreationHealthCondition extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientId: PropTypes.string.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        healthConditionTypes: null,
    };
    
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.listHealthConditionTypes();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Patient health condition', 'createHealthCondition.title'])}</h3>

                <InfoMessage infoMessage={t(['Child information were successfully saved!', 'createHealthCondition.info'])} text={t(['Please describe the patient\'s health condition.', 'createHealthCondition.infoText'])} />

                {!state.loading && state.healthConditionTypes &&
                    <PatientHealthConditionDetailsUpdateForm
                        updateMode={false}
                        healthConditionDetails={''}
                        healthConditionTypes={state.healthConditionTypes}
                        loading={state.loading}
                        onSubmit={this.saveHealthConditionDetails}
                    />
                }
                {state.loading &&
                    <LoadingView/>
                }
            </div>
        );
    }

    async saveHealthConditionDetails(healthConditionDetails, healthConditionTypesSelection) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        try {
            await BackendApiService.getRequest({
                domain: 'patient',
                modelName: 'updateHealthConditionDetails',
                data: {
                    patientId: this.props.patientId,
                    healthConditionDetails: healthConditionDetails
                }
            });
            await this.updateHealthConditionTypes(healthConditionTypesSelection);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            this.props.onSaved();
        }
        catch (e) {
            console.error(e);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }

    updateHealthConditionTypes(healthConditionTypesSelection) {
        return Promise.all(healthConditionTypesSelection.map(healthConditionType => this.addHealthConditionType(healthConditionType)));
    }
    
    addHealthConditionType(healthConditionType){
        return BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'addHealthCondition',
            data: {
                patientId: this.props.patientId,
                healthCondition: healthConditionType
            }
        });
    }

    listHealthConditionTypes() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'listHealthConditionTypes',
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
                healthConditionTypes: response.healthConditionTypesList,
            }));
            return response;
        }).catch(err => {
            console.error(err);
        });
    }
}

export default withTranslation()(PatientCreationHealthCondition);

