import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import ReactLoader from 'react-loader-component';

import LoadingView from '@/components/static/loading-view.tsx';
import Err403Page from '@/pages/err403';
import Err404Page from '@/pages/err404';
import { BackendApiService } from '@/services/backend-api-service';

const delay = 300; //ms
class DeferredLoadingView extends React.Component {
    timeout = null;
    
    constructor(props) {
        super(props);
        this.state = {
            display: false,
        };
    }
    
    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.setState({display: true});
            this.timeout = null;
        }, delay);
    }
    
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    
    render() {
        if (this.state.display) {
            return (
                <LoadingView className="fullSize" />
            );
        }
        else {
            return <div style={{minHeight: 110}}/>;
        }
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.state, nextState);
    }
}

class DefaultErrorComponent extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        path: PropTypes.array,
        loaderData: PropTypes.object,
        loaderError: PropTypes.object,
    };
    
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        const props = this.props;
        const loaderData = props.loaderError;
        if (loaderData.status === 401) {
            //Looks like the user has no access anymore. Check that by calling fetchMe()
            //If access is lost, it will show the login form
            BackendApiService.fetchMe();
        }
    }
    
    render() {
        const props = this.props;
        const loaderData = props.loaderData;
        if (loaderData && loaderData.status === 404) {
            return (
                <Err404Page/>
            );
        }
        else if (loaderData && loaderData.status === 403) {
            return (
                <Err403Page/>
            );
        }
        else {
            return (
                <div>
                    <h1 className="text-center">Ooops, something went wrong.</h1>
                    <p className="text-center">We cannot load the page you requested. Sorry for the inconvenience.</p>
                    <pre style={{display:'none'}}>{JSON.stringify(props, null, '  ')}</pre>
                </div>
            );
        }
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

/**
 * @param options - example : {
 *  errorComponent: MyErrorComponent,
 *  loadingComponent: MyLoadingComponent,
 *  models: [
 *    {name: 'users'},
 *    {name: 'company', id: 42},
 *    {name: 'company', id: 42, disabled: true}, //to disable this loading for some reason
 *    {name: 'company', id: 42, allowError: true}, //to always load successfully, even if the promise fails
 *    {name: 'company', id: 42, cached: false}, //force the loader to reload data if in cache
 *    {name: 'company', id: 42, transform: (data) => { return doSomething(data); } }, //transform the returned data
 *  ],
 * }
 */
export function Loader(options) {
    return function(Component) {
        const models = options.models;
    
        //Connect directly with redux using the `models` option
        const modelKeys = Object.keys(models);
        
        const LoaderWrapper = (props) => {
            const forwardProps = {
                ...props
            };
            delete forwardProps.loaderData;
    
            modelKeys.map((propName, index) => {
                // eslint-disable-next-line react/prop-types
                forwardProps[propName] = props.loaderData[index];
            });
            
            return (
                <Component {...forwardProps} />
                //<div className="scroll-area">
                //    <pre>{JSON.stringify(forwardProps, null, '  ')}</pre>
                //</div>
            );
        };
        
        function applyModel(model, props) {
            if (typeof (model) === 'function') {
                return model(props);
            }
            else {
                return Object.assign({}, model);
            }
        }
    
        const loaderOptions = {
            models: modelKeys,
            errorComponent: options.errorComponent || DefaultErrorComponent,
            loadingComponent: options.loadingComponent || DeferredLoadingView,
            resultProp: 'loaderData',
            errorProp: 'loaderError',
            shouldComponentReload: options.shouldComponentReload || ((props, nextProps) => {
                return null != modelKeys.find(propName => {
                    let curModel  = applyModel(models[propName], props);
                    delete curModel.transform;
                    let nextModel = applyModel(models[propName], nextProps);
                    delete nextModel.transform;
                    return !deepEqual(curModel, nextModel);
                });
            }),
            load: (props) => {
                // Loop through all the store names required and request the data
                const promises = modelKeys.map(propName => {
                    let model = applyModel(models[propName], props);
    
                    modelKeys.forEach(key => {
                        if (key !== 'name' && key !== 'transform') {
                            model[key] = typeof(model[key]) === 'function' ? model[key](props) : model[key];
                        }
                    });
                
                    const modelName = model.name;
                    const domainName = model.domain;
                    let out;
                    if (model.disabled) {
                        out = Promise.resolve(); //noop
                    }
                    else if (model.id) {
                        out = BackendApiService.fetchOne(modelName, domainName, model.id, {
                            ...model
                        });
                    }
                    else {
                        out = BackendApiService.fetchAll(modelName, domainName, model.filter, {
                            ...model
                        });
                    }
    
                    if (model.transform) {
                        out = out.then(model.transform);
                    }
                    
                    if (model.allowError) {
                        out = out.catch(e => {
                            return e;
                        });
                    }
                    else {
                        out = out.catch(e => {
                            console.error(e);
                            throw e;
                        });
                    }
                    
                    return out;
                });
                return Promise.all(promises);
            },
        };
    
        return ReactLoader(loaderOptions)(LoaderWrapper);
    };
}
