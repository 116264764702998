function formatHourTwoDigits(time: number | string): string | undefined {
    if (time !== undefined) {
        const timeString = time.toString();

        if (timeString.length === 1) {
            return `0${timeString}h`;
        }
        else {
            return `${timeString}h`;
        }
    }
}

function formatTimeFragmentTwoDigits(time: number | string): string | undefined {
    if (time !== undefined) {
        const timeString = time.toString();

        if (timeString.length === 1) {
            return `0${timeString}`;
        }
        else {
            return `${timeString}`;
        }
    }
}

/**
 * Round a duration in hours with the following rules :
 * - if < 15min : 15min
 * - else : round to 0.25 precision
 */
function roundDurationHoursToQuarterPrecision(durationInSeconds: number): number {
    if (durationInSeconds <= 15*60) {
        return 0.25;
    }
    else {
        return Math.round(4 * durationInSeconds / 60 / 60) / 4;
    }
}

/**
 * Checks that a time with format "HH:mm(:ss)" or "H:mm(:ss)" is a valid time
 * @param hourString
 */
function isHourStringValid(hourString: string): boolean {
    const hourRegex = /^([01]\d|2[0-3]|[0-9]):[0-5]\d(?::[0-5]\d)?$/;
    return hourRegex.test(hourString);
}

export {formatHourTwoDigits, formatTimeFragmentTwoDigits, roundDurationHoursToQuarterPrecision, isHourStringValid};
