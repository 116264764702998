import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {PatientGroupsForm} from '@/components/business/analytics/patient/common/patient-groups-form';
import {Button} from '@/components/buttons/button.jsx';
import {InfoMessage} from '@/components/form/info-message.jsx';
import LoadingView from '@/components/static/loading-view.tsx';
import {BackendApiService} from '@/services/backend-api-service.js';

class PatientCreationGroups extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patientId: PropTypes.string.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: false,
        groups: null,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.fetchGroups();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t(['Access groups', 'patientGroups.title'])}</h3>

                {!state.loading && state.groups && state.groups.length > 0 &&
                    <div className="groups-block">
                        <InfoMessage infoMessage={t(['Prescriber information were successfully saved!', 'patientGroups.info'])} text={t(['Please describe the patient\'s health condition.', 'patientGroups.infoText'])} />

                        <PatientGroupsForm
                            updateMode={false}
                            loading={state.loading}
                            groups={state.groups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                }
                {!state.loading && state.groups && state.groups.length === 0 &&
                    <div className="no-group-block">
                        <InfoMessage infoMessage={t(['No group available.', 'patientGroups.noGroup.info'])} text={t(['You must create a group first, and then update the patient to assign him to this group.', 'patientGroups.noGroup.infoText'])} />
                        <div className="step-form-actions">
                            <Button className="button-create" onClick={this.handleClickNoGroupNext}>{t(['Next', 'patientGroups.noGroup.next'])}</Button>
                        </div>
                    </div>
                }
                {state.loading &&
                    <LoadingView />
                }
            </div>
        );
    }

    handleClickNoGroupNext() {
        if(this.props.onSaved) {
            this.props.onSaved();
        }
    }

    async fetchGroups() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const groups = await BackendApiService.getRequest({
                domain: 'group',
                modelName: 'groups',
            });

            groups.groupList.forEach(group => {
                group.groupId = group.groupId.id;
                group.selected = false;
            });

            this.setState(state => ({
                ...state,
                groups: groups.groupList,
            }));

            this.setState(state => ({
                ...state,
                loading: false,
                groups: groups.groupList,
            }));
        }
        catch (e) {
            console.error(e);
        }
    }

    patientGroupGrantAccess(groupId) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patientPermission',
            modelName: 'patientGroupGrantAccess',
            data: {
                patientId: this.props.patientId,
                groupId: groupId,
            }
        }).then((response) => {
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return response;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    handleSubmit(selectedGroups) {
        const updatedGroups = this.state.groups.map(g => ({...g}));

        updatedGroups.forEach(group => {
            const isGroupSelected = selectedGroups[group.groupId];

            if(group.selected !== isGroupSelected) {
                group.selected = isGroupSelected;
                if(isGroupSelected) {
                    this.patientGroupGrantAccess(group.groupId);
                }
            }
        });

        this.setState(state => ({
            ...state,
            groups: updatedGroups,
        }));

        if(this.props.onSaved) {
            this.props.onSaved();
        }
    }
}

export default withTranslation()(PatientCreationGroups);

