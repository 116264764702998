import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientsProgramSelection from '@/components/business/analytics/patients/patients-program-selection.jsx';
import LoadingView from '@/components/static/loading-view.tsx';
import {HcpProgramEnum} from '@/enum/hcp-program-enum.jsx';
import {BackendApiService} from '@/services/backend-api-service.js';

class HcpUpdateConfiguration extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        hcp: PropTypes.object.isRequired,
        onSaved: PropTypes.func
    };

    state = {
        loading: true,
        program: HcpProgramEnum.RESEARCH,
    };

    componentDidMount() {
        this.fetchHcpProgram();
    }

    render() {
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={this.props.className}>
                <h3>{t(['Configuration', 'hcpUpdateConfiguration.title'])}</h3>

                <div className="program-configuration-wrapper">
                    <h4>{t(['Program selection', 'hcpUpdateConfiguration.program.title'])}</h4>

                    <p>{t(['Please select a program type, this will update the columns displayed in your active patients dashboard.', 'hcpUpdateConfiguration.program.text'])}</p>

                    {state.loading ?
                        <LoadingView/>
                        :
                        <PatientsProgramSelection program={state.program} onChanged={(selectedProgram) => this.updateHcpProgram(selectedProgram)}/>
                    }
                </div>
            </div>
        );
    }

    async fetchHcpProgram() {
        // Initialize program with HCP configuration
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'getProgramType',
        })
            .then((response) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    program: response.programType,
                }));
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    }

    async updateHcpProgram(program) {
        this.setState(state => ({
            ...state,
            loading: true,
            program: program,
        }));

        // Update program with HCP configuration
        BackendApiService.getRequest({
            domain: 'hcp',
            modelName: 'updateProgramType',
            data: {
                program: program
            }
        })
            .then((response) => {
                this.setState(state => ({
                    ...state,
                    loading: false,
                    program: program,
                }));
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    }
}

//language=SCSS
HcpUpdateConfiguration = Styled(HcpUpdateConfiguration)`
& {
    .program-configuration-wrapper {
        margin-bottom: 30px;
        > div {
            margin-left: 10px;
        }
    }
}
`;

export default withTranslation()(HcpUpdateConfiguration);

