/**
 * Interface extracted from node_modules/@react-native-firebase/app/lib/index.d.ts
 */
interface FirebaseAppOptions {
    /**
     * The Google App ID that is used to uniquely identify an instance of an app.
     */
    appId: string;

    /**
     * An API key used for authenticating requests from your app, e.g.
     * "AIzaSyDdVgKwhZl0sTTTLZ7iTmt1r3N2cJLnaDk", used to identify your app to Google servers.
     */
    apiKey?: string;

    /**
     * The database root URL, e.g. "http://abc-xyz-123.firebaseio.com".
     */
    databaseURL?: string;

    /**
     * The Project ID from the Firebase console, for example "abc-xyz-123".
     */
    projectId: string;

    /**
     * The tracking ID for Google Analytics, e.g. "UA-12345678-1", used to configure Google Analytics.
     */
    gaTrackingId?: string;

    /**
     * The Google Cloud Storage bucket name, e.g. "abc-xyz-123.storage.firebase.com".
     */
    storageBucket?: string;

    /**
     * The Project Number from the Google Developer's console, for example "012345678901", used to
     * configure Google Cloud Messaging.
     */
    messagingSenderId?: string;

    /**
     * iOS only - The OAuth2 client ID for iOS application used to authenticate Google users, for example
     * "12345.apps.googleusercontent.com", used for signing in with Google.
     */
    clientId?: string;

    /**
     * iOS only - The Android client ID used in Google AppInvite when an iOS app has its Android version, for
     * example "12345.apps.googleusercontent.com".
     */
    androidClientId?: string;

    /**
     * iOS only - The URL scheme used to set up Durable Deep Link service.
     */
    deepLinkURLScheme?: string;

    [name: string]: unknown;
}

class GabiBackendServiceClientConfig {
    readonly domain: string;
    readonly apiKey: string;

    constructor(domain: string, apiKey: string) {
        this.domain = domain;
        this.apiKey = apiKey;
    }
}

enum GabiEnv {
    not_set = '',
    development = 'development',
    acceptance = 'acceptance',
    production = 'production',
}

class GabiEnvData {
    constructor(
    public readonly name: string,
    public readonly backendConfig: GabiBackendServiceClientConfig,
    public readonly signalingServerUrl: string,
    public readonly firebaseConfig: FirebaseAppOptions,
    ) {
    }
}

const devApiKey = 'AIzaSyBsOVCiHP8asPl92Lx0hqTvXKXwMlm4Qi4';
const GabiEnvDevelopment = new GabiEnvData(
    GabiEnv.development,
    new GabiBackendServiceClientConfig('dev.api.gabismartcare.com', devApiKey),
    'https://signaling-server-m3ol5225qa-ew.a.run.app',
    {
        apiKey: devApiKey,
        authDomain: 'gsc-pediarity-dev.firebaseapp.com',
        projectId: 'gsc-pediarity-dev',
        storageBucket: 'gsc-pediarity-dev.appspot.com',
        messagingSenderId: '1035753187788',
        appId: '1:1035753187788:web:4e6dad3b7b725bb8639569',
    }
);

const accApiKey = 'AIzaSyCni8AH_fELafkeMxHljFHBCuBOGXlpun0';
const GabiEnvAcceptance = new GabiEnvData(
    GabiEnv.acceptance,
    new GabiBackendServiceClientConfig('acceptance.api.gabismartcare.com', accApiKey),
    'https://signaling-server-fvmud3qirq-ew.a.run.app',
    {
        apiKey: accApiKey,
        authDomain: 'gsc-pediarity-acc.firebaseapp.com',
        projectId: 'gsc-pediarity-acc',
        storageBucket: 'gsc-pediarity-acc.appspot.com',
        messagingSenderId: '262927445296',
        appId: '1:262927445296:web:8167294d51e0d65a6f2cf5',
    }
);

const prodApiKey = 'AIzaSyB2Wab78WrONllG-F69xBQg6NqrnPdAaLE';
const GabiEnvProduction = new GabiEnvData(
    GabiEnv.production,
    new GabiBackendServiceClientConfig('api.gabismartcare.com', prodApiKey),
    'https://signaling-server-qn7o4bf27a-ew.a.run.app',
    {
        apiKey: prodApiKey,
        authDomain: 'gsc-pediarity-prod.firebaseapp.com',
        projectId: 'gsc-pediarity-prod',
        storageBucket: 'gsc-pediarity-prod.appspot.com',
        messagingSenderId: '971130836252',
        appId: '1:971130836252:web:13928a67e9a09a3b0056d9'
    }
);

export {
    GabiEnv, GabiEnvData,
    GabiEnvDevelopment, GabiEnvAcceptance, GabiEnvProduction,
};
