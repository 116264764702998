import {withAuth0} from '@auth0/auth0-react';
import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import ReactRouterPrompt from 'react-router-prompt';
import Styled from 'styled-components';

import {PatientUpdateCaregiver} from '@/components/business/analytics/patient/patient-update/patient-update-caregiver';
import PatientUpdateChild from '@/components/business/analytics/patient/patient-update/patient-update-child';
import {PatientUpdateEventConfiguration} from '@/components/business/analytics/patient/patient-update/patient-update-event-configuration';
import {PatientUpdateGroups} from '@/components/business/analytics/patient/patient-update/patient-update-groups';
import PatientUpdateHealthCondition from '@/components/business/analytics/patient/patient-update/patient-update-health-condition';
import {PatientUpdatePrescriber} from '@/components/business/analytics/patient/patient-update/patient-update-prescriber';
import {Button} from '@/components/buttons/button';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Modal} from '@/components/layout/modal';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {withBackendQuery} from '@/decorators/with-backend-query';
import withRouter from '@/decorators/withRouter';
import {usePatientData} from '@/hooks/backend/use-patient-data';

function getPagePath(props) {
    const pagePath = [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];

    if (props.params.returnTo === 'healthReport' || props.params.returnTo === 'timeline') {
        pagePath.push({
            route: `/patients/${props.params.id_patient}/healthReport`,
            name: 'Health report',
        });
    }

    if (props.params.returnTo === 'timeline' && props.params.returnToDate) {
        pagePath.push({
            route: `/patients/${props.params.id_patient}/timeline/${props.params.returnToDate}`,
            name: 'Daily biometrics',
        });
    }

    return pagePath;
}

function wrapWithPage(Component) {
    return Page({
        name: 'Update patient',
        pagePath: props => (
            getPagePath(props)
        )
    })(Component);
}

function withPatientData(Component) {
    return withBackendQuery('patientLoading', 'patient', 'patientError', props => usePatientData({
        patientId: props.params.id_patient,
    }))(Component);
}

class PatientUpdatePage extends React.Component {
    static propTypes = {
        auth0: PropTypes.object,
        params: PropTypes.object,
        patientLoading: PropTypes.bool,
        patient: PropTypes.object,
        className: PropTypes.string,
        t: PropTypes.func,
        onSaved: PropTypes.func,
    };
    
    get hasNotSavedData() {
        return (
            this.state.notSavedDataChild ||
            this.state.notSavedDataEventConfig ||
            this.state.notSavedDataCaregiver ||
            this.state.notSavedDataPrescriber ||
            this.state.notSavedDataHealthCondition ||
            this.state.notSavedDataAccessGroups
        );
    }
    
    get steps() {
        const state = this.state;
        const props = this.props;
        
        return [
            { label: props.t(['Child information', 'pages.patientUpdate.steps.childInformation']), hasChanged: state.notSavedDataChild },
            { label: props.t(['Health condition', 'pages.patientUpdate.steps.healthCondition']), hasChanged: state.notSavedDataHealthCondition },
            { label: props.t(['Event configuration', 'pages.patientUpdate.steps.eventConfiguration']), hasChanged: state.notSavedDataEventConfig },
            { label: props.t(['Caregiver information', 'pages.patientUpdate.steps.caregiverInformation']), hasChanged: state.notSavedDataCaregiver },
            { label: props.t(['Prescriber', 'pages.patientUpdate.steps.prescriber']), hasChanged: state.notSavedDataPrescriber },
            { label: props.t(['Access groups', 'pages.patientUpdate.steps.accessGroups']), hasChanged: state.notSavedDataAccessGroups },
        ];
    }

    state = {
        activeStep: 0,
        notSavedDataAccessGroups: false,
        notSavedDataCaregiver: false,
        notSavedDataChild: false,
        notSavedDataEventConfig: false,
        notSavedDataHealthCondition: false,
        notSavedDataPrescriber: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;
        const me = props.auth0.user;

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    <ReactRouterPrompt when={this.hasNotSavedData}>
                        {({ isActive, onConfirm, onCancel }) =>
                            isActive && (
                                <Modal title={t(['Do you really want to leave?', 'pages.patientUpdate.notSavedData.title'])} onClosed={onCancel}>
                                    <p>{t(['Are you sure you want to leave the page without saving? You data will be lost.', 'pages.patientUpdate.notSavedData.text'])}</p>
                                    <Button displayIcon={false} className="button-delete" type="submit" onClick={onConfirm}>
                                        {t(['Leave the page', 'pages.patientUpdate.notSavedData.confirm'])}
                                    </Button>
                                    <Button displayIcon={false} className="button-cancel" type="button" onClick={onCancel}>
                                        {t(['Stay on this page', 'pages.patientUpdate.notSavedData.cancel'])}
                                    </Button>
                                </Modal>
                            )
                        }
                    </ReactRouterPrompt>
                    {!props.patientLoading && props.patient &&
                        <div className="sections-wrapper">
                            <nav className="sections-menu">
                                {this.steps.map((step, i) => (
                                    // eslint-disable-next-line react/jsx-no-bind
                                    <a key={i}
                                        className={`section-menu-step-${i} ${(state.activeStep === i) ? 'activeStep' : ''} ${step.hasChanged ? 'changedStep' : ''}`}
                                        onClick={() => this.handleStepClicked(i)}
                                    >
                                        {step.label}
                                    </a>
                                ))}
                            </nav>
                            
                            <div className="sections-content">
                                <PatientUpdateChild className={(state.activeStep === 0) ? 'section-step-0 active' : 'section-step-0 hidden'} patient={props.patient} onChanged={this.handlePatientChildChanged} />
                                <PatientUpdateHealthCondition className={(state.activeStep === 1) ? 'section-step-0 active' : 'section-step-0 hidden'} patient={props.patient} onChanged={this.handlePatientHealthConditionChanged} />
                                <PatientUpdateEventConfiguration className={(state.activeStep === 2) ? 'section-step-0 active' : 'section-step-0 hidden'} patientId={props.patient.patientId.id} onChanged={this.handlePatientEventConfigChanged} />
                                <PatientUpdateCaregiver className={(state.activeStep === 3) ? 'section-step-1 active' : 'section-step-1 hidden'} patient={props.patient} onChanged={this.handlePatientCaregiverChanged} />
                                <PatientUpdatePrescriber className={(state.activeStep === 4) ? 'section-step-2 active' : 'section-step-2 hidden'} patient={props.patient} onChanged={this.handlePatientPrescriberChanged} me={me} />
                                <PatientUpdateGroups className={(state.activeStep === 5) ? 'section-step-3 active' : 'section-step-3 hidden'} patient={props.patient} onChanged={this.handlePatientGroupsChanged} />
                            </div>
                        </div>
                    }
                    {props.patientLoading &&
                        <LoadingView />
                    }
                </ErrorBoundary>
            </div>
        );
    }

    handlePatientChildChanged(notSavedDataChild) {
        this.setState(state => ({
            ...state,
            notSavedDataChild: notSavedDataChild,
        }));
    }

    handlePatientCaregiverChanged(notSavedDataCaregiver) {
        this.setState(state => ({
            ...state,
            notSavedDataCaregiver: notSavedDataCaregiver,
        }));
    }
    
    handlePatientEventConfigChanged(notSavedDataEventConfig) {
        this.setState(state => ({
            ...state,
            notSavedDataEventConfig: notSavedDataEventConfig,
        }));
    }
    
    handlePatientHealthConditionChanged(notSavedDataHealthCondition) {
        this.setState(state => ({
            ...state,
            notSavedDataHealthCondition: notSavedDataHealthCondition,
        }));
    }
    
    handlePatientGroupsChanged(notSavedDataGroups) {
        this.setState(state => ({
            ...state,
            notSavedDataAccessGroups: notSavedDataGroups,
        }));
    }

    handlePatientPrescriberChanged(notSavedDataCaregiver) {
        this.setState(state => ({
            ...state,
            notSavedDataPrescriber: notSavedDataCaregiver,
        }));
    }

    handleStepClicked(stepIndex) {
        this.setState(state => ({
            ...state,
            activeStep: stepIndex,
        }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
PatientUpdatePage = Styled(PatientUpdatePage)`
& {
    min-height: 500px;
    width: 90%;
    height: calc(100% - 80px);
    margin: 70px auto 0 auto;
}
`;

PatientUpdatePage = withRouter(wrapWithPage(withTranslation()(withAuth0(withPatientData(PatientUpdatePage)))));
export {PatientUpdatePage};
