import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';

class HcpUpdateEmailForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        loading: PropTypes.bool,
        hcp: PropTypes.object.isRequired,
        onSubmit: PropTypes.func
    };

    state = {
        newEmail: '',
        newEmailValid: true,
        newEmailConfirmation: '',
        newEmailConfirmationValid: true,
        loading: false,
        errorMessage: '',
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    isFilled() {
        const state = this.state;
        return (
            state.newEmail && state.newEmail !== '' &&
            state.newEmailConfirmation && state.newEmailConfirmation !== '' &&
            state.newEmailValid &&
            state.newEmailConfirmationValid
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={this.props.className}>
                <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                <form onSubmit={this.handleSubmit}>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-newEmail" className={`mandatoryField ${(this.state.newEmail === '') ? 'empty' : 'filled'}`}>{t(['New email address', 'hcpUpdateEmail.newEmailAddress'])}</label>
                        <input name="email" maxLength={320} id="hcp-newEmail" required aria-required="true" value={this.state.newEmail} onBlur={this.handleValidateNewEmail} onChange={this.handleChangeNewEmail} />
                    </div>
                    {state.newEmail && !state.newEmailValid &&
                        <div className="errorWrapper">
                            <ErrorMessage text={t(['Invalid email', 'hcpUpdateEmail.newEmailAddressError'])} errorMessage={t(['Please enter a valid email address', 'hcpUpdateEmail.newEmailAddressErrorMessage'])} />
                        </div>
                    }
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-newEmailConfirmation" className={`mandatoryField ${(this.state.newEmailConfirmation === '') ? 'empty' : 'filled'}`}>{t(['New email address confirmation', 'hcpUpdateEmail.newEmailAddressConfirmation'])}</label>
                        <input name="email" maxLength={320} id="hcp-newEmailConfirmation" required aria-required="true" value={this.state.newEmailConfirmation} onBlur={this.handleValidateNewEmailConfirmation} onChange={this.handleChangeNewEmailConfirmation} />
                    </div>
                    {state.newEmailConfirmation && !state.newEmailConfirmationValid &&
                        <div className="errorWrapper">
                            <ErrorMessage text={t(['Invalid email', 'hcpUpdateEmail.newEmailAddressConfirmationError'])} errorMessage={t(['The two email addresses should be identical', 'hcpUpdateEmail.newEmailAddressConfirmationErrorMessage'])} />
                        </div>
                    }
                    <div className="step-form-actions">
                        <Button className="button-create" disabled={!this.isFilled()} loading={props.loading}>{t(['Save', 'hcpUpdateEmail.confirm'])}</Button>
                    </div>
                </form>
            </div>
        );
    }

    handleValidateNewEmail(e) {
        const email = e.target.value;
        const pattern = /^\S{1,60}@{1}[a-z0-9_-]{1,60}(\.[a-z0-9_-]{2,60})+$/;

        this.setState(state => ({
            ...state,
            newEmailValid: pattern.test(email),
        }));
    }

    handleChangeNewEmail(e) {
        this.setState(state => ({
            ...state,
            newEmail: e.target.value,
        }));
    }

    handleValidateNewEmailConfirmation(e) {
        const currentNewEmail = this.state.newEmail;

        this.setState(state => ({
            ...state,
            newEmailConfirmationValid: currentNewEmail === e.target.value,
        }));
    }

    handleChangeNewEmailConfirmation(e) {
        this.setState(state => ({
            ...state,
            newEmailConfirmation: e.target.value,
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        if(this.props.onSubmit) {
            const hcp = {
                id: this.props.hcp.hcpId,
                personalDetails: {
                    email: this.state.newEmailConfirmation,
                },
            };
            this.props.onSubmit(hcp);
        }
    }
}

export default withTranslation()(HcpUpdateEmailForm);

