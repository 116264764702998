import PropTypes from 'prop-types';
import Styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode';

const TimelineChartZoomAreaPropTypes = {
    className: PropTypes.string,
    startCoordinate: PropTypes.object.isRequired,
    endCoordinate: PropTypes.object.isRequired,
    subchartType: PropTypes.number,
};

function UnstyledTimelineChartZoomArea(props) {
    let left = props.startCoordinate.x;
    let right = props.endCoordinate.x;

    // Get selected subchart position
    let top = document.getElementsByClassName(`patient-timeline-subchart-${props.subchartType}`)[0]?.offsetTop;

    // Get selected subchart real height, from cartesian grid
    const chartCartesianGrid = document.querySelector(`.patient-timeline-subchart-${props.subchartType} .recharts-cartesian-grid`);
    const chartCartesianGridRect = chartCartesianGrid?.getBoundingClientRect();
    let height = chartCartesianGridRect?.height;

    // Display area with correct direction
    if (left > right) {
        right = props.startCoordinate.x;
        left = props.endCoordinate.x;
    }

    const width = (left === 0) ? 0 : right - left;

    return (
        <div className={props.className} style={{top: top, left: left, width: width, height: height, backgroundColor: colorPalette.signalType[props.subchartType]}} />
    );
}

UnstyledTimelineChartZoomArea.propTypes = TimelineChartZoomAreaPropTypes;

//language=SCSS
const TimelineChartZoomArea = Styled(UnstyledTimelineChartZoomArea)`
& {
    background:${colorPalette.activeColor};
    position:absolute;
    opacity: 0.5;
    pointer-events: none;
}
`;

export { TimelineChartZoomArea };
