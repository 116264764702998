import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {useState} from 'react';

import {PatientHealthConditionDetailsUpdateForm} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition-details-update-form';
import {BackendApiService} from '@/services/backend-api-service';

type PatientHealthConditionDetailsUpdateProps = {
    patient: Patient;
    healthConditionDetails: string;
    healthConditionTypes: string[];
    healthConditionTypesSelected: string[];
    onSaved?: () => void;
    onChanged?: (changed: boolean) => void;
};

function PatientHealthConditionDetailsUpdate({patient, healthConditionDetails, healthConditionTypes, healthConditionTypesSelected, onSaved, onChanged}: PatientHealthConditionDetailsUpdateProps) {
    const [loading, setLoading] = useState(false);

    const updateHealthConditionDetails = (newHealthConditionDetails: string, healthConditionTypesSelection: string[]) => {
        setLoading(true);

        const updateDetailsPromise = (newHealthConditionDetails === healthConditionDetails ? Promise.resolve() :
            BackendApiService.getRequest({
                domain: 'patient',
                modelName: 'updateHealthConditionDetails',
                data: {
                    patientId: patient.patientId?.id ?? '',
                    healthConditionDetails: newHealthConditionDetails,
                }
            })
        );

        Promise.all([
            updateDetailsPromise,
            ...updateHealthConditionTypes(healthConditionTypesSelection),
        ]).then(() => {
            setLoading(false);
            if (onSaved) {
                onSaved();
            }
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    };

    const updateHealthConditionTypes = (healthConditionTypesSelection: string[]) => {
        return healthConditionTypes.map(healthConditionType => {
            // It was selected and it's not anymore
            if (healthConditionTypesSelected.includes(healthConditionType) && !healthConditionTypesSelection.includes(healthConditionType)) {
                return removeHealthConditionType(healthConditionType);
            }
            // If was not selected and it is now
            if (!healthConditionTypesSelected.includes(healthConditionType) && healthConditionTypesSelection.includes(healthConditionType)) {
                return addHealthConditionType(healthConditionType);
            }
            return Promise.resolve();
        });
    };

    const addHealthConditionType = async (healthConditionType: string) => {
        return BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'addHealthCondition',
            data: {
                patientId: patient.patientId?.id ?? '',
                healthCondition: healthConditionType
            }
        }).catch(err => {
            console.error(err);
        });
    };

    const removeHealthConditionType = async (healthConditionType: string) => {
        return BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'removeHealthCondition',
            data: {
                patientId: patient.patientId?.id ?? '',
                healthCondition: healthConditionType
            }
        }).catch(err => {
            console.error(err);
        });
    };

    return (
        <PatientHealthConditionDetailsUpdateForm
            updateMode={true}
            healthConditionDetails={healthConditionDetails}
            healthConditionTypes={healthConditionTypes}
            healthConditionTypesSelected={healthConditionTypesSelected}
            loading={loading}
            onSubmit={updateHealthConditionDetails}
            onChanged={onChanged}
        />
    );
}

export {PatientHealthConditionDetailsUpdate};
