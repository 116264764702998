enum LocalStorageFieldName {
  APP_SHOWCASE_DONE = 'APP_SHOWCASE_DONE',
  PATIENT_INFOS = 'PATIENT_INFOS',
  SELECTED_ENVIRONMENT = 'SELECTED_ENVIRONMENT',
  SELECTED_LANGUAGE = 'SELECTED_LANGUAGE',
  TOS_VERSION_ACCEPTED = 'TOS_VERSION_ACCEPTED',
  USER_EMAIL = 'USER_EMAIL',
  USER_ID = 'USER_ID',
  USER_PASSWORD = 'USER_PASSWORD',
}

export { LocalStorageFieldName };
