export type ChartDualLabelProps = {
    viewBox: { width: number, height: number, x: number, y: number };
    fill: string;
    aboveText: string;
    belowText: string;
};

export function ChartDualLabel({viewBox, fill, aboveText, belowText}: ChartDualLabelProps) {
    return (
        <>
            <g transform={`rotate(-90, 0, ${viewBox.height/2})`}>
                {/*<rect x="0" y={viewBox.height/2} fill="blue" width={viewBox.height/2} height="12" />*/}
                <text fontSize="11" offset="0" x="50%" y={viewBox.height/2} fill={fill} className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x={viewBox.height/4} dy="0.8em">{aboveText}</tspan>
                </text>
            </g>
            <g transform={`rotate(-90, 0, ${viewBox.height})`}>
                {/*<rect x="0" y={viewBox.height} fill="red" width={viewBox.height/2} height="12" />*/}
                <text fontSize="11" offset="0" x="50%" y={viewBox.height} fill={fill} className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x={viewBox.height/4} dy="0.8em">{belowText}</tspan>
                </text>
            </g>
        </>
    );
}
