import stylis from 'stylis';

import {colorPalette} from '@/themes/darkmode';

import '@enykeev/react-virtualized/styles.css';
import 'rc-slider/assets/index.css';

//language=SCSS
const rootCSS = stylis('html body',`
& {
    // RECHARTS STYLES
    .recharts-cartesian-grid-horizontal line {
        stroke: ${colorPalette.thirdBackground};
    }

    .recharts-cartesian-axis-ticks {
        tspan {
            fill: #fff;
        }
    }

    .recharts-cartesian-axis-line,
    .recharts-cartesian-axis-tick-line {
        stroke: ${colorPalette.clearColor};
    }

    .recharts-rectangle.recharts-tooltip-cursor,
    .recharts-curve.recharts-tooltip-cursor {
        fill:${colorPalette.clearColor};
        stroke:${colorPalette.clearColor};
    }
}`);

const head = document.head || document.getElementsByTagName('head')[0];
const styleTag = document.createElement('style');
styleTag.type = 'text/css';
if (styleTag.styleSheet) {
    styleTag.styleSheet.cssText = rootCSS;
}
else {
    styleTag.appendChild(document.createTextNode(rootCSS));
}
head.appendChild(styleTag);
