import {UpdateTutorialStatusRequest} from 'gabi-api-ts/v2/hcp/command/hcp_command';
import {HcpCommandServiceClient} from 'gabi-api-ts/v2/hcp/command/hcp_command.client';
import {TutorialStatus as ApiTutorialStatusValue} from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {HcpQueryServiceClient} from 'gabi-api-ts/v2/hcp/query/hcp_query.client';
import {useSyncExternalStore, JSX} from 'react';

import {grpcRequest} from '@/services/api-requests/grpc-request-ts';
import {Auth0Service} from '@/services/auth0-service';
import {noop} from '@/util/noop';
import {ObservableVar} from '@/util/observable-var';
import {singlePromise} from '@/util/promise-util';

type TutorialStatus = {
    demoPatient: boolean;
    guidedTourPatient: boolean;
    guidedTourHealthReport: boolean;
    guidedTourSignals: boolean;
};

function convertTutorialStatus(status: boolean) {
    return status ? ApiTutorialStatusValue.ENABLED : ApiTutorialStatusValue.DISABLED;
}

class TutorialService_ {
    readonly tutorialStatus: ObservableVar<TutorialStatus> = new ObservableVar({
        demoPatient: false,
        guidedTourPatient: false,
        guidedTourHealthReport: false,
        guidedTourSignals: false,
    } as TutorialStatus);

    async init() {
        try {
            const accessToken = await Auth0Service.getAccessToken();
            const out = await singlePromise(() => {
                return grpcRequest(
                    accessToken ?? '',
                    HcpQueryServiceClient,
                    HcpQueryServiceClient.prototype.getTutorialStatus,
                    {}
                );
            }, 'getTutorialStatus');
            this.tutorialStatus.set({
                demoPatient: out.response.demoPatient === ApiTutorialStatusValue.ENABLED,
                guidedTourPatient: out.response.guidedTourPatient === ApiTutorialStatusValue.ENABLED,
                guidedTourHealthReport: out.response.guidedTourHealthReport === ApiTutorialStatusValue.ENABLED,
                guidedTourSignals: out.response.guidedTourSignals === ApiTutorialStatusValue.ENABLED,
            });
        }
        catch (e) {
            this.tutorialStatus.set({
                demoPatient: false,
                guidedTourPatient: false,
                guidedTourHealthReport: false,
                guidedTourSignals: false,
            });
        }
    }

    updateDemoPatientEnabled(enabled: boolean) {
        const updatedTutorialStatus = { ...this.tutorialStatus.value };
        updatedTutorialStatus.demoPatient = enabled;
        TutorialService.updateTutorialStatus(updatedTutorialStatus);
    }

    updateTutorialStatus(status: TutorialStatus) {
        this.tutorialStatus.set(status);
        // Updating the value in the backend. This is allowed to fail, as it will not break the user experience.
        (async () => {
            try {
                const apiStatus: UpdateTutorialStatusRequest = {
                    demoPatient: convertTutorialStatus(status.demoPatient),
                    guidedTourPatient: convertTutorialStatus(status.guidedTourPatient),
                    guidedTourHealthReport: convertTutorialStatus(status.guidedTourHealthReport),
                    guidedTourSignals: convertTutorialStatus(status.guidedTourSignals),
                };
                const accessToken = await Auth0Service.getAccessToken();
                await grpcRequest(
                    accessToken ?? '',
                    HcpCommandServiceClient,
                    HcpCommandServiceClient.prototype.updateTutorialStatus,
                    apiStatus
                );
            }
            catch(e) {
                console.error('Cannot update the tutorial status in the backend.', e);
            }
        })().then(noop);
    }
}

const TutorialService = new TutorialService_();

function useTutorialStatus() {
    return useSyncExternalStore<TutorialStatus>(
        TutorialService.tutorialStatus.onChange,
        () => TutorialService.tutorialStatus.value,
    );
}

type Props = { [key: string]: unknown };
function withTutorialStatus(Component: JSX.ElementType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function ComponentWithTutoStatus(props: Props) {
        const tutorialStatus = useTutorialStatus();

        const extraProps: Props = {};
        extraProps['tutorialStatus'] = tutorialStatus;

        return (
            <Component {...props} {...extraProps} />
        );
    }

    return ComponentWithTutoStatus;
}

export type {TutorialStatus};
export {TutorialService, useTutorialStatus, withTutorialStatus};
