import {isValid, subDays} from 'date-fns';
import deepEqual from 'deep-eql';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {atNoon} from 'gabi-web-common/util/date-util';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import Styled from 'styled-components';

import GuidedTourTimeline from '@/components/business/analytics/guidedTour/guided-tour-timeline';
import {TimelineChart} from '@/components/business/analytics/timeline/timeline-chart';
import {
    TimelineTooltipActigraphy,
    TimelineTooltipHR,
    TimelineTooltipSPO2,
} from '@/components/business/analytics/timeline/timeline-chart-tooltip';
import {TimelineScrollbar} from '@/components/business/analytics/timeline/timeline-scrollbar';
import {TimelineSidebar} from '@/components/business/analytics/timeline/timeline-sidebar';
import {TimelineSubchartZoom} from '@/components/business/analytics/timeline/timeline-subchart-zoom';
import {ErrorMessage} from '@/components/form/error-message';
import {Autosized} from '@/components/layout/autosized';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {withBackendQuery} from '@/decorators/with-backend-query';
import withRouter from '@/decorators/withRouter';
import {useDaysWithSignalData} from '@/hooks/backend/use-days-with-signal-data';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {useBackendQuery} from '@/hooks/use-backend-query';
import baby_sitting_skinType_1 from '@/public/images/baby_sitting_skin_1.svg';
import baby_sitting_skinType_2 from '@/public/images/baby_sitting_skin_2.svg';
import baby_sitting_skinType_3 from '@/public/images/baby_sitting_skin_3.svg';
import baby_sitting_skinType_4 from '@/public/images/baby_sitting_skin_4.svg';
import baby_sitting_skinType_5 from '@/public/images/baby_sitting_skin_5.svg';
import baby_sitting_skinType_6 from '@/public/images/baby_sitting_skin_6.svg';
import baby_sitting_skinType_fox from '@/public/images/baby_sitting_skin_fox.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils-ts';
import {BackendApiService} from '@/services/backend-api-service';
import {withTutorialStatus} from '@/services/tutorial-service';
import {colorPalette} from '@/themes/darkmode';
import {isDateStringValid} from '@/util/date-utils';
import {isHourStringValid} from '@/util/time-utils';
import {formatDateForUrl} from '@/util/url-util';

import '@/stylesheets/datepicker-darkmode-theme.css';

const babySittingSkinType = {
    0: baby_sitting_skinType_fox,
    1: baby_sitting_skinType_1,
    2: baby_sitting_skinType_2,
    3: baby_sitting_skinType_3,
    4: baby_sitting_skinType_4,
    5: baby_sitting_skinType_5,
    6: baby_sitting_skinType_6,
};

function checkOrder(arr) {
    for (let i = 1; i < arr.length; i++) {
        if (arr[i - 1].secondsSince12pm > arr[i].secondsSince12pm) {
            console.warn('SIGNAL IS NOT ORDERED');
            console.warn(`Index: ${i - 1}, Values: ${arr[i - 1].secondsSince12pm} and ${arr[i].secondsSince12pm}`);
            console.warn(`Index: ${i - 1}, Values: ${new Date(arr[i - 1].secondsSince12pm)} and ${new Date(arr[i].secondsSince12pm)}`);
            console.warn(`Index: ${i - 1}, Values: ${JSON.stringify(arr[i - 1].atOrig)} and ${JSON.stringify(arr[i].atOrig)}`);
        }
    }
}

function wrapWithPage(Component) {
    return Page({
        name: 'Daily biometrics',
        pagePath: props => ([
            {
                route: '/patients/',
                name: 'Patients'
            },
            {
                route: `/patients/${props.params.id_patient}/healthReport`,
                name: 'Health Report'
            }
        ])
    })(Component);
}

function withDaysWithData(Component) {
    return withBackendQuery('daysWithDataLoading', 'daysWithData', 'daysWithDataError', props => {
        return useDaysWithSignalData({
            patient: props.patient,
        });
    })(Component);
}

function withEventConfig(Component) {
    return withBackendQuery('eventConfigurationLoading', 'eventConfiguration', 'eventConfigurationError', props => useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: {patientId: {id: props.params.id_patient}},
    }))(Component);
}

function withPatientData(Component) {
    return withBackendQuery('patientLoading', 'patient', 'patientError', props => usePatientData({
        patientId: props.params.id_patient,
    }))(Component);
}

function computeChartOverviewBlocksReliable(signal) {
    const chartOverviewBlocksReliable = [];
    if (signal.length > 0) {
        const firstReliablePoint = signal.find(item => item.reliability);
        if (firstReliablePoint) {
            let prevT = firstReliablePoint.secondsSince12pm;
            let curBlockStart = prevT;
            signal.forEach(item => {
                if (item.reliability) {
                    if (item.secondsSince12pm > prevT + 120) {
                        chartOverviewBlocksReliable.push({
                            start: curBlockStart,
                            end: prevT,
                        });
                        curBlockStart = item.secondsSince12pm;
                    }
                    prevT = item.secondsSince12pm;
                }
            });
            chartOverviewBlocksReliable.push({
                start: curBlockStart,
                end: prevT,
            });
        }
    }
    return chartOverviewBlocksReliable;
}

class PatientTimelinePage extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        daysWithData: PropTypes.object,
        eventConfiguration: PropTypes.object,
        eventConfigurationLoading: PropTypes.bool.isRequired,
        patient: PropTypes.object,
        patientError: PropTypes.object,
        patientLoading: PropTypes.bool.isRequired,
        navigate: PropTypes.func,
        params: PropTypes.object,
        tutorialStatus: PropTypes.any,
    };

    static zoomLevel = {
        value: 200/300,
        label: '5min',
    };
    
    chartOverviewBlocks = [];
    chartOverviewBlocksReliableSPO2 = [];
    chartOverviewBlocksReliableHR = [];

    state = {
        chartAreaWidth: 0, // Got from <Autosized> to capture the chart width
        displayModalZoom: false,
        errorMessage: null,
        fetchingSignalsAndEvents: false,
        medicalEvents: null,
        ranges: null,
        selectedDateTime: 0,
        signals: null,
        subcharts: {},
        tooltipData: {},
        visibleStartTime: 0,
        zoomData: {},
    };

    constructor(props) {
        super(props);

        autoBind(this);

        // Enable charts
        this.state.subcharts[SignalType.Signal_PR] = true;
        this.state.subcharts[SignalType.Signal_SPO2] = true;
        this.state.subcharts[SignalType.Signal_Actigraphy] = true;

        // Set start date from URL
        if (!props.params.date) {
            this.state.selectedDateTime = props.daysWithData.day[props.daysWithData.day.length - 1].getTime();
        }
        else if (!isDateStringValid(props.params.date)) {
            setTimeout(() => {
                props.navigate(`/patients/${props.params.id_patient}/timeline`, { replace: true });
            }, 50);
        }
        else {
            // Check hour in URL if present
            if (props.params.time && !isHourStringValid(props.params.time)) {
                setTimeout(() => {
                    props.navigate(`/patients/${props.params.id_patient}/timeline/${props.params.date}`, { replace: true });
                }, 50);
            }
            const urlDate = this.parseUrlDateAndTime();
            const hours = urlDate.getHours();
            if (hours >= 0 && hours < 12) {
                const dayBeforeUrlDate = subDays(urlDate, 1);
                this.state.selectedDateTime = atNoon(dayBeforeUrlDate).getTime();
            }
            else {
                this.state.selectedDateTime = atNoon(urlDate).getTime();
            }
        }
    }
    
    parseUrlDateAndTime() {
        const params = this.props.params;
        const urlDateStr = params.date.replace(/-/g, '/');
        let urlTimeStr = (!params.time || !isHourStringValid(params.time)) ? '12:00:00' : params.time;
        if (urlTimeStr.length <= 2) {
            urlTimeStr += ':00';
        }
        const urlDateTimeStr = `${urlDateStr} ${urlTimeStr}`;
        return new Date(urlDateTimeStr);
    }

    componentDidMount() {
        document.addEventListener('mouseover', this.handleDocumentMouseOver);
        if (this.props.eventConfiguration) {
            this.fetchSignalsAndEvents();
        }
    }
    
    componentDidUpdate(prevProps) {
        if (!deepEqual(prevProps.eventConfiguration, this.props.eventConfiguration)) {
            if (this.props.eventConfiguration) {
                this.fetchSignalsAndEvents();
            }
        }
    }

    async fetchSignalsAndEvents() {
        const state = this.state;
        const dateFrom = new Date(state.selectedDateTime);
        const dateTo = new Date(state.selectedDateTime);

        this.setState(state => ({
            ...state,
            fetchingSignalsAndEvents: true,
        }));

        BackendApiService.getRequest({
            domain: 'signal',
            modelName: 'getSignal',
            data: {
                patientId: this.props.params.id_patient,
                from : dateFrom,
                to: dateTo
            }
        })
            .then((response) => {
                const ranges = response.rangesList;
                const signals = response.blocksList;
                const medicalEvents = response.eventsList;
                checkOrder(signals.hr);
                
                // Computing overview blocks
                this.chartOverviewBlocks = [];
                if (signals.spo2.length > 0) {
                    let prevT = signals.spo2[0].secondsSince12pm;
                    let curBlockStart = prevT;
                    signals.spo2.forEach(item => {
                        if (item.secondsSince12pm > prevT + 300) {
                            this.chartOverviewBlocks.push({
                                start: curBlockStart,
                                end: prevT,
                            });
                            curBlockStart = item.secondsSince12pm;
                        }
                        prevT = item.secondsSince12pm;
                    });
                    this.chartOverviewBlocks.push({
                        start: curBlockStart,
                        end: prevT,
                    });
                }
                
                // Computing overview blocks with reliable data
                this.chartOverviewBlocksReliableSPO2 = computeChartOverviewBlocksReliable(signals.spo2);
                this.chartOverviewBlocksReliableHR = computeChartOverviewBlocksReliable(signals.hr);
                
                if (!this.props.params.time || !isHourStringValid(this.props.params.time)) {
                    if (this.chartOverviewBlocks.length > 0) {
                        this.setState(state => ({
                            ...state,
                            visibleStartTime: this.chartOverviewBlocks[0]?.start,
                        }));
                    }
                }
                else {
                    const urlDate = this.parseUrlDateAndTime();
                    
                    const signalStartTime = this.chartOverviewBlocks[0]?.start;
                    const signalEndTime = this.chartOverviewBlocks[this.chartOverviewBlocks.length-1]?.end;

                    if (isValid(urlDate)) {
                        this.setState(state => ({
                            ...state,
                            visibleStartTime: (
                                Math.min(signalEndTime - state.chartAreaWidth / PatientTimelinePage.zoomLevel.value,
                                    Math.max(signalStartTime,
                                        (urlDate.getTime() - state.selectedDateTime) / 1000
                                    )
                                )
                            ),
                        }));
                    }
                }

                this.setState(state => ({
                    ...state,
                    ranges: ranges,
                    signals: this.alignSignals(signals),
                    medicalEvents: medicalEvents,
                    fetchingSignalsAndEvents: false,
                }));

                return response;
            })
            .catch(err => {
                console.error(err);
                this.setState(state => ({
                    ...state,
                    signals: null,
                    fetchingSignalsAndEvents: false,
                    errorMessage: err.message || 'Cannot get signals.'
                }));
            });
    }

    /**
     * Make sure all signals have the same number of points and have "unixTs" aligned
     * @param signals
     * @return {{}} aligned signals
     */
    alignSignals(signals) {
        // return signals;
        const prioritarySignals = [
            'spo2',
            'hr',
        ];
        let mainSignal = null;
        let mainSignalKey = null;
        for (let i=0; i < prioritarySignals.length; ++i) {
            if (signals[prioritarySignals[i]]) {
                mainSignalKey = prioritarySignals[i];
                mainSignal = signals[mainSignalKey];
                break;
            }
        }

        const outSignals = {};
        Object.keys(signals).forEach(signalKey => {
            const outSignal = [];
            const signal = signals[signalKey];
            if (signalKey === mainSignalKey) {
                outSignals[signalKey] = signal;
            }
            else {
                let j = 0;
                for (let i=0; i < mainSignal.length; ++i) {
                    const mainSignalPoint = mainSignal[i];

                    while (j < signal.length && signal[j].secondsSince12pm < mainSignalPoint.secondsSince12pm) {
                        ++j;
                    }
                    let value = (signal[j] || { value: null }).value;
                    let reliableValue = (signal[j] || { value: null }).reliableValue;
                    let reliability = (signal[j] || { reliability: null }).reliability;
                    if (!signal[j] || ((signal[j].secondsSince12pm - mainSignalPoint.secondsSince12pm) > 1) || (reliableValue && reliableValue.value) === 'noData') {
                        reliability = false;
                        value = 'noData';
                        if (reliableValue) {
                            reliableValue.value = 'noData';
                        }
                    }

                    outSignal.push({
                        ...mainSignalPoint,
                        value: value,
                        reliableValue: reliableValue,
                        reliability: reliability,
                    });
                }
                outSignals[signalKey] = outSignal;
            }
        });
        return outSignals;
    }

    checkSignalsPresence() {
        const signals = this.state.signals;
        const patient = this.props.patient;
        const urlDate = this.parseUrlDateAndTime();
        
        return (
            formatApiDateToJSDate(patient.birthDate).getTime() < urlDate.getTime() &&
            !(signals !== null && (signals.hr.length === 0 && signals.spo2.length === 0 && signals.actigraphy.length === 0))
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        
        const visibleEndTime = state.visibleStartTime + state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;

        const loading = (
            (props.eventConfigurationLoading) ||
            (props.patientLoading) ||
            (!props.daysWithData)
        );
        
        const hasPatientAccess = !props.patientError;
        const patient = props.patient;
        
        if (loading) {
            return (
                <div className={`timeline-page ${props.className ?? ''}`}>
                    {hasPatientAccess && patient && props.daysWithData && props.eventConfiguration && (
                        <TimelineSidebar
                            patient={patient}
                            eventConfiguration={props.eventConfiguration}
                            daysWithData={props.daysWithData?.day ?? []}
                            selectedDate={new Date(state.selectedDateTime)}
                            onDateSelected={this.handleSelectDate}
                        />
                    )}
                    <LoadingView />
                </div>
            );
        }
        else if (!hasPatientAccess) {
            return (
                <div className="no-access-message-block">
                    <div className="info-message">You don&apos;t have access to this patient.</div>
                    <img src={baby_sitting_skinType_1 ?? ''} alt="Baby sitting" width="150" />
                </div>
            );
        }
        else if (state.errorMessage) {
            return (
                <ErrorMessage errorMessage={state.errorMessage} text="An error occurred" />
            );
        }
        else {
            return (
                <div className={`timeline-page ${props.className ?? ''}`}>
                    {patient &&
                        <div>
                            {props.tutorialStatus.guidedTourSignals && (
                                <GuidedTourTimeline />
                            )}
                            <TimelineSidebar
                                patient={patient}
                                eventConfiguration={props.eventConfiguration}
                                daysWithData={props.daysWithData.day}
                                selectedDate={new Date(state.selectedDateTime)}
                                onDateSelected={this.handleSelectDate}
                            />
                            <div className="timeline-content-wrapper">
                                <div className={'timeline-content'}>
                                    {(!state.fetchingSignalsAndEvents && this.checkSignalsPresence() && state.medicalEvents &&
                                        <div className="timeline-scroll">
                                            <TimelineScrollbar
                                                startTime={0}
                                                endTime={24*3600}
                                                visibleStartTime={state.visibleStartTime}
                                                visibleEndTime={visibleEndTime}
                                                medicalEvents={state.medicalEvents}
                                                onScroll={this.handleChartScroll}
                                                onClick={this.handleChartScrollClick}
                                                dataBlocks={this.chartOverviewBlocks}
                                                dataBlocksReliableSPO2={this.chartOverviewBlocksReliableSPO2}
                                                dataBlocksReliableHR={this.chartOverviewBlocksReliableHR}
                                            />
                                        </div>
                                    )}
                                    <div className="timeline-charts">
                                        <Autosized component={this.renderMetricChart} />
                                    </div>
                                </div>
                            </div>

                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_PR) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipHR
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}
                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_SPO2) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipSPO2
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}
                            {(state.tooltipData?.value && state.tooltipData?.subchartType === SignalType.Signal_Actigraphy) && this.renderSubChartTooltip(
                                state.tooltipData, (
                                    <TimelineTooltipActigraphy
                                        active={true}
                                        baseDate={state.selectedDateTime}
                                        payload={state.tooltipData}
                                    />
                                )
                            )}

                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_PR) && this.renderSubChartZoom(SignalType.Signal_PR, state.signals?.hr, state.medicalEvents?.hr, props.eventConfiguration.configuration.hr, TimelineTooltipHR, 'Pulse Rate', 'bpm', 10)}
                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_SPO2) && this.renderSubChartZoom(SignalType.Signal_SPO2, state.signals?.spo2, state.medicalEvents?.spo2, props.eventConfiguration.configuration.spo2, TimelineTooltipSPO2, 'SpO2', '%', 2)}
                            {(state.displayModalZoom && state.zoomData?.zoomSelectedSubchart === SignalType.Signal_Actigraphy) && this.renderSubChartZoom(SignalType.Signal_Actigraphy, state.signals?.actigraphy, state.medicalEvents?.actigraphy, null, TimelineTooltipActigraphy, 'Movements', 'G',0.01)}
                        </div>
                    }
                    {!patient && (<>
                        <LoadingView />
                    </>)}
                </div>
            );
        }
    }

    renderSubChartTooltip(tooltipData, /*JSX.Element*/ content) {
        return (
            <div style={{
                'width': '220px',
                'position': 'absolute',
                'left': (tooltipData.positionX >= window.innerWidth / 2) ? tooltipData.positionX - 185 : tooltipData.positionX + 10,
                'top': tooltipData.positionY + 10}}
            >
                {content}
            </div>
        );
    }

    renderSubChartZoom(subchartType, data, medicalEvents, eventConfiguration, tooltip_, label, unit, padding) {
        const state = this.state;
        return (
            <TimelineSubchartZoom
                selectedTimestamp={state.selectedDateTime}
                zoomInfo={state.zoomData}
                subchartType={subchartType}
                data={data}
                medicalEvents={medicalEvents}
                eventConfiguration={eventConfiguration}
                tooltip_={tooltip_}
                label={tooltip_}
                unit={unit}
                padding={padding}
                onClosed={this.handleCloseModalZoomChart}
            />
        );
    }

    formatYAxis(tickItem) {
        if (tickItem === Math.floor(tickItem)) {
            return tickItem;
        }
        else {
            return tickItem.toFixed(2);
        }
    }

    handleDocumentMouseOver() {
        this.setState(() => ({
            tooltipData: {}
        }));
    }

    handleCloseModalZoomChart() {
        this.setState(() => ({
            displayModalZoom: false,
            zoomAreaStartTimestamp: null,
            zoomAreaStartCoordinate: {x: 0, y: 0},
            zoomAreaEndTimestamp: null,
            zoomAreaEndCoordinate: {x: 0, y: 0},
            zoomSelectedSubchart: null,
        }));
    }

    renderMetricChart({width, height}) {
        const props = this.props;
        const state = this.state;

        const subcharts = Object.entries(this.state.subcharts).filter(e => !!e[1]).map(e => parseInt(e[0]));
        const selectedTimeLength = 24*3600;

        const chartWidth = TimelineChart.yaxisWidth + (selectedTimeLength * PatientTimelinePage.zoomLevel.value);
        const chartAreaWidth = width - TimelineChart.yaxisWidth;
        
        const patient = props.patient;

        if (state.chartAreaWidth !== chartAreaWidth) {
            setTimeout(() => {
                this.setState(state => ({
                    ...state,
                    chartAreaWidth: chartAreaWidth,
                }));
            }, 50);
        }

        if (state.fetchingSignalsAndEvents) {
            return <LoadingView/>;
        }
        if (!this.checkSignalsPresence()) {
            return (
                <div className="no-data-message-block">
                    <div className="info-message">No recording for this day.</div>
                    <img src={babySittingSkinType[patient.skinType]} alt="Baby sitting" width="150" />
                </div>
            );
        }

        const visibleEndTime = state.visibleStartTime + state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;
        const visibleWidth = Number((100*(visibleEndTime-state.visibleStartTime)/selectedTimeLength).toFixed(1));
        const scrollBarCursorWidth = (visibleWidth < 100) ? visibleWidth : 100;

        return (
            <TimelineChart
                baseDate={new Date(this.state.selectedDateTime)}
                endTime={24*3600}
                eventConfiguration={props.eventConfiguration}
                height={height}
                id_patient={props.params.id_patient}
                medicalEvents={state.medicalEvents}
                ranges={state.ranges}
                scrollBarCursorWidth={scrollBarCursorWidth}
                scrollX={state.visibleStartTime / selectedTimeLength}
                signals={state.signals}
                startTime={0}
                subcharts={subcharts}
                width={chartWidth}
                //onScroll={this.handleChartScroll}
                onZoomChart={this.handleZoomChart}
                onDisplayTooltip={this.handleDisplayTooltip}
            />
        );
    }

    handleDisplayTooltip(tooltipData) {
        this.setState(() => ({
            tooltipData: tooltipData,
        }));
    }

    handleZoomChart(zoomData) {
        if (zoomData.zoomAreaEndTimestamp === zoomData.zoomAreaStartTimestamp) {
            return;
        }
        this.setState(() => ({
            displayModalZoom: true,
            zoomData: zoomData,
        }));
    }

    handleSelectDate(selectedDate){
        let url = `/patients/${this.props.params.id_patient}/timeline/${formatDateForUrl(selectedDate)}`;
        this.props.navigate(url);

        this.setState(state => ({
            ...state,
            selectedDateTime: atNoon(selectedDate).getTime(),
            visibleStartTime: 0,
        }), () => {
            // noinspection JSIgnoredPromiseFromCall
            this.fetchSignalsAndEvents();
        });
    }
    
    handleChartScroll(deltaTime) {
        const state = this.state;
    
        const visibleTimeLength = state.chartAreaWidth / PatientTimelinePage.zoomLevel.value;
        const actualDeltaTime = Math.min(
            24*3600 - visibleTimeLength,
            Math.max(
                0, state.visibleStartTime + deltaTime
            )
        ) - state.visibleStartTime;
        
        this.setState(state => ({
            ...state,
            visibleStartTime: state.visibleStartTime + actualDeltaTime,
        }));
    }

    handleChartScrollClick(cursorPositionTime) {
        this.setState(state => ({
            ...state,
            visibleStartTime: cursorPositionTime,
        }));
    }

    // handleEventConfigurationChanged(selectedEventConfiguration) {
    //     this.setState(state => ({
    //         ...state,
    //         eventConfiguration: selectedEventConfiguration
    //     }), () => {
    //         // noinspection JSIgnoredPromiseFromCall
    //         this.fetchSignalsAndEvents();
    //     });
    // }
}

//language=SCSS
PatientTimelinePage = Styled(PatientTimelinePage)`
& {
    position: relative;
    
    svg, svg * {
        user-drag: none;
    }
    
    .timeline-content-wrapper {
        display: flex;
    }
    
    .timeline-content {
        padding: 30px;
        width: calc(100% - 250px);
        height: calc(100vh - 70px);
        position: absolute !important;
        left: 250px;
        overflow-x: hidden;
    }
    
    .timeline-zoom-slider {
        padding: 0 50px 0 50px;
        width: 100%;
        margin-bottom: 15px;
        
        .rc-slider-mark {
            margin-top: -4px;
        }
        
        .rc-slider-mark-text {
            color: ${colorPalette.frontColor};
        }
    }
    
    .timeline-charts {
        width: 100%;
        margin-top: 45px;
    }
    
    .timeline__chart-section {
        padding: 0 30px;
        height: 100%;
    }
    
    .rc-slider-tooltip-inner {
        white-space: nowrap;
    }
    
    .timeline__eventsChart .info-message {
        margin-top: 20px;
    }
    
    .modal-zoom-chart, .modal-zoom-chart > .modal-content {
        border-radius: 5px;
        z-index: 1050;
        
        h2 {
            font-size: 17px;
            margin: 0 0 5px 0;
        }
        
        h3 {
            font-size: 14px;
            margin: 0 0 25px 0;
            color: ${colorPalette.clearColor}
        }
        
        .modal-close-button {
            position: absolute;
            right: 10px;
            top: 10px;
            border: none;
            font-size: 20px;
            color: ${colorPalette.frontColor};
            background: transparent;
        }
        
        .recharts-cartesian-axis line,
        .recharts-cartesian-grid line {
            stroke: ${colorPalette.clearColor};
        }
        
        .recharts-cartesian-axis-ticks {
            tspan {
                fill: #fff;
            }
        }
        
        .recharts-rectangle.recharts-tooltip-cursor,
        .recharts-curve.recharts-tooltip-cursor {
            fill: ${colorPalette.activeColor};
            stroke: ${colorPalette.activeColor};
        }
    }
    
    .modal-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${colorPalette.thirdBackground};
        opacity: 0.8;
        z-index: 1000;
    }
    
    .no-access-message-block {
        width: 600px;
        margin: 70px auto 0 auto;
        
        img {
            display: block;
            margin: 20px auto 0 auto;
        }
    }
    
    .no-data-message-block {
        width: 600px;
        margin: 0 auto;
        
        img {
            display: block;
            margin: 20px auto 0 auto;
        }
    }
}
`;

PatientTimelinePage = withRouter(
    withTutorialStatus(withPatientData(withEventConfig(withDaysWithData(
        wrapWithPage(PatientTimelinePage)
    ))))
);

export {PatientTimelinePage};
