import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {BinaryReader, BinaryWriter} from '@protobuf-ts/runtime';
import type {RpcInterceptor} from '@protobuf-ts/runtime-rpc/build/types/rpc-interceptor';
import {RpcMetadata} from '@protobuf-ts/runtime-rpc/build/types/rpc-metadata';

import {GabiEnvService} from '@/services/gabi-env-service';

function clientConfig() {
    return GabiEnvService.getEnvironment().backendConfig;
}

function getApiUrl() {
    const config = clientConfig();
    //console.log(`getApiUrl: ${config.domain}`);
    return `https://${config.domain}`;
}

function getGrpcMetadata(idToken?: string): RpcMetadata {
    const metadata: RpcMetadata = {
        'x-api-key': clientConfig().apiKey,
        'caller-id': 'analytics-web', //'gabi_companion_app',
        'caller-version': '0.6.3-92da86d',
    };

    // In case of request without token
    if (idToken && idToken !== '') {
        metadata.Authorization = `Bearer ${idToken}`;
    }

    //console.log(metadata);

    return metadata;
}

const grpcWebFetchTransport = (interceptors: RpcInterceptor[]) => new GrpcWebFetchTransport({
    format: 'binary',
    baseUrl: getApiUrl(),
    fetchInit: {
        credentials: 'omit',
    },
    binaryOptions: {
        writerFactory: () => new BinaryWriter(new TextEncoder()),
        readerFactory: (bytes: Uint8Array) => new BinaryReader(bytes, new TextDecoder()),
    },
    interceptors: interceptors,
});

export {getApiUrl, getGrpcMetadata, grpcWebFetchTransport};
